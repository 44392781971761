import Styled from 'styled-components';

export const H1 = Styled.h1`
    font-size: ${props => props.fontSize || "4.7rem"};
    font-weight: ${props => props.fontWeight || "bold"};
    color: ${props => props.color || "#fff"};
    text-align: ${props => props.textAlign || "center"};
    margin: ${props => props.margin || "50px auto"};
    padding: ${props => props.padding || "0"};
    line-height: ${props => props.lineHeight || "1.2"};
    text-transform: ${props => props.textTransform || "uppercase"};


    @media (max-width: 768px) {
        font-size: ${props => props.fontSize || "2.5rem"};
    }

    
    `
export const H2 = Styled.h2`
    font-size: ${props => props.fontSize || "1.5rem"};
    font-weight: ${props => props.fontWeight || "bold"};
    color: ${props => props.color || "#4c4c4c"}; 
    background-color: ${props => props.backgroundColor || "transparent"};
    text-align: ${props => props.textAlign || "start"};
    margin: ${props => props.margin || "auto"};
    padding: ${props => props.padding || "0"};
    line-height: ${props => props.lineHeight || "1.2"};
    text-transform: ${props => props.textTransform || "uppercase"};
    letter-spacing: ${props => props.letterSpacing || "5px"}; 
    min-height: ${props => props.minHeight || "auto"};
    display: ${props => props.display || "block"};
    justify-content: ${props => props.justifyContent || "center"};
    align-items: ${props => props.alignItems || "center"};
    width: ${props => props.width || "auto"};
    border-bottom: ${props => props.borderBottom || "none"};
    border-width: ${props => props.borderWidth || "initial"}; 
    cursor: ${props => props.cursor || "text"};

    &:hover {
        color: ${props => props.hoverColor || "auto"};
        background-color: ${props => props.hoverBackgroundColor || "auto"};
        // border-bottom: ${props => props.hoverBorderBottom || "initial"};
        // border-width: ${props => props.hoverBorderWidth || "initial"};

    }

  

    @media (max-width: 768px) {
        width: ${props => props.mediaWidth || "80%"}; 
        margin: ${props => props.mediaMargin || "0 auto 20px"}; 
        font-size: ${props => props.fontSize || "0.8rem"};
        text-align: ${props => props.mediaTextAlign || "center"};
        
    }
`
export const H3 = Styled.h3`
    font-size: ${props => props.fontSize || "1.1rem"};
    font-weight: ${props => props.fontWeight || "bold"};
    color: ${props => props.color || "#4c4c4c"}; 
    background-color: ${props => props.backgroundColor || "transparent"};
    text-align: ${props => props.textAlign || "start"};
    margin: ${props => props.margin || "auto"};
    padding: ${props => props.padding || "0"};
    line-height: ${props => props.lineHeight || "1.2"};
    text-transform: ${props => props.textTransform || "uppercase"};
    letter-spacing: ${props => props.letterSpacing || "0"}; 
    min-height: ${props => props.minHeight || "auto"};
    display: ${props => props.display || "block"};
    justify-content: ${props => props.justifyContent || "center"};
    align-items: ${props => props.alignItems || "center"};
    width: ${props => props.width || "auto"};
    border-bottom: ${props => props.borderBottom || "none"};
    border-width: ${props => props.borderWidth || "initial"}; 
    display: ${props => props.display || "block"};
    align-items: ${props => props.alignItems || "center"};


    @media (max-width: 768px) {
        font-size: ${props => props.mediaFontSize || "0.8rem"}; 

}
`

export const P = Styled.p`
    font-size: ${props => props.fontSize || "1rem"};
    font-weight: ${props => props.fontWeight || "initial"};
    color: ${props => props.color || "#4c4c4c"}; 
    background-color: ${props => props.backgroundColor || "transparent"};
    text-align: ${props => props.textAlign || "start"};
    margin: ${props => props.margin || "0 0 1.5rem"};
    padding: ${props => props.padding || "0"};
    line-height: ${props => props.lineHeight || "1.5"};
    text-transform: ${props => props.textTransform || "initial"};
    letter-spacing: ${props => props.letterSpacing || "0"}; 
    min-height: ${props => props.minHeight || "auto"};
    display: ${props => props.display || "block"};
    justify-content: ${props => props.justifyContent || "center"};
    align-items: ${props => props.alignItems || "center"};
    width: ${props => props.width || "auto"};
    border-bottom: ${props => props.borderBottom || "none"};
    border-width: ${props => props.borderWidth || "initial"}; 


    &:first-child{
        ::first-letter {
            color: #903;
            float: left;
            font-family: Georgia;
            font-size: 65px;
            line-height: 40px;
            padding-top: 4px;
            padding-right: 8px;
            padding-left: 3px;
          }
    }
    @media (max-width: 768px) {
        font-size: ${props => props.mediaFontSize || "0.8rem"};
    }
`



export const Section = Styled.section`
    overflow: hidden;
    background-color: ${props => props.backgroundColor || "transparent"};
    padding: ${props => props.padding || "0 0"}; 
    margin: ${props => props.margin || "0"};
    background-image: url(${props => props.backgroundImage || "none"}); 
    background-size: ${props => props.backgroundSize || "cover"};
    background-position: ${props => props.backgroundPosition || "center"};
    background-repeat: ${props => props.backgroundRepeat || "no-repeat"};
    background-attachment: ${props => props.backgroundAttachment || "fixed"};
    height: ${props => props.height || "auto"};
    opacity: ${props => props.opacity || "1"};

`
export const Container = Styled.div`
    width: ${props => props.width || "auto"};
    height: ${props => props.height || "100%"};
    min-height: ${props => props.minHeight || "auto"};
    margin: ${props => props.margin || "auto"};  
    color: ${props => props.color || "#000"};
    background: ${props => props.backgroundColor || "initial"};
    display: ${props => props.display || "block"};
    justify-content: ${props => props.justifyContent || "center"};
    align-items: ${props => props.alignItems || "center"};
    text-align: ${props => props.textAlign || "center"};
    flex-wrap: ${props => props.flexWrap || "wrap"};
    flex-direction: ${props => props.flexDirection || "auto"};
    color: ${props => props.color || "#000"};
    padding: ${props => props.padding || "0"};
    border-top: ${props => props.borderTop || "none"};
    border-bottom: ${props => props.borderBottom || "none"};
    overflow: ${props => props.overflow || "visible"};
    @media (max-width: 768px) {
        width: ${props => props.mediaWidth || "100%"}; 
        flex-direction: ${props => props.mediaFlexDirection || "column"};
        align-items: ${props => props.mediaAlignItems || "center"};
        padding: ${props => props.mediaPadding || "unset"};
        text-align: ${props => props.mediaTextAlign || "center"};
        // margin: ${props => props.mediaMargin || "auto"};  

}
`

export const TextContainer = Styled.div`
    width: ${props => props.width || "auto"};
    margin: ${props => props.margin || "auto"};
    color: ${props => props.color || "#fff"};
    text-transform: ${props => props.textTransform || "initial"};
    padding: ${props => props.padding || "0"};
    text-align: ${props => props.textAlign || "start"};
    height: ${props => props.height || "auto"};
    background-color: ${props => props.backgroundColor || "transparent"};
    display: ${props => props.display || "block"};
    justify-content: ${props => props.justifyContent || "center"};
    align-items: ${props => props.alignItems || "center"};
    flex-direction: ${props => props.flexDirection || "initial"};

    @media (max-width: 768px) { 
        padding: ${props => props.mediaPadding || "0"};
        width: ${props => props.mediaWidth || "80%"};
    }


`

export const ImageContainer = Styled.div`
    width: ${props => props.width || "auto"};
    margin: ${props => props.margin || "auto"};
    text-align: ${props => props.textAlign || "center"};
    @media (max-width: 768px) {
        width: ${props => props.mediaWidth || "80%"};  
    }
`
//  height={idx % 2 === 0 ? '220px':'260px'}
export const Source = Styled.source` 
    width: ${props => props.width || "100%"};
    height: ${props => props.height || "auto"};
    margin: ${props => props.margin || "auto"};
    padding: ${props => props.padding || "0"};
    border-radius: ${props => props.borderRadius || "0"};
    border: ${props => props.border || "0"};
    box-shadow: ${props => props.boxShadow || "none"}; 
    cursor: ${props => props.cursor || "initial"};
    transition: all 0.3s ease;
    object-fit: ${props => props.objectFit || "cover"};


    &:hover {
        filter:${props => props.filter || "none"};
        transform: ${props => props.transform || "none"};
    }
     @media (max-width: 768px) {
        width: ${props => props.mediaWidth || "100%"}; 
        height: ${props => props.mediaHeight || "auto"};
    } 

`

export const VideoContainer = Styled.div` 
    width: ${props => props.width || "100%"};
    height: ${props => props.height || "auto"};
    margin: ${props => props.margin || "auto"};
    padding: ${props => props.padding || "0"};
    border-radius: ${props => props.borderRadius || "0"};
    border: ${props => props.border || "0"};
    box-shadow: ${props => props.boxShadow || "none"}; 
    cursor: ${props => props.cursor || "initial"};
    transition: all 0.3s ease;
    object-fit: ${props => props.objectFit || "cover"};


    &:hover {
        filter:${props => props.filter || "none"};
        transform: ${props => props.transform || "none"};
    }
     @media (max-width: 768px) {
        width: ${props => props.mediaWidth || "100%"}; 
        height: ${props => props.mediaHeight || "auto"};
    } 

`

export const Image = Styled.img` 
    width: ${props => props.width || "100%"};
    background-color: ${props => props.backgroundColor || "initial"};
    height: ${props => props.height || "auto"};
    margin: ${props => props.margin || "auto"};
    padding: ${props => props.padding || "0"};
    border-radius: ${props => props.borderRadius || "0"};
    border: ${props => props.border || "0"};
    box-shadow: ${props => props.boxShadow || "none"}; 
    cursor: ${props => props.cursor || "initial"};
    transition: all 0.3s ease;
    object-fit: ${props => props.objectFit || "cover"};
    filter:${props => props.filter || "opacity(1)"};


    &:hover {
        filter:${props => props.hoverFilter || "none"};
        transform: ${props => props.transform || "none"};
    }
     @media (max-width: 768px) {
        width: ${props => props.mediaWidth || "100%"}; 
        height: ${props => props.mediaHeight || "auto"};
        object-fit: ${props => props.mediaObjectFit || "cover"};
        margin: ${props => props.mediaMargin || "auto"};
    // height: ${props => props.mediaHeight || "auto"};
    } 

`


export const GallerImageContainer = Styled.div`
    -webkit-column-count: ${props => props.columnCount || "3"};
    -moz-column-count: ${props => props.columnCount || "3"};
    column-count: 3;
    webkit-column-width: ${props => props.columnWidth || "33%"};
    -moz-column-width: ${props => props.columnWidth || "33%"};
    column-width: ${props => props.columnWidth || "33%"};
    padding: ${props => props.padding || "0 12px"};


    :nth-child(2n) {
        -webkit-column-count: ${props => props.columnCount || "2"};
        -moz-column-count: ${props => props.columnCount || "2"};
        column-count: 2;
        webkit-column-width: ${props => props.columnWidth || "50%"};
        -moz-column-width: ${props => props.columnWidth || "50%"};
        column-width: ${props => props.columnWidth || "50%"};
        padding: ${props => props.padding || "0 12px"};
    }


    // :nth-child(2) { 
    //     column-count: 2; 
    //     column-width: ${props => props.columnWidth || "50%"};
    //     padding: ${props => props.padding || "0 12px"};
    // }
    // width: ${props => props.width || "auto"};
 
    // @media (max-width: 991px) {
    //     column-count: 2;
    // }
    // @media (max-width: 480px) {
    //     column-count: 1;
    // }
`

export const Card = Styled.div`
    width: ${props => props.width || "26%"};
    margin: ${props => props.margin || "20px"};
    height: ${props => props.height || "auto"};
    padding: 20px;
    background: ${props => props.background || "none"};
    overflow: hidden; 
    @media (max-width: 768px) {
        width: ${props => props.mediaWidth || "80%"};  
        margin: ${props => props.mediaMargin || "0"};
        padding: ${props => props.mediaPadding || "0"};
    }
`
export const CardImage = Styled.div`
    width: ${props => props.width || "100px"};
    height: ${props => props.height || "100px"};
    display: flex;
    text-align: center;
    margin: auto;
`

export const CardText = Styled.div`
    width: 100%;
    height: ${props => props.height || "200px"}; 

    @media (max-width: 768px) {
    height: ${props => props.mediaHeight || "270px"}; 
    text-align: ${props => props.mediaTextAlign || "center"};

    }

`
export const CardTextTitle = Styled.h3`
    font-size: ${props => props.fontSize || "1.1rem"};
    font-weight: ${props => props.fontWeight || "bold"};
    color: ${props => props.color || "#4c4c4c"}; 
    background-color: ${props => props.backgroundColor || "transparent"};
    text-align: ${props => props.textAlign || "start"};
    margin: ${props => props.margin || "10px 0 "};
    padding: ${props => props.padding || "0"};
    line-height: ${props => props.lineHeight || "1.5"};
    text-transform: ${props => props.textTransform || "uppercase"};
    letter-spacing: ${props => props.letterSpacing || "0"}; 
    min-height: ${props => props.minHeight || "auto"};
    display: ${props => props.display || "block"};
    justify-content: ${props => props.justifyContent || "center"};
    align-items: ${props => props.alignItems || "center"};
    width: ${props => props.width || "auto"};
    height: ${props => props.height || "54px"};
    border-bottom: ${props => props.borderBottom || "none"};
    border-width: ${props => props.borderWidth || "initial"}; 
    text-decoration: ${props => props.textDecoration || "none"};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`

export const CardTextDescription = Styled.p`
    font-size: ${props => props.fontSize || "1rem"};
    font-weight: ${props => props.fontWeight || "initial"};
    color: ${props => props.color || "#4c4c4c"}; 
    background-color: ${props => props.backgroundColor || "transparent"};
    text-align: ${props => props.textAlign || "start"};
    margin: ${props => props.margin || "0 0 1.5rem"};
    padding: ${props => props.padding || "0"};
    line-height: ${props => props.lineHeight || "1.5"};
    text-transform: ${props => props.textTransform || "initial"};
    letter-spacing: ${props => props.letterSpacing || "0"}; 
    min-height: ${props => props.minHeight || "auto"};
    display: ${props => props.display || "block"};
    justify-content: ${props => props.justifyContent || "center"};
    align-items: ${props => props.alignItems || "center"};
    width: ${props => props.width || "auto"};
    height: ${props => props.height || "140px"}; 
    border-bottom: ${props => props.borderBottom || "none"};
    border-width: ${props => props.borderWidth || "initial"};
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;


    @media (max-width: 768px) {
    max-height: ${props => props.height || "140px"}; 
    height: ${props => props.height || "auto"}; 
    }

`
export const Input = Styled.input`
    width: 100%;
    height: 200px;

`



export const TextArea = Styled.textarea`
    width: 100%;
    height: 200px;

`


export const Button = Styled.button`
    border-radius: ${props => props.borderRadius || "100px"};
    padding: ${props => props.padding || "16px 30px"}; 
    display: ${props => props.display || "static"}; 
    align-items: ${props => props.alignItems || "center"};
    color: #fff;
    background-color: #ff5454;
    border: none;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    margin: ${props => props.margin || "0"};

    &:hover {
        background-color: #ce4848;
        color: #fff;
    }
`



export const DashMenuContainer = Styled.div`
    width: 100%;
    height: 100vh;
    background-color: transparent; 
    display: ${props => props.display || "flex"}; 
    align-items: ${props => props.alignItems || "center"};
    flex-direction: ${props => props.flexDirection || "column"};
    overflow: ${props => props.overflow || "hidden"};
    box-shadow: 0px 0px 10px -3px ${props => props.boxShadow}; 
    
    
    @media (max-width: 768px) {
        transition: ${props => props.transition || "all 0.8s ease-in-out"};
        position: ${props => props.position || "absolute"};
        left: ${props => props.mediaLeft || "0px"};
        width: ${props => props.mediaWidth || "70%"};
    }
    `
export const DashMenuLogoContainer = Styled.div`
    width: 100%;
    height: 30vh;
    background-color: ${props => props.backgroundColor || "#fff"};; 
    padding: ${props => props.padding || "20px"};
    display: ${props => props.display || "flex"}; 
    align-items: ${props => props.alignItems || "center"};
    flex-direction: ${props => props.flexDirection || "column"};

    `
export const DashMenuItemContainer = Styled.div`
    width: 100%;
    height: 70vh;
    border-top: ${props => props.borderTop || "1px solid #000"};
    background-color: transparent; 
    display: ${props => props.display || "flex"}; 
    align-items: ${props => props.alignItems || "center"};
    flex-direction: ${props => props.flexDirection || "column"};
    overflow-x: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
        width: 0;
      } 
    `

export const DashMenuItem = Styled.div`
    width: 94%;
    height: 8vh;
    padding: 0 10px ;
    background-color: ${props => props.backgroundColor || "transparent"};
    position: ${props => props.position || 'static'};
    bottom:${props => props.bottom || "0"};
    color: #fff;
    border-bottom:1px solid #8f8f8f;
    display: ${props => props.display || "flex"};
    justify-content: ${props => props.justifyContent || "start"};
    align-items: ${props => props.alignItems || "center"};  
    flex-direction: ${props => props.flexDirection || "row"};
    cursor: pointer;
    border-left: 10px solid transparent ;

    &:hover { 
        background-color: #273d54;
        color: #fff;
        padding: 0 10px ;
        border-left: 10px solid !important;
    }

    @media (max-width: 768px) {
        border-left: 5px solid transparent !important;

    }

`

export const DashMenuIcon = Styled.div`
width: 50px;
height: 8vh;
padding: 10px;
display: ${props => props.display || "flex"};
justify-content: ${props => props.justifyContent || "center"};
align-items: ${props => props.alignItems || "center"};

svg {
    width: 2rem;
    height: 2rem;
}

`

export const DashMenuName = Styled.div` 

    display: ${props => props.display || 'flex'};
    justify-content: ${props => props.justifyContent || 'space-between'};
    align-items: ${props => props.alignItems || 'center'};
    width: 100%;

    svg {
        width: 2rem;
        height: 2rem;
    }
`


export const DashContentDiv = Styled.div` 
    margin: ${props => props.margin || ' 20px auto'};
    padding: ${props => props.padding || ' 20px'};
    width: fit-content;
    overflow: auto;
    ::-webkit-scrollbar {
        width: 1px !important;
    }
    ::-webkit-scrollbar-track {
        // background-color:red;
    } 
    ::-webkit-scrollbar-thumb {
            background-color:#c8c7c7 !important;
    }
    
    // @media (max-width: 475px) {
    // }
    
     
`
export const DashContentTableDiv = Styled.div` 
    margin: ${props => props.margin || ' 20px 0'};
    padding: ${props => props.padding || ' 20px'};
    padding: ${props => props.padding || '10px'};
    margin: ${props => props.margin || '20px 0'};
    border-radius: ${props => props.borderRadius || '3px'};
    box-shadow:0px 0px 10px -3px ${props => props.boxShadow}; 
    // @media (max-width: 475px) {
    //     overflow: auto; 
    // }
    // overflow: auto;

    ::-webkit-scrollbar {
        width: 1px !important;
      }
    ::-webkit-scrollbar-track {
        // background-color:red;
        } 
    ::-webkit-scrollbar-thumb {
            background-color:#c8c7c7 !important;
        }
    
     
`


// Local Table
export const LclTable = Styled.table`
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    position: static;
    top: 20px;   
    margin: ${props => props.margin || "auto"};
    // box-shadow: 0px 0px 24px 1px #e2e2e2;
    // border: none;
    // text-align: left;
    @media (max-width: 475px) { 
        // overflow:scroll;
        // display: flex; 
        // justify-content: center;
        // align-items: center;
        // transform:rotate(270deg);
    }
    
`

export const LclTData = Styled.td`
    border: 1px solid ${props => props.border || props.theme.themeborder || "#c8c7c7"}; 
    padding: 8px; 
    text-align: ${props => props.textAlign || "left"};  
    overflow-wrap: ${props => props.overflowWrap || "normal"};
    height: ${props => props.height || "auto"};
    max-width: ${props => props.maxWidth || "initial"};
    min-width: ${props => props.minWidth || "initial"};
    width: ${props => props.width || "200px"};
    color: ${props => props.color};
    font-size: ${props => props.fontSize || "unset"};
    overflow-wrap: ${props => props.overflowWrap || "break-word"};
`
export const LclTRow = Styled.tr`
    // border: 1px solid #dddddd;
    text-align: left;
    padding: 8px; 
    display: ${props => props.display || "table-row"};
    flex-direction: ${props => props.flexDirection || "row"};
    width: ${props => props.width || "auto"};
    height: ${props => props.height || "auto"};
    max-width: ${props => props.maxWidth || "auto"};
    max-height: ${props => props.maxHeight || "auto"};
    
    :nth-child(even) {
        background-color:  ${props => props.theme.themeNthChildBg || "#dddddd"};
      }
      
    @media (max-width: 475px) {
        // transform:rotate(-270deg);
        // flex-basis: 33.33333333%;
        // display: flex;
        // flex-direction: column; 
        // padding: 5px 10px;
      }
`


export const PrimaryButton = Styled.button`
color: ${props => props.theme.primaryBtnColor || props.theme.primaryBtnColor || 'blue'}; 
background: transparent;
box-shadow: 0 0 0 2px  ${props => props.theme.primaryBtnBoxShadow} inset;
font-size: 1em;
margin: ${props => props.margin || "1em"};
padding: 0.6em 1em;
border-radius: 3px;
border:none;

:hover {
    color: white; 
    background: ${props => props.theme.primaryBtnInvertedbackground || 'blue'}; 
}


`
export const DangerButton = Styled.button`
color: ${props => props.theme.dangerBtnColor || 'red'}; 
background: transparent;
box-shadow: 0 0 0 2px  ${props => props.theme.dangerBtnBoxShadow} inset;

font-size: 1em;
margin: 1em;
padding: 0.6em 1em;
border-radius: 3px;
border:none;


:hover {
    color: white; 
    background: ${props => props.theme.dangerBtnInvertedbackground || 'red'}; 
}

`

export const DropDownContainer = Styled.div`
  width: 10.5em;
  margin: 0 auto;
`;

export const DropDownHeader = Styled.div`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: #3faffa;
  background: #ffffff;
`;

export const DropDownListContainer = Styled.div``;

export const DropDownList = Styled.ul`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

export const ListItem = Styled.li`
  list-style: none;
  margin-bottom: 0.8em;
`;


export const InputGroupDiv = Styled.div`
    display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: ${props => props.margin || "0"};
    padding: ${props => props.padding || "0"};
    border-radius: ${props => props.borderRadius || "3px"};
    // box-shadow: 0px 0px 24px 1px ${props => props.boxShadow};
    // background: ${props => props.background || "white"};
    @media (max-width: 475px) {
        overflow: auto;
    }
`
export const InputGroup = Styled.div`
    width: ${props => props.width || "auto"};
    @media (max-width: 475px) {
        width: 100%;
    }
`
export const IconContainer = Styled.div` 
       display: flex;
       position: absolute;
       top: ${props => props.top || 'auto'};
       right: 10px;
       height: 35px;
       width: 35px;
       background-color: #ff5454;
       color: #fff;
       border-radius: 5%;
       cursor: pointer;
       align-items: center;
       justify-content: center;
 
`