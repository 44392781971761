import React, { useState } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import styled from 'styled-components';



const Button = styled.div`
 position: fixed; 
 width: 100%;
 left: 95%;
 bottom: 50px;
 height: 20px;
 font-size: 2.8rem;
 z-index: 1;
 cursor: pointer;
 color: #717171;

  :hover {
    color: #565353;
  }

 @media (max-width: 768px) {
    left: 46% ;
    bottom: 30px ;
    font-size: 1.5rem ;
}
`;
const ScrollButton = (props) => {


  // const buttonVisibleOn = props.props;

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <Button style={{ display: visible ? 'block' : 'none' }} className="scrollButton">
      <FaArrowCircleUp onClick={scrollToTop}
        style={{ display: visible ? 'block' : 'none' }} />
    </Button>
  );
};

export default ScrollButton;