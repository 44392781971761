import React, { useEffect, useState } from 'react';
import { NavbarContainer, NavbarItem, NavbarItemContainer, NavbarItemIcon, NavbarLogoContainer } from '../Style/Navbar/Navbar.style';
// import VMB_LOGO from '../../Assets/VMBLOGO.png';
// import NavOpen from '../../Assets/navbar.png';
import { Link } from 'react-router-dom';
import Parse from 'parse/dist/parse.min.js';
// import { useHistory } from 'react-router-dom';
// import { Container } from '../StyledComponent/VMBALLStyled';
import { FaBars, FaTimes } from "react-icons/fa";


const Navbar = () => {
    // const NavbarItems = [
    //     {
    //         id: 1,
    //         name: 'Virtual Tour',
    //         pathID: 'VirtualTour',
    //         icon: 'virtualTour'
    //     },
    //     {
    //         id: 2,
    //         name: 'About',
    //         pathID: 'About',
    //         icon: 'info'
    //     },
    //     {
    //         id: 3,
    //         name: 'Features',
    //         pathID: 'Features',
    //         icon: 'Features'
    //     },
    //     {
    //         id: 4,
    //         name: 'Gallery',
    //         pathID: 'Gallery',
    //         icon: 'allery'
    //     },
    //     {
    //         id: 5,
    //         name: 'Articales',
    //         pathID: 'Articales',
    //         icon: 'articales'
    //     },
    //     {
    //         id: 6,
    //         name: 'Contact Us',
    //         pathID: '/contact_us',
    //         icon: 'contactus'
    //     },
    //     {
    //         id: 8,
    //         name: 'Dashboard',
    //         path: '/dashboard',
    //         icon: 'Login'
    //     }


    // ]
    const [NavbarItems, setNavbarItems] = useState([]);
    const [NavbarLogo, setNavbarLogo] = useState([])
    const [ActiveItem, setActiveItem] = useState('');
    const [ActiveNavbar, setActiveNavbar] = useState('');
    // const [AllPages, setAllPages] = useState([]);
    // const NavbarLogo = [
    //     {
    //         id: 1,
    //         name: 'Virtual Tour',
    //         logo: VMB_LOGO
    //     }
    // ];
    const changeBackground = () => {
        if (window.scrollY >= 200) {
            setActiveNavbar(true);
        } else {
            setActiveNavbar(false);
        }
    };

    window.addEventListener("scroll", changeBackground);

    const [ShowNavbar, setShowNavbar] = useState(false);

    useEffect(() => {
        const AllFetch = async () => {
            const FetchNavbarData = async () => {
                const NavbarData = new Parse.Query('Navbar');

                const NavbarDataResult = await NavbarData.find();
                setNavbarItems(NavbarDataResult);
            }
            await FetchNavbarData();
            const FetchCompanyProfile = async () => {
                const CompanyProfileQuery = new Parse.Query('CompanyProfile');
                try {
                    let allCompanyProfile = await CompanyProfileQuery.find();
                    allCompanyProfile.map(item => item.get('visibility') === true ? setNavbarLogo([item]) : null)
                    // allCompanyProfile.map(item => item.get('visibility') === true ? // console.log(item) : null)



                } catch (error) {
                    // console.log('page error', error);
                }
            }
            await FetchCompanyProfile();
            // const FetchPage = async () => {
                // const ItemQuery = new Parse.Query('Pages');
                // try {
                    // let FoundItem = await ItemQuery.first();
                    // FoundItem.map(item => item.get('pageRoot') === true ? setAllPages([item]) : null)
                    // console.log(FoundItem);
                    // setAllPages(FoundItem)
                    // allCompanyProfile.map(item => item.get('visibility') === true ? // console.log(item) : null)



                // } catch (error) {
                    // console.log('page error', error);
                // }
            // }
            // await FetchPage();
        }
        AllFetch()
        return () => {
            window.removeEventListener('click',);

        }
    }, []);


    return (
        // <Container>
        <NavbarContainer className={ActiveNavbar && 'ActiveNavbar'}>
            <NavbarLogoContainer>
                {/* <Link to={`/${AllPages.map(item => item.get('pageRoute'))}`}> */}
                {NavbarLogo.map((logo, idx) => <img key={idx} src={logo.get('companyLogo') ? logo.get('companyLogo')['_url'] : undefined} alt={logo.get('companyName')}
                    onClick={() => window.scrollTo(0, 0)} style={{ cursor: 'pointer' }}
                />)}
                {/* </Link> */}

            </NavbarLogoContainer>
            <NavbarItemIcon onClick={() => setShowNavbar(!ShowNavbar)} >
                {/* <img src={NavOpen} alt="VMB Logo" /> */}
                {ShowNavbar ? <FaTimes style={{ color: "white", fontSize: '1rem' }} /> : <FaBars style={{ color: "white", fontSize: '1rem' }} />}

            </NavbarItemIcon>
            {/* <NavbarItemIconBar1 onClick={() => setShowNavbar(!ShowNavbar)} transform={ShowNavbar && 'rotate(135deg)'} top={ShowNavbar && '58px'} />
                <NavbarItemIconBar2 onClick={() => setShowNavbar(!ShowNavbar)} display={ShowNavbar && 'none'} />
                <NavbarItemIconBar3 onClick={() => setShowNavbar(!ShowNavbar)} transform={ShowNavbar && 'rotate(45deg)'} top={ShowNavbar && '58px'} /> */}
            {/* <NavbarItemContainer mediaTransform={ShowNavbar ? '0, 100px' : '0,-100vh'}> */}
            <NavbarItemContainer mediaDisplay={ShowNavbar ? 'block' : 'none'}>
                {/* <NavbarItem /> */}
                {
                    NavbarItems.map(item => (

                        item.get('ItemType') === 'Page' && item.get('visibility') === true ?
                            <Link
                                to={`/${item.get('ItemRoute')}`}
                                className={ActiveItem === item.id ? 'ActiveNavItem' : 'NavItem'}
                                key={item.id}
                                style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}
                                onClick={() => {
                                    // window.location.replace(`/#${item.get('ItemRoute')}`);
                                    setActiveItem(item.get('ItemName')); setShowNavbar(false)
                                }}
                            >
                                <NavbarItem> {item.get('ItemName')}</NavbarItem>
                            </Link> :
                            item.get('ItemType') === 'Section' && item.get('visibility') === true ?
                                <NavbarItem
                                    key={item.id}
                                    className={ActiveItem === item.id ? 'ActiveNavItem' : 'NavItem'}
                                    onClick={() => { window.location.replace(`/#${item.get('ItemRoute')}`); setActiveItem(item.id); setShowNavbar(false) }}
                                >{item.get('ItemName')}
                                </NavbarItem> : undefined
                    ))

                }
                {/* <Link to='/dashboard' style={{ textDecoration: 'none', color: 'white' }}>   <NavbarItem>DashBoard</NavbarItem> </Link> */}
            </NavbarItemContainer>
        </NavbarContainer>
        // {/* </Container> */ }
    );
};

export default Navbar;