import React, { useEffect, useState } from 'react';
import { DashContentDiv, DashContentTableDiv, H3, InputGroup, InputGroupDiv, LclTable, LclTData, LclTRow, PrimaryButton } from '../Component/StyledComponent/VMBALLStyled';
import toast from 'react-hot-toast';
import Parse from 'parse/dist/parse.min.js';
import { BiHide, BiShowAlt } from 'react-icons/bi';

const DashNavbarAndFooter = (data) => {
    const PropsData = data.data;

    const [AllInputs, setAllInputs] = useState({
        name: '',
        route: '',
        type: '',
    });

    const [FooterInputs, setFooterInputs] = useState({});

    const [image, setImage] = useState();
    const [changeImage, setChangeImage] = useState(false);
    const [DropdownOption, setDropdownOption] = useState('AsBoth');
    const [showLogo, setShowLogo] = useState(undefined);
    const [showNavData, setShowNavData] = useState([]);
    const [showFooterData, setShowFooterData] = useState([]);
    const [updatedContent, setUpdatedContent] = useState({});
    const [showForm, setShowForm] = useState(false);
    // const [ChangedRootPage, setChangedRootPage] = useState(false);
    const [FormLoading, setFormLoading] = useState(false);
    const [DataReload, setDataReload] = useState(false);

    const [inputs, setInputs] = useState({
        navLogoLink: '',
        pageName: '',
        pageRoute: '',
        footerText: '',
        footerDate: '',

    });

    useEffect(() => {
        const FetchNavbarData = async () => {
            const NavbarData = new Parse.Query('Navbar');
            if (DropdownOption !== 'AsBoth') {
                NavbarData.equalTo('ItemType', DropdownOption === 'AsSection' ? "Section" : DropdownOption === 'AsPage' ? "Page" : undefined)
            }
            const NavbarDataResult = await NavbarData.find();
            setShowNavData(NavbarDataResult);
        }
        const FetchFooterData = async () => {
            const DataQuery = new Parse.Query('Footer');
            const DataQueryResult = await DataQuery.find();
            setShowFooterData(DataQueryResult);
        }

        if (PropsData.className === 'Navbar') {
            FetchNavbarData();
        }
        if (PropsData.className === 'Footer') {
            FetchFooterData();
        }

        return () => {
            window.removeEventListener('click', FetchNavbarData);
            window.removeEventListener('click', FetchFooterData);

        }
    }, [DropdownOption, PropsData.className, DataReload]);


    const handleSubmitContent = async (e) => {
        e.preventDefault();
        setFormLoading(true);
        if (!updatedContent) {
            if (PropsData.className === 'Navbar') {

                const newNavbar = new Parse.Object('NavbarLogo');
                try {
                    if (image !== undefined) {
                        const navbarLogo = new Parse.File('Logo', image);
                        newNavbar.set('navbarLogo', navbarLogo);
                    }
                    newNavbar.set('navLogoLink', inputs.navLogoLink);
                    newNavbar.set('visibility', true);

                    await newNavbar.save();
                    setFormLoading(false);
                    toast.success('Navbar logo added');
                } catch (error) {
                    // console.log(error);
                    toast.error('Found error', error);
                }

            }

            if (PropsData.className === 'Footer') {



                const newFooter = new Parse.Object('Footer');
                try {
                    newFooter.set('technologyName', FooterInputs.technologyName);
                    if (FooterInputs.technologyLogo) {
                        const technologyLogo = new Parse.File(FooterInputs.technologyLogo.name, FooterInputs.technologyLogo);
                        newFooter.set('technologyLogo', technologyLogo);
                    }
                    newFooter.set('visibility', true);
                    newFooter.set('createdBy', Parse.User.current());
                    newFooter.set('updatedBy', Parse.User.current());

                    await newFooter.save();
                    setFormLoading(false);
                    toast.success('Footer added');
                } catch (error) {
                    console.log(error);
                    toast.error('Found error', error);
                }
            }
        }
        if (updatedContent) {
            console.log('updatedContent', updatedContent);

            if (PropsData.className === 'Navbar') {
                const ItemName = AllInputs.name;
                const ItemRoute = AllInputs.route;
                const ItemType = AllInputs.type;
                const newnavItem = new Parse.Query('Navbar');
                const newPages = new Parse.Query('Pages');
                const newSections = new Parse.Query('Sections');
                const foundPage = await newPages.find();
                const foundItem = await newSections.find();
                newnavItem.equalTo('objectId', updatedContent.id);
                const foundNavItem = await newnavItem.first();
                try {
                    foundNavItem.set('ItemName', ItemName);
                    foundNavItem.set('ItemRoute', ItemRoute.replaceAll(' ', '').toLowerCase());


                    if (ItemType === 'Page') {
                        for (let newPage of foundPage) {
                            if (newPage.id === foundNavItem.get('page').id) {
                                newPage.set('pageName', ItemName);
                                newPage.set('pageRoute', ItemRoute.replaceAll(' ', '').toLowerCase());
                                await newPage.save();
                            }
                        }
                    }
                    if (ItemType === 'Section') {
                        for (let newItem of foundItem) {
                            if (newItem.id === foundNavItem.get('section').id) {
                                newItem.set('sectionName', ItemName);
                                newItem.set('sectionRoute', ItemRoute.replaceAll(' ', '').toLowerCase());
                                await newItem.save();
                            }
                        }
                    }
                    await foundNavItem.save();
                    setFormLoading(false);
                    toast.success('NavItem changed');
                } catch (error) {
                    // console.log(error);
                    toast.error('Found error', error);


                }
            }
            if (updatedContent.className === 'NavbarLogo') {
                const navLogoLink = inputs.navLogoLink;
                const newNavbarLogo = new Parse.Query('NavbarLogo');
                newNavbarLogo.equalTo('objectId', updatedContent.id);
                const foundNavbarLogo = await newNavbarLogo.first();
                // console.log(foundNavbarLogo);
                try {
                    if (image !== undefined) {
                        const navbarLogo = new Parse.File('Logo', image);
                        foundNavbarLogo.set('navbarLogo', navbarLogo);
                    }
                    foundNavbarLogo.set('navLogoLink', navLogoLink);
                    await foundNavbarLogo.save();
                    setFormLoading(false);
                    toast.success('Updated Successfully');
                } catch (error) {
                    // console.log(error);
                    toast.error('Found error', error);
                }

            }
            if (updatedContent.className === 'Footer') {
                const technologyName = FooterInputs.technologyName;
                const technologyLogo = FooterInputs.technologyLogo;
                const newFooter = new Parse.Query('Footer');
                newFooter.equalTo('objectId', updatedContent.id);
                const foundFooter = await newFooter.first();
                try {
                    foundFooter.set('technologyName', technologyName);
                    foundFooter.set('updatedBy', Parse.User.current());
                    if (!changeImage && technologyLogo) {
                        const technologyLogoFile = new Parse.File(technologyLogo.name, technologyLogo);
                        foundFooter.set('technologyLogo', technologyLogoFile);
                    }
                    await foundFooter.save();
                    setFormLoading(false);
                    toast.success('Updated Successfully');
                } catch (error) {
                    // console.log(error);
                    toast.error('Found error', error);
                }
            }

        }

        setFormLoading(false);
        setShowForm(false);
        setAllInputs({
            name: '',
            route: '',
            type: '',
        })
        setFooterInputs({});
        setDataReload(true);
        e.target.reset();

    }
    const HandleUpdate = async (data) => {
        setUpdatedContent(data);
        setShowForm(true);
        // console.log(data);
        if (data.className === 'Footer') {
            setChangeImage(true);

            setFooterInputs({
                technologyName: data.get("technologyName") ? data.get("technologyName") : '',
                technologyLogo: data.get("technologyLogo") ? data.get("technologyLogo") : '',
            });
        }
        // setAllInputs({
        //     name: data.get("ItemName") ? data.get("ItemName") : '',
        //     route: data.get("ItemRoute") ? data.get("ItemRoute") : '',
        //     type: data.get("ItemType") ? data.get("ItemType") : '',
        // });

    }
    const HandleDelete = async (data) => {
        setUpdatedContent(data);
        setShowForm(true);
        setAllInputs({
            name: data.get("ItemName") ? data.get("ItemName") : '',
            route: data.get("ItemRoute") ? data.get("ItemRoute") : '',
            type: data.get("ItemType") ? data.get("ItemType") : '',
        });

    }

    const handleVisibilityShow = async (data) => {
        setFormLoading(true);
        const selectedItem = data.showData;
        if (data.showData.className === 'NavbarLogo') {
            const navbarQuery = new Parse.Query("NavbarLogo");
            navbarQuery.equalTo("objectId", selectedItem.id);
            try {
                let newNavbar = await navbarQuery.first();
                newNavbar.set('visibility', true);
                await newNavbar.save();
                toast.success(`visibility changed to Show for ${selectedItem.get('navItem')} Succesfully`)
            } catch (error) {
                setFormLoading(false);
                // console.log(error);

            }

        }
        if (data.showData.className === 'Navbar') {
            const navbarQuery = new Parse.Query("Navbar");
            navbarQuery.equalTo("objectId", selectedItem.id);
            try {
                let newNavbar = await navbarQuery.first();
                newNavbar.set('visibility', true);
                await newNavbar.save();
                toast.success(`visibility changed to Show for ${selectedItem.get('navItem')} Succesfully`)
            } catch (error) {
                // console.log(error);
                setFormLoading(false);
            }

        }

        setFormLoading(false);

    }
    const handleVisibilityHide = async (data) => {
        setFormLoading(true);
        const selectedItem = data.showData;
        if (data.showData.className === 'NavbarLogo') {
            const navbarQuery = new Parse.Query("NavbarLogo");
            navbarQuery.equalTo("objectId", selectedItem.id);
            try {
                let newNavbar = await navbarQuery.first();
                newNavbar.set('visibility', false);
                await newNavbar.save();
                toast.success(`visibility changed to Hide for ${selectedItem.get('navItem')} Succesfully`)
            } catch (error) {
                // console.log(error);
                setFormLoading(false);
            }

        }
        if (data.showData.className === 'Navbar') {
            const navbarQuery = new Parse.Query("Navbar");
            navbarQuery.equalTo("objectId", selectedItem.id);
            try {
                let newNavbar = await navbarQuery.first();
                newNavbar.set('visibility', false);
                await newNavbar.save();
                toast.success(`visibility changed to Hide for ${selectedItem.get('navItem')} Succesfully`)
            } catch (error) {
                // console.log(error);
                setFormLoading(false);
            }

        }

        setFormLoading(false);
    }


    const FormatTime = (date) => {
        try {
            let time = date.split(" ");
            let hours = time[4].split(":")[0];
            let minutes = time[4].split(":")[1];
            let day = time[0].split(",")[0];
            let lcldate = time[1];
            let month = time[2];
            let year = time[3];
            hours = Number(hours) + 6
            hours = hours || 12;
            const ampm = hours >= 12 ? "PM" : "AM";
            minutes = minutes < 10 ? `${minutes}` : minutes;
            hours %= 12;
            const strTime = `${hours}:${minutes} ${ampm} ${day} ${lcldate} ${month} ${year}`;
            return strTime;
        } catch (e) {
            return "";
        }
    };


    return (
        <DashContentDiv>
            <H3>{PropsData.className}</H3>

            <div className={FormLoading ? 'confirmationOverlay activeConfirmationOverlay' : 'confirmationOverlay'}>
                <div className={FormLoading ? 'activeSpinnerHolder spinnerHolder' : 'spinnerHolder'}>
                    <spin className={FormLoading ? 'activeOverlaySpinner overlaySpinner' : 'overlaySpinner'}></spin>
                </div>
            </div>

            {
                PropsData.className === 'Navbar' && <>
                    {/* {
                        showLogo === undefined && <PrimaryButton style={{ display: 'block', marginTop: '15px' }} onClick={() => { setShowForm(true); setChangedImage(!changedImage) }}>Add logo</PrimaryButton >

                    } */}



                    {showLogo ? <DashContentTableDiv >
                        <LclTable>
                            <LclTRow>
                                <LclTData>Logo</LclTData>
                                <LclTData>Logo/Root page Link</LclTData>
                                <LclTData>Logo Visibility</LclTData>
                                <LclTData>Logo Manage</LclTData>

                            </LclTRow>
                            {
                                showLogo !== undefined ?

                                    <LclTRow>
                                        <LclTData><img src={showLogo.get("navbarLogo") ? showLogo.get("navbarLogo")['_url'] : null} alt='' style={{ width: '100px' }} /></LclTData>
                                        <LclTData>{showLogo.get("navLogoLink") ? showLogo.get("navLogoLink") : '-------'}</LclTData>
                                        <LclTData maxWidth='110px'>
                                            <LclTable>
                                                <LclTRow>
                                                    <LclTData maxWidth='50px'> Present </LclTData>
                                                    <LclTData maxWidth='50px'> Change To </LclTData>
                                                </LclTRow>
                                                <LclTRow>

                                                    <LclTData maxWidth='50px' color='green' fontSize='15px'>{showLogo.get('visibility') === false ? <b style={{ cursor: 'pointer' }}>Hide <BiHide /></b> : <b>Show  <BiShowAlt /> </b>}</LclTData>
                                                    <LclTData maxWidth='50px' color='red' fontSize='15px'>{
                                                        showLogo.get('visibility') === false ? <small style={{ cursor: 'pointer' }} onClick={() => handleVisibilityShow({ showLogo })}> Show <BiShowAlt /></small> : <small style={{ cursor: 'pointer' }} onClick={() => handleVisibilityHide({ showLogo })}> Hide   <BiHide /></small>
                                                    }</LclTData>
                                                </LclTRow>
                                            </LclTable>

                                        </LclTData>
                                        <LclTable maxWidth='90px'>
                                            <LclTRow display='flex' flexDirection='column'>

                                                <LclTData maxWidth='100%' color='red' fontSize='15px' textAlign='center'>
                                                    <small style={{ cursor: 'pointer' }} onClick={() => { setUpdatedContent(showLogo); setInputs({ ...inputs, navLogoLink: showLogo.get("navLogoLink") }); setShowForm(!showForm) }}>{showForm ? 'Cancel' : 'Update'}</small>
                                                </LclTData>
                                                <LclTData maxWidth='100%' color='red' fontSize='15px' textAlign='center'>
                                                    {/* <small style={{ cursor: 'pointer' }} onClick={() => { setShowDeleteModal(true); setDeleteContent({ data: showLogo }) }}> Delete  <AiFillDelete /></small> */}

                                                </LclTData>
                                            </LclTRow>

                                        </LclTable>
                                    </LclTRow> : null
                            }
                        </LclTable> </DashContentTableDiv> : null}

                    {showForm && <form onSubmit={(e) => handleSubmitContent(e)}>
                        <InputGroupDiv>
                            <InputGroup width='33.33333%'>
                                <label>Nabvar item name :</label>
                                <input type='text' value={AllInputs.name} onChange={(e) => setAllInputs({ ...AllInputs, name: e.target.value })} />
                            </InputGroup>
                            <InputGroup width='33.33333%'>
                                <label> Route : </label>
                                <input type='text' value={AllInputs.route} onChange={(e) => setAllInputs({ ...AllInputs, route: e.target.value })} />
                            </InputGroup>
                            <InputGroup width='33.33333%'>
                                <PrimaryButton>Submit</PrimaryButton>
                                {/* <input type='submit' value='Submit' /> */}
                            </InputGroup>
                        </InputGroupDiv>
                    </form>}


                    <DashContentTableDiv>
                        {/* <select onClick={(e) => setDropdownOption(e.target.value)}>
                                <option value='AsBoth'>Show page and section name as navbar item </option>
                                <option value='AsSection'>Show section name as navbar item</option>
                                <option value='AsPage'>Show page name as navbar item</option>
                            </select> */}
                        {
                            showNavData !== undefined ? <LclTable>
                                <LclTRow>
                                    <LclTData>Item Type</LclTData>
                                    <LclTData>Navbar Item</LclTData>
                                    <LclTData>Navbar Route</LclTData>
                                    <LclTData>Create/Update Info</LclTData>
                                    <LclTData maxWidth='70px'>Visibility</LclTData>
                                    <LclTData maxWidth='50px'>Manage</LclTData>

                                </LclTRow>



                                {showNavData.map(showData =>
                                    <LclTRow>
                                        <LclTData> {showData.get("ItemType") && showData.get("ItemType")} </LclTData>
                                        <LclTData> {showData.get("ItemName") ? showData.get("ItemName") : '------'} </LclTData>
                                        <LclTData> {showData.get("ItemRoute") ? showData.get("ItemRoute") : '-----'} </LclTData>
                                        <LclTData>
                                            <LclTable>
                                                <LclTRow display='flex' flexDirection='column'>
                                                    <LclTData fontSize='15px' textAlign='center'>
                                                        Created at: {showData.get("createdAt") !== undefined && FormatTime(showData.get("createdAt").toUTCString())}
                                                    </LclTData>
                                                    <LclTData fontSize='15px' textAlign='center'>
                                                        Last Updated at: {showData.get("updatedAt") !== undefined && FormatTime(showData.get("updatedAt").toUTCString())}
                                                    </LclTData>
                                                </LclTRow>

                                            </LclTable>
                                        </LclTData>
                                        <LclTData >
                                            <LclTable>
                                                <LclTRow>
                                                    <LclTData> Present </LclTData>
                                                    <LclTData> Change To </LclTData>
                                                </LclTRow>
                                                <LclTRow>

                                                    <LclTData color='green' fontSize='15px'>{showData.get('visibility') === false ? <b>Hide <BiHide /></b> : <b>Show  <BiShowAlt /> </b>}</LclTData>
                                                    <LclTData  >{
                                                        showData.get('visibility') === false ? <small style={{ margin: '10px', color: 'red', fontSize: '15px', cursor: 'pointer', }} onClick={() => handleVisibilityShow({ showData })}> Show <BiShowAlt /></small> : <small style={{ margin: '10px', color: 'red', fontSize: '15px', cursor: 'pointer', }} onClick={() => handleVisibilityHide({ showData })}> Hide   <BiHide /></small>
                                                    }</LclTData>
                                                </LclTRow>
                                            </LclTable>

                                        </LclTData>
                                        <LclTable maxWidth='90px'>
                                            <LclTRow display='flex' flexDirection='column'>

                                                <LclTData maxWidth='100%' color='red' fontSize='15px' textAlign='center'>
                                                    <small style={{ cursor: 'pointer' }} onClick={() => HandleUpdate(showData)}> Update  </small>
                                                </LclTData>

                                            </LclTRow>

                                        </LclTable>
                                    </LclTRow>)}

                            </LclTable> : null
                        }


                    </DashContentTableDiv>


                </>
            }

            {
                PropsData.className === 'Footer' && <>

                    <PrimaryButton onClick={() => { setShowForm(!showForm); setUpdatedContent(!updatedContent) }} margin='1em 0'>{showForm ? 'Cancel' : 'Add new technology'}</PrimaryButton>


                    {showForm && <form onSubmit={handleSubmitContent}>
                        <label>Technology Name: </label>
                        <input required type='text' placeholder='Technology Name' onChange={e => setFooterInputs({ ...FooterInputs, technologyName: e.target.value })} value={FooterInputs.technologyName} />

                        <label>Logo: </label> < br />
                        {
                            changeImage ?
                                <div style={{ border: '1px solid red', width: 'fit-content', padding: '0 10px' }}>
                                    <img src={FooterInputs.technologyLogo['_url']} alt='logo' height={'150px'} width={'150px'} style={{ objectFit: 'contain' }} />< br />
                                    <p style={{ cursor: 'pointer', color: 'red' }} onClick={() => setChangeImage(false)}>change image ?</p>
                                </div> :
                                <input required type='file' onChange={e => setFooterInputs({ ...FooterInputs, technologyLogo: e.target.files[0] })} />
                        }
                        < br />
                        <PrimaryButton>Submit</PrimaryButton>
                    </form>
                    }

                    <DashContentTableDiv>

                        <LclTable>
                            <LclTRow>
                                <LclTData>Technology</LclTData>
                                <LclTData>Logo</LclTData>
                                <LclTData>Create/Update Info</LclTData>
                                <LclTData>Visibility</LclTData>
                                <LclTData>Manage</LclTData>

                            </LclTRow>

                            {showFooterData !== undefined && showFooterData.map(showData =>

                                <LclTRow>
                                    <LclTData> {showData.get("technologyName") ? showData.get("technologyName") : '------'} </LclTData>
                                    <LclTData> {showData.get("technologyLogo") ? <img src={showData.get("technologyLogo")['_url']} alt='logo' height={'150px'} width={'150px'} style={{ objectFit: 'contain' }} /> : '-----'} </LclTData>
                                    <LclTData>
                                        <LclTable>
                                            <LclTRow display='flex' flexDirection='column'>
                                                <LclTData fontSize='15px' textAlign='center'>
                                                    Created at: {showData.get("createdAt") !== undefined && FormatTime(showData.get("createdAt").toUTCString())}
                                                </LclTData>
                                                <LclTData fontSize='15px' textAlign='center'>
                                                    Created by: {showData.get("createdBy") !== undefined && showData.get("createdBy").get('username')}
                                                </LclTData>
                                                <LclTData fontSize='15px' textAlign='center'>
                                                    Last Updated at: {showData.get("updatedAt") !== undefined && FormatTime(showData.get("updatedAt").toUTCString())}
                                                </LclTData>
                                                <LclTData fontSize='15px' textAlign='center'>
                                                    Updated by: {showData.get("updatedBy") !== undefined && showData.get("updatedBy").get('username')}
                                                </LclTData>
                                            </LclTRow>
                                        </LclTable>
                                    </LclTData>
                                    <LclTData >
                                        <LclTable>
                                            <LclTRow>
                                                <LclTData> Present </LclTData>
                                                <LclTData> Change To </LclTData>
                                            </LclTRow>
                                            <LclTRow>
                                                <LclTData color='green' fontSize='15px'>{showData.get('visibility') === false ? <b>Hide <BiHide /></b> : <b>Show  <BiShowAlt /> </b>}</LclTData>
                                                <LclTData  >{
                                                    showData.get('visibility') === false ? <small style={{ margin: '10px', color: 'red', fontSize: '15px', cursor: 'pointer', }} onClick={() => handleVisibilityShow({ showData })}> Show <BiShowAlt /></small> : <small style={{ margin: '10px', color: 'red', fontSize: '15px', cursor: 'pointer', }} onClick={() => handleVisibilityHide({ showData })}> Hide   <BiHide /></small>
                                                }</LclTData>
                                            </LclTRow>
                                        </LclTable>
                                    </LclTData>
                                    <LclTData>
                                        <LclTable >
                                            <LclTRow display='flex' flexDirection='column'>
                                                <LclTData maxWidth='100%' color='red' fontSize='15px' textAlign='center'>
                                                    <small style={{ cursor: 'pointer' }} onClick={() => HandleUpdate(showData)}> Update  </small>
                                                </LclTData>
                                                <LclTData maxWidth='100%' color='red' fontSize='15px' textAlign='center'>
                                                    <small style={{ cursor: 'pointer' }} onClick={() => HandleDelete(showData)}> Delete  </small>
                                                </LclTData>
                                            </LclTRow>
                                        </LclTable>
                                    </LclTData>
                                </LclTRow>
                            )}





                        </LclTable>

                    </DashContentTableDiv>

                </>
            }

        </DashContentDiv >
    );
};

export default DashNavbarAndFooter;