import Styled from "styled-components"

export const NavbarContainer = Styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 10px 20px;
    height: 80px;
    width: 80%
    margin: 0 auto;
    transition: all 0.3s ease-in-out;
    position: absolute;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: all 0.3s ease-in-out;
    @media (max-width: 768px) {
        padding: 5px 20px;
        height: auto;
        // width: 100%;
    }
`
export const NavbarLogoContainer = Styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
    }

    @media (max-width: 768px) {
            width: 60px;
            height: auto;

    }
`
export const NavbarItemContainer = Styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;

    @media (max-width: 768px) {
        display: ${props => props.mediaDisplay};
        position: absolute;
        top: 100px;
        bottom: 0;
        height: fit-content;
        background: #001526;
        left: 0;
        width: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 0 10px;
        transform: translate(${props => props.mediaTransform});
    }
    // width: 100px;
    // height: 100%;
`
export const NavbarItem = Styled.div`
    color: #fff;
    text-decoration: none !important;
    font-size: 14px;
    // font-weight: bold;
    text-transform: uppercase;
    padding: 20px 10px;
    // border-bottom: 2px solid transparent;
    cursor: pointer;
    &:hover {
        // border-bottom: 2px solid white;
        color: #ff5454;
    } 
    @media (max-width: 768px) {
        width: 90%;
        text-align: start;
    }
`


export const NavbarItemIcon = Styled.div`
   display: ${props => props.display || "none"};
   
   @media (max-width: 768px) {
       display: flex;
       position: absolute;
       right: 20px;
       top: ${props => props.top || 'auto'};
       height: 35px;
       width: 35px;
       background-color: #ff5454;
       color: #fff;
       border-radius: 5%;
       cursor: pointer;
       align-items: center;
       justify-content: center;
}
`

export const NavbarItemIconBar1 = Styled.div`
   display: none;
   
   @media (max-width: 768px) {
       display: block;
       height: 3px;
       width: 25px;
       background-color: #fff;
       color: #fff;
       border-radius: 5%;
       cursor: pointer;
       position:absolute;
       right: 28px;
       top:${props => props.top || "48px"};
       transform: ${props => props.transform};

}

`

export const NavbarItemIconBar2 = Styled.div`
   display: none;
   
   @media (max-width: 768px) {
       display: ${props => props.display || "block"};
       height: 3px;
       width: 25px;
       background-color: #fff;
       color: #fff;
       border-radius: 5%;
       cursor: pointer;
       position:absolute;
       right: 28px; 

}

`

export const NavbarItemIconBar3 = Styled.div`
   display: none;
   
   @media (max-width: 768px) {
       display: block;
       height: 3px;
       width: 25px;
       background-color: #fff;
       color: #fff;
       border-radius: 5%;
       cursor: pointer;
       position:absolute;
       right: 28px; 
       top:${props => props.top || "68px"};
       transform: ${props => props.transform};

}

`