import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Dashboard from './Dashboard/Dashboard';
import Parse from 'parse/dist/parse.min.js';
import PrivateRoute from './Dashboard/PrivateRoute';
import DashboardLogin from './Dashboard/DashboardLogin';
import { createContext, useEffect, useState } from 'react';
import DynamicFront from './Pages/DynamicFront';
import AOS from 'aos';
import 'aos/dist/aos.css';




// Parse.initialize(process.env.REACT_APP_APPLICATION_ID, process.env.REACT_APP_JAVASCRIPT_KEY);
// Parse.serverURL = process.env.REACT_APP_HOST_URL;
// Parse.initialize('sbnmKmVwlqsGvp80TAyppfRqJxO3ysF58azrf6P8', '7pA5A3kAY48oEdroZeRgQg66OwMvQdX1bjChlMC5');
Parse.initialize('jnadf71b266416185aa4d75432f8353007la6b10', '4cbd01fe42003a63f6fh032fd06a7gl01d60da97');

Parse.serverURL = "https://api.virtualmuseumbd.com/v1";


export const DataContext = createContext();

function App() {

  const [PageRefresh, setPageRefresh] = useState(false);
  // const [FoundPage, setFoundPage] = useState([]);
  const [AppearanceInput, setAppearanceInput] = useState({
    dashboardMenuBGColor: '',
    dashContentTheme: false,
    primaryButtonBGColor: '',
    dangerButtonBGColor: '',
    successButtonBGColor: '',
    fontFamily: '',
    h1FontSize: '',
    h1FontWeight: '',
    h1TextTransformation: '',
    h1Color: '',
    h1TextFormating: '',
    h2FontSize: '',
    h2FontWeight: '',
    h2TextTransformation: '',
    h2Color: '',
    h2TextFormating: '',
    h3FontSize: '',
    h3FontWeight: '',
    h3TextTransformation: '',
    h3Color: '',
    h3TextFormating: '',
    h4FontSize: '',
    h4FontWeight: '',
    h4TextTransformation: '',
    h4Color: '',
    h4TextFormating: '',
    h5FontSize: '',
    h5FontWeight: '',
    h5TextTransformation: '',
    h5Color: '',
    h5TextFormating: '',
    h6FontSize: '',
    h6FontWeight: '',
    h6TextTransformation: '',
    h6Color: '',
    h6TextFormating: '',
    pFontSize: '',
    pFontWeight: '',
    pTextTransformation: '',
    pColor: '',
    pTextFormating: '',
  });

  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, []);

  // useEffect(() => {

  //   const DashAppearance = async () => {
  //     const query = new Parse.Query('Appearance');
  //     const foundDashboard = await query.first();

  //     if (foundDashboard) {
  //       setAppearanceInput({
  //         dashboardMenuBGColor: foundDashboard.get('dashboardMenuBGColor'),
  //         dashContentTheme: foundDashboard.get('dashContentTheme'),
  //         primaryButtonBGColor: foundDashboard.get('primaryButtonBGColor'),
  //         dangerButtonBGColor: foundDashboard.get('dangerButtonBGColor'),
  //         successButtonBGColor: foundDashboard.get('successButtonBGColor'),
  //         fontFamily: foundDashboard.get('fontFamily'),
  //         h1FontSize: foundDashboard.get('h1FontSize'),
  //         h1TextTransformation: foundDashboard.get('h1FontTransformation'),
  //         h1Color: foundDashboard.get('h1Color'),
  //         h1TextFormating: foundDashboard.get('h1FontFormating'),
  //         h2FontSize: foundDashboard.get('h2FontSize'),
  //         h2TextTransformation: foundDashboard.get('h2FontTransformation'),
  //         h2Color: foundDashboard.get('h2Color'),
  //         h2TextFormating: foundDashboard.get('h2FontFormating'),
  //         h3FontSize: foundDashboard.get('h3FontSize'),
  //         h3TextTransformation: foundDashboard.get('h3FontTransformation'),
  //         h3Color: foundDashboard.get('h3Color'),
  //         h3TextFormating: foundDashboard.get('h3FontFormating'),
  //         h4FontSize: foundDashboard.get('h4FontSize'),
  //         h4TextTransformation: foundDashboard.get('h4FontTransformation'),
  //         h4Color: foundDashboard.get('h4Color'),
  //         h4TextFormating: foundDashboard.get('h4FontFormating'),
  //         h5FontSize: foundDashboard.get('h5FontSize'),
  //         h5TextTransformation: foundDashboard.get('h5FontTransformation'),
  //         h5Color: foundDashboard.get('h5Color'),
  //         h5TextFormating: foundDashboard.get('h5FontFormating'),
  //         h6FontSize: foundDashboard.get('h6FontSize'),
  //         h6TextTransformation: foundDashboard.get('h6FontTransformation'),
  //         h6Color: foundDashboard.get('h6Color'),
  //         h6TextFormating: foundDashboard.get('h6FontFormating'),
  //         pFontSize: foundDashboard.get('pFontSize'),
  //         pTextTransformation: foundDashboard.get('pFontTransformation'),
  //         pColor: foundDashboard.get('pColor'),
  //         pTextFormating: foundDashboard.get('pFontFormating'),
  //       });
  //     }
  //   }
  //   DashAppearance();

  //   // setProperty();

  //   return () => {
  //     window.removeEventListener('click', DashAppearance);
  //   }

  // }, [setAppearanceInput]);
  useEffect(() => {

    const CompanyProfile = async () => {
      const query = new Parse.Query('CompanyProfile');
      const foundDashboard = await query.first();
      document.getElementById('SiteTitle').innerHTML = foundDashboard.get('companyName');
      document.getElementById('SiteFavIcon').href = foundDashboard.get('companyLogo') ? foundDashboard.get('companyLogo')['_url'] : undefined;
    }
    CompanyProfile();

    // setProperty();

    return () => {
      window.removeEventListener('click', CompanyProfile);
    }

  }, []);
  // useEffect(() => {

  //   const pageQuery = async () => {
  //     const query = new Parse.Query('Pages');
  //     const foundItem = await query.find();
  //     setFoundPage(foundItem);
  //   }
  //   pageQuery();

  //   return () => {
  //     window.removeEventListener('click', pageQuery);
  //   }

  // }, []);
  return (
    <DataContext.Provider value={{ arrValue: [AppearanceInput, setAppearanceInput, PageRefresh, setPageRefresh] }}>
      <Router>
        <Switch>
          <PrivateRoute exact path='/vmbdash'>
            <Dashboard />
          </PrivateRoute>
          <Route exact path='/login'>
            <DashboardLogin />
          </Route>
          <Route exact path="/" >
            <DynamicFront />
          </Route>
        </Switch>
      </Router>
    </DataContext.Provider >
  );
}

export default App;
