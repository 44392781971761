// import React, { useEffect, useState } from 'react';
// import Navbar from '../Component/Navbar/Navbar';
// import './Home.css';
// import ScrollButton from '../Component/ScrollBtn/ScrollButton';
// import { Button, Card, CardImage, CardText, CardTextDescription, CardTextTitle, Container, H1, H2, H3, IconContainer, Image, ImageContainer, P, Section, Source, TextContainer, VideoContainer } from '../Component/StyledComponent/VMBALLStyled';
// import { RiCloseFill } from 'react-icons/ri';
// import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai';
// import { BiMessageAltDetail, BiMailSend } from 'react-icons/bi';
// import { FaFacebook, FaTwitter, FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa';
// import ReactElasticCarousel from 'react-elastic-carousel';
// import ReactVisibilitySensor from 'react-visibility-sensor';
// import toast, { Toaster } from 'react-hot-toast';
// import Parse from 'parse/dist/parse.min.js';
// import Blender from '../Assets/Blender.png';
// import Megascan from '../Assets/Megascan.png';
// import Unreal from '../Assets/Unreal.svg';
// import Adobe from '../Assets/Adobe.png';
// import Zbrush from '../Assets/Zbrush.svg';
// import Autodesk from '../Assets/Autodesk.svg';


// const DynamicFront = (props) => {


//     const UsedInstrument = [
//         {
//             name: 'Unreal',
//             image: Unreal,
//             link: 'https://www.unrealengine.com/en-US/',
//         },
//         {
//             name: 'Quixel Megascan',
//             image: Megascan,
//             link: 'https://www.quixel.com/',
//         },
//         {
//             name: 'Blender',
//             image: Blender,
//             link: 'https://www.blender.org/',
//         },
//         {
//             name: 'Adobe',
//             image: Adobe,
//             link: 'https://www.adobe.com/',
//         },
//         {
//             name: 'ZBrush',
//             image: Zbrush,
//             link: 'https://www.zbrush.com/',
//         },
//         {
//             name: 'Autodesk',
//             image: Autodesk,
//             link: 'https://www.autodesk.com/',
//         },
//     ]
//     // console.log('window.pageYOffset', window.pageYOffset)

//     const [ShowForm, setShowForm] = useState(false);
//     // const [ShowVr, setShowVr] = useState(false);
//     const [ActiveItem, setActiveItem] = useState('Photos');
//     const [ActiveArticle, setActiveArticle] = useState('Article');
//     const [CompanyProfile, setCompanyProfile] = useState([]);
//     const [ShowSections, setShowSections] = useState([]);
//     const [AllSectionContent, setAllSectionContent] = useState([]);
//     const [SectionContent, setSectionContent] = useState([]);
//     const SectionContentSorting = SectionContent.sort((firstItem, secondItem) => firstItem.get('sectionPosition') - secondItem.get('sectionPosition'));
//     const [ShowModal, setShowModal] = useState(false);
//     const [ImgSrc, setImgSrc] = useState({});
//     const [ImageZoom, setImageZoom] = useState(false);
//     const [MassageInput, setMassageInput] = useState({
//         name: '',
//         email: '',
//         massage: '',
//         phone: ''
//     });
//     const [Render, setRender] = useState();

//     const onVisibilityChange = (isVisible = Boolean) => {
//         if (isVisible) {
//             setRender(true);
//         }
//     };

//     const PagePath = window.location.pathname.replace(/\\|\//g, '');


//     useEffect(() => {
//         const allFetch = async () => {

//             const fetchPages = async () => {
//                 const pagesQuery = new Parse.Query('Pages');
//                 // const navarLogoQuery = new Parse.Query('NavbarLogo');
//                 // let foundNavarLogo = await navarLogoQuery.first();

//                 // const navLogoLink = (foundNavarLogo !== undefined ? foundNavarLogo.get('navLogoLink').replace(/\\|\//g, '') : undefined);


//                 // if (props.props) {
//                 //     pagesQuery.equalTo('pageRoot', props.props);
//                 // }
//                 // if (!props.props) {
//                 //     pagesQuery.equalTo('pageRoute', PagePath);
//                 //     // console.log('pageRoot', true);
//                 // }
//                 try {
//                     let allPages = await pagesQuery.first();
//                     if (allPages.get('visibility') === true) {
//                         if (allPages) {
//                             let sectionsQuery = new Parse.Query('Sections');
//                             sectionsQuery.equalTo('page', allPages);
//                             try {
//                                 let foundSections = await sectionsQuery.find();
//                                 setShowSections(foundSections);
//                                 // console.log('foundSections', foundSections);
//                                 return true;
//                             } catch (error) {
//                                 // console.log('section error', error);
//                             }
//                         }
//                     }
//                 } catch (error) {
//                     // console.log('page error', error);
//                 }
//             }
//             await fetchPages();

//             const FetchCompanyProfile = async () => {
//                 const CompanyProfileQuery = new Parse.Query('CompanyProfile');

//                 try {
//                     let allCompanyProfile = await CompanyProfileQuery.find();
//                     allCompanyProfile.map(item => item.get('visibility') === true ? setCompanyProfile([item]) : null)
//                     // setCompanyProfile(allCompanyProfile);

//                 } catch (error) {
//                     // console.log('page error', error);
//                 }
//             }
//             await FetchCompanyProfile();
//         }
//         allFetch();
//         window.scrollTo(0, 0);
//         // window.addEventListener('click', allFetch);

//         return () => {
//             window.removeEventListener('click', allFetch);
//         }
//     }, [PagePath]);


//     useEffect(() => {
//         setAllSectionContent([]);
//         setSectionContent([]);
//         const FetchAllContent = async () => {
//             const FetchLandingPage = async () => {
//                 let LandingPageQuery = new Parse.Query('LandingPage');
//                 if (ShowSections !== undefined || null) {
//                     for (let newType of ShowSections) {
//                         if (newType.get('type') === 'LandingPage') {
//                             LandingPageQuery.equalTo("section", newType);
//                             setSectionContent(all => [...all, newType]);
//                             try {
//                                 let FoundCard = await LandingPageQuery.find();
//                                 setAllSectionContent(all => [...all, FoundCard]);
//                                 // console.log(foundTiltPicture);
//                             } catch (error) {
//                                 // console.log(error);

//                             }
//                         }
//                     }
//                 }
//             }
//             await FetchLandingPage();
//             const FetchVRTour = async () => {
//                 let ItemQuery = new Parse.Query('VRTour');
//                 if (ShowSections !== undefined || null) {
//                     for (let newType of ShowSections) {
//                         if (newType.get('type') === 'VRTour') {
//                             ItemQuery.equalTo("section", newType);
//                             setSectionContent(all => [...all, newType]);
//                             try {
//                                 let FoundItem = await ItemQuery.find();
//                                 setAllSectionContent(all => [...all, FoundItem]);
//                                 // console.log(foundTiltPicture);
//                             } catch (error) {
//                                 // console.log(error);

//                             }
//                         }
//                     }
//                 }
//             }
//             await FetchVRTour();
//             const FetchAbout = async () => {
//                 let ItemQuery = new Parse.Query('About');
//                 if (ShowSections !== undefined || null) {
//                     for (let newType of ShowSections) {
//                         if (newType.get('type') === 'About') {
//                             ItemQuery.equalTo("section", newType);
//                             setSectionContent(all => [...all, newType]);
//                             try {
//                                 let FoundItem = await ItemQuery.find();
//                                 setAllSectionContent(all => [...all, FoundItem]);
//                                 // console.log(foundTiltPicture);
//                             } catch (error) {
//                                 // console.log(error);

//                             }
//                         }
//                     }
//                 }
//             }
//             await FetchAbout();
//             const FetchCard = async () => {
//                 let CardQuery = new Parse.Query('Card');
//                 if (ShowSections !== undefined || null) {
//                     for (let newType of ShowSections) {
//                         if (newType.get('type') === 'Card') {
//                             CardQuery.equalTo("section", newType);
//                             setSectionContent(all => [...all, newType]);
//                             try {
//                                 let FoundCard = await CardQuery.find();
//                                 setAllSectionContent(all => [...all, FoundCard]);
//                                 // console.log(foundTiltPicture);
//                             } catch (error) {
//                                 // console.log(error);

//                             }
//                         }
//                     }
//                 }
//             }
//             await FetchCard();
//             const FetchPhotoGallery = async () => {
//                 let PhotoGalleryQuery = new Parse.Query('PhotoGallery');
//                 if (ShowSections !== undefined || null) {
//                     for (let newType of ShowSections) {
//                         if (newType.get('type') === 'PhotoGallery') {
//                             PhotoGalleryQuery.equalTo("section", newType);
//                             setSectionContent(all => [...all, newType]);
//                             try {
//                                 let FoundPhotoGallery = await PhotoGalleryQuery.find();
//                                 setAllSectionContent(all => [...all, FoundPhotoGallery]);;
//                                 // console.log(foundTiltPicture);
//                             } catch (error) {
//                                 // console.log(error);

//                             }
//                         }
//                     }
//                 }
//             }
//             await FetchPhotoGallery();
//             const FetchVideoGallery = async () => {
//                 let VideoGalleryQuery = new Parse.Query('VideoGallery');
//                 if (ShowSections !== undefined || null) {
//                     for (let newType of ShowSections) {
//                         if (newType.get('type') === 'VideoGallery') {
//                             VideoGalleryQuery.equalTo("section", newType);
//                             setSectionContent(all => [...all, newType]);
//                             try {
//                                 let FoundVideoGallery = await VideoGalleryQuery.find();
//                                 setAllSectionContent(all => [...all, FoundVideoGallery]);;
//                                 // console.log(foundTiltPicture);
//                             } catch (error) {
//                                 // console.log(error);

//                             }
//                         }
//                     }
//                 }
//             }
//             await FetchVideoGallery();

//             const FetchNews = async () => {
//                 let ArticleQuery = new Parse.Query('News');
//                 if (ShowSections !== undefined || null) {
//                     for (let newType of ShowSections) {
//                         if (newType.get('type') === 'News') {
//                             ArticleQuery.equalTo("section", newType);
//                             setSectionContent(all => [...all, newType]);
//                             try {
//                                 let FoundArticle = await ArticleQuery.find();
//                                 setAllSectionContent(all => [...all, FoundArticle]);;
//                                 // console.log(foundTiltPicture);
//                             } catch (error) {
//                                 // console.log(error);

//                             }
//                         }
//                     }
//                 }
//             }
//             await FetchNews();


//             // just for retry 
//             // const FetchTestimonials = async () => {
//             //     let ArticleQuery = new Parse.Query('News');
//             //     if (ShowSections !== undefined || null) {
//             //         for (let newType of ShowSections) {
//             //             if (newType.get('type') === 'News') {
//             //                 ArticleQuery.equalTo("section", newType);
//             //                 setSectionContent(all => [...all, newType]);
//             //                 try {
//             //                     let FoundArticle = await ArticleQuery.find();
//             //                     setAllSectionContent(all => [...all, FoundArticle]);;
//             //                     // console.log(foundTiltPicture);
//             //                 } catch (error) {
//             //                     // console.log(error);

//             //                 }
//             //             }
//             //         }
//             //     }
//             // }
//             // await FetchTestimonials();



//             // end of retry

//             //comment out fetch articles for practices
//             // const FetchArticle = async () => {
//             //     let ItemQuery = new Parse.Query('Article');
//             //     if (ShowSections !== undefined || null) {
//             //         for (let newType of ShowSections) {
//             //             if (newType.get('type') === 'Article') {
//             //                 ItemQuery.equalTo("section", newType);
//             //                 setSectionContent(all => [...all, newType]);
//             //                 try {
//             //                     let FoundItem = await ItemQuery.find();
//             //                     setAllSectionContent(all => [...all, FoundItem]);

//             //                 } catch (error) {
//             //                     // console.log(error);

//             //                 }
//             //             }
//             //         }
//             //     }
//             // }
//             // await FetchArticle();
//             const FetchGallery = async () => {
//                 let ItemQuery = new Parse.Query('Gallery');
//                 if (ShowSections !== undefined || null) {
//                     for (let newType of ShowSections) {
//                         if (newType.get('type') === 'Gallery') {
//                             ItemQuery.equalTo("section", newType);
//                             setSectionContent(all => [...all, newType]);
//                             try {
//                                 let FoundItem = await ItemQuery.find();
//                                 setAllSectionContent(all => [...all, FoundItem]);

//                             } catch (error) {
//                                 // console.log(error);

//                             }
//                         }
//                     }
//                 }
//             }
//             await FetchGallery();
//         }

//         // window.addEventListener('click',fetchAllContent);
//         FetchAllContent();
//         return () => {
//             window.removeEventListener('click', FetchAllContent);
//         }


//     }, [ShowSections]);





//     const FormInputs = [
//         {
//             required: true,
//             name: 'Name',
//             type: 'text',
//             placeholder: 'Enter your name',
//             value: MassageInput.name,
//             onChange: (e) => {
//                 setMassageInput({ ...MassageInput, name: e.target.value });
//             },
//         },
//         {
//             required: true,
//             name: 'Email',
//             type: 'email',
//             placeholder: 'Enter your email',
//             value: MassageInput.email,
//             onChange: (e) => {
//                 setMassageInput({ ...MassageInput, email: e.target.value });
//             }

//         },
//         {
//             required: true,
//             name: 'Phone Number',
//             type: 'number',
//             placeholder: 'Enter your phone number',
//             value: MassageInput.phone,
//             onChange: (e) => {
//                 setMassageInput({ ...MassageInput, phone: e.target.value })
//             },
//         },
//         {
//             required: true,
//             name: 'Massage',
//             type: 'textarea',
//             placeholder: 'Enter your massage',
//             value: MassageInput.massage,
//             onChange: (e) => {
//                 setMassageInput({ ...MassageInput, massage: e.target.value })
//             },
//         },
//     ]


//     const breakPoints = [
//         { width: 1, itemsToShow: 1 },
//         { width: 550, itemsToShow: 2, itemsToScroll: 2 },
//         { width: 768, itemsToShow: 3 },
//         { width: 1200, itemsToShow: 3 },
//         { width: 1500, itemsToShow: 4 },

//     ];

//     const MassageSubmit = async (e) => {
//         e.preventDefault();
//         const { name, email, massage, phone } = MassageInput;

//         try {
//             const Massage = new Parse.Object('Massage');

//             Massage.set('name', name);
//             Massage.set('email', email);
//             Massage.set('phone', phone);
//             Massage.set('massage', massage);

//             await Massage.save();
//             setMassageInput({
//                 name: '',
//                 email: '',
//                 phone: '',
//                 massage: ''
//             })
//             toast.success('Massage Send Successfully');
//             setShowForm(false);
//             setShowModal(false);
//         } catch (error) {
//             // console.log(error);
//             toast.error('Something went wrong!');
//         }
//     }



//     return (
//         <>
//             <Navbar />

//             {/* <div className={SectionContent.length === 0 && AllSectionContent.length === 0 ? 'dataPreLoaderActive' : 'dataPreLoader'}>
//                 <img src={Preloader} alt='loader' />
//             </div> */}

//             {SectionContent.length !== 0 && AllSectionContent.length !== 0 ? <>
//                 {
//                     CompanyProfile.map((profile, key) =>
//                         <div key={key} style={profile.get('companyFacebook') || profile.get('companyTwitter') || profile.get('companyYoutube') || profile.get('companyInstagram') || profile.get('companyLinkedIn') ? { position: 'fixed', display: 'flex', flexDirection: 'column', bottom: '85px', left: '-5px', background: 'white', padding: '5px', borderRadius: '5px' } : { display: 'none' }}>
//                             {profile.get('companyFacebook') && <a href={profile.get('companyFacebook')} target='_blank' rel="noreferrer" style={{ margin: '3px 5px 3px  ' }}><FaFacebook size={'1.5rem'} color='#1876f2' /></a >}
//                             {profile.get('companyTwitter') && <a href={profile.get('companyTwitter')} target='_blank' rel="noreferrer" style={{ margin: '3px 5px 3px  ' }}><FaTwitter size={'1.5rem'} color='#1d9bf0' /></a >}
//                             {profile.get('companyYoutube') && <a href={profile.get('companyYoutube')} target='_blank' rel="noreferrer" style={{ margin: '3px 5px 3px  ' }}><FaYoutube size={'1.5rem'} color='#ff0000' /></a >}
//                             {profile.get('companyInstagram') && <a href={profile.get('companyInstagram')} target='_blank' rel="noreferrer" style={{ margin: '3px 5px 3px  ' }}><FaInstagram size={'1.5rem'} color='#8a3ab9' /></a >}
//                             {profile.get('companyLinkedIn') && <a href={profile.get('companyLinkedIn')} target='_blank' rel="noreferrer" style={{ margin: '3px 5px 3px  ' }}><FaLinkedin size={'1.5rem'} color='#0e76a8' /></a >}
//                         </div>
//                     )
//                 }


//                 {
//                     ShowSections !== [] && SectionContent !== [] && AllSectionContent !== [] ?

//                         SectionContentSorting.map((section, idx) => (
//                             section.get('type') === 'LandingPage' && section.get('visibility') === true ?
//                                 <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} height='100vh'>

//                                     {/* <img src="http://www.virtualmuseumbd.com/wp-content/uploads/2020/09/VMB.0093_2a.jpg" alt="VMB_LOGO" className='VMB_Image' />  onClick={() => window.location.replace('/#Footer')}  */}
//                                     {AllSectionContent ? AllSectionContent.map(data => data.map((LandingPage, key) =>
//                                         LandingPage.className === 'LandingPage' && LandingPage.get('visibility') === true && LandingPage.get('section').id === section.id ?
//                                             <TextContainer key={key} height='100%' backgroundColor='#00000073' textAlign='center' mediaWidth='100%' display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
//                                                 <H2 data-aos='fade-bottom' color='#fff' textAlign='center' margin='20px 0'>{LandingPage.get('subHeading')}</H2>
//                                                 <H1 data-aos='fade-bottom' margin='20px 0 30px'>
//                                                     {LandingPage.get('heading')}
//                                                 </H1>
//                                                 <a data-aos='fade-bottom' href={LandingPage.get('link')} target='_blank' rel='noreferrer'> <Button>{LandingPage.get('buttonName')}</Button></a>
//                                             </TextContainer>
//                                             : null)) : null
//                                     }
//                                 </Section>
//                                 : section.get('type') === 'VRTour' && section.get('visibility') === true ?
//                                     <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} >
//                                         <Container>
//                                             {/* <Container backgroundColor='#232323' minHeight="200px" display='flex' flexDirection='column'> */}
//                                             <H3 display='flex' justifyContent="center" alignItems="center" color='#bfbfbf' backgroundColor='#232323' minHeight="250px">
//                                                 {section.get('sectionTitle')}
//                                             </H3>
//                                             {/* <Button onClick={() => setShowVr(!ShowVr)}>{ShowVr ? 'Hide' : 'Visit'}</Button> */}
//                                             {/* </Container>  && ShowVr */}

//                                             {AllSectionContent ? AllSectionContent.map(data => data.map((VRTour, key) =>
//                                                 VRTour.className === 'VRTour' && VRTour.get('visibility') === true && VRTour.get('section').id === section.id ?
//                                                     <iframe key={key} src={VRTour.get('link')} title='Vmb' height='700px' width='100%' allowFullScreen={true} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
//                                                     : null)) : null
//                                             }
//                                         </Container>

//                                     </Section>
//                                     : section.get('type') === 'About' && section.get('visibility') === true ?
//                                         <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#f5f5f5' padding='20px 0'>
//                                             <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
//                                                 <H2
//                                                     data-aos='fade-right'
//                                                     color='#2b2b2b'
//                                                     width="20%"
//                                                     borderBottom="3px solid #4c4c4c"
//                                                     padding="35px 0"
//                                                     margin='0 20px 20px'
//                                                 >{section.get('sectionTitle')}</H2>

//                                                 {AllSectionContent ? AllSectionContent.map(data => data.map((Item, key) =>
//                                                     Item.className === 'About' && Item.get('visibility') === true && Item.get('section').id === section.id ?
//                                                         <Container key={key} display='flex' justifyContent='space-between' mediaWidth='100%'>

//                                                             <TextContainer width='50%' color='#2b2b2b' padding='10px' data-aos={"fade-right"}>
//                                                                 <P>{Item.get('description')}</P>
//                                                                 <P>{Item.get('description1')}</P>
//                                                                 <P>{Item.get('description2')}</P>
//                                                             </TextContainer>

//                                                             <ImageContainer width='45%' data-aos={"fade-left"}>
//                                                                 <Image src={Item.get('image') ? Item.get('image')['_url'] : undefined} alt="VMB_LOGO" />
//                                                             </ImageContainer>

//                                                         </Container> : null)) : null
//                                                 }
//                                             </Container>
//                                         </Section>
//                                         : section.get('type') === 'Card' && section.get('visibility') === true ?
//                                             <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#ededed' padding='30px 0'>
//                                                 <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
//                                                     <H2 width="20%"
//                                                         borderBottom="1px solid #4c4c4c"
//                                                         borderWidth="thin"
//                                                         padding="35px 0"
//                                                         textAlign='center'
//                                                         data-aos='fade-up'
//                                                     >{section.get('sectionName')}</H2>
//                                                     <Container display='flex'>

//                                                         {AllSectionContent ? AllSectionContent.map(data => data.map((card, key) =>
//                                                             card.className === 'Card' && card.get('visibility') === true && card.get('section').id === section.id ?

//                                                                 <Card key={card.id} data-aos={key === 0 ? "fade-right" : key === 1 ? "fade-up" : "fade-left"}>
//                                                                     <CardImage>
//                                                                         <Image src={card.get('image') ? card.get('image')['_url'] : null} width='70%' transform='scale(1.1)' filter='opacity(0.5)' mediaFilter='opacity(1)' />
//                                                                     </CardImage>
//                                                                     <CardText mediaHeight='auto'>
//                                                                         <h3>{card.get('heading')}</h3>
//                                                                         <P textAlign='center'>{card.get('description')}</P>
//                                                                     </CardText>
//                                                                 </Card>
//                                                                 : null)) : null
//                                                         }


//                                                         {/* {
//                                                             FakeFeatures.map(feature => (
//                                                                 <Card key={feature.id}>
//                                                                     <CardImage>
//                                                                         <Image src={AboutUsImg} transform='scale(1.1)' />
//                                                                     </CardImage>
//                                                                     <CardText>
//                                                                         <h3>{feature.title}</h3>
//                                                                         <p>{feature.description}</p>
//                                                                     </CardText>
//                                                                 </Card>
//                                                             ))

//                                                         } */}
//                                                     </Container>
//                                                 </Container>
//                                             </Section>
//                                             : section.get('type') === 'PhotoGallery' && section.get('visibility') === true ?
//                                                 <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#011627' padding='30px 0'>
//                                                     <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
//                                                         <H2 width="20%"
//                                                             borderBottom="1px solid #4c4c4c"
//                                                             borderWidth="thin"
//                                                             padding="35px 0"
//                                                             textAlign='center'
//                                                             color='#fff'>{section.get('sectionName')}</H2>
//                                                         <Container display='flex' padding='50px 0 30px' textAlign='start' mediaFlexDirection='row' mediaTextAlign='center'>
//                                                             {AllSectionContent ? AllSectionContent.map(data => data.map((PhotoGallery, key) =>
//                                                                 PhotoGallery.className === 'PhotoGallery' && PhotoGallery.get('visibility') === true && PhotoGallery.get('section').id === section.id ?
//                                                                     <Image key={key} src={PhotoGallery.get('image') ? PhotoGallery.get('image')['_url'] : null} width={'380px'} height={'250px'} padding='20px 20px 0 0' margin='0' cursor='pointer'
//                                                                         onClick={() => { setShowModal(!ShowModal); setImgSrc(PhotoGallery.get('image')['_url']); }} transform='scale(1.04)'
//                                                                         mediaWidth='150px' mediaHeight='100px' />
//                                                                     : null)) : null
//                                                             }

//                                                         </Container>
//                                                     </Container>


//                                                 </Section>
//                                                 : section.get('type') === 'VideoGallery' && section.get('visibility') === true ?
//                                                     <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#fff' padding='30px 0'>
//                                                         <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
//                                                             <H2 width="20%"
//                                                                 borderBottom="1px solid #4c4c4c"
//                                                                 borderWidth="thin"
//                                                                 padding="35px 0"
//                                                                 textAlign='center'
//                                                                 color='#000'>{section.get('sectionName')}</H2>
//                                                             <Container display='flex' padding='50px 0 30px' textAlign='start'>
//                                                                 {AllSectionContent ? AllSectionContent.map(data => data.map((VideoGallery, key) =>
//                                                                     VideoGallery.className === 'VideoGallery' && VideoGallery.get('visibility') === true && VideoGallery.get('section').id === section.id ?
//                                                                         (VideoGallery.get('type') !== 'UploadVideo' ?
//                                                                             // Youtube emdede video link get
//                                                                             // <iframe key={key} width="560" height="315" title="YouTube video player" src={VideoGallery.get('embadedLink') ? VideoGallery.get('embadedLink') : null} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
//                                                                             <iframe key={key} width="560" height="315" title="YouTube video player" src={VideoGallery.get('embadedLink') ? VideoGallery.get('embadedLink') : undefined} ></iframe>
//                                                                             : VideoGallery.get('type'))
//                                                                         : null)) : null
//                                                                 }
//                                                                 {/* Youtube iframe
//                                                                  */}
//                                                             </Container>
//                                                         </Container>


//                                                     </Section>
//                                                     : section.get('type') === 'Article' && section.get('visibility') === true ?
//                                                         <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#fff' padding='30px 0'>
//                                                             <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
//                                                                 <H2 width="20%"
//                                                                     borderBottom="1px solid #4c4c4c"
//                                                                     borderWidth="thin"
//                                                                     padding="35px 0"
//                                                                     textAlign='center'
//                                                                     color='#000'
//                                                                     data-aos='fade-right'
//                                                                 >Articales</H2>
//                                                                 <Container display='flex' padding='50px 0 30px' textAlign='start'>


//                                                                     <ReactVisibilitySensor
//                                                                         scrollCheck={true}
//                                                                         onChange={onVisibilityChange}
//                                                                         delayedCall >
//                                                                         {/* <ReactElasticCarousel breakPoints={breakPoints} enableautoPlay={Render && 'true'} autoPlaySpeed={Render && 3000}> */}
//                                                                         {/* <Slider {...settings}> */}


//                                                                         {
//                                                                             AllSectionContent ? AllSectionContent.map(data => data.map((Item, key) =>
//                                                                                 Item.className === 'Article' && Item.get('visibility') === true && Item.get('section').id === section.id
//                                                                                     ?
//                                                                                     <Card key={key} width='auto' margin='0' >
//                                                                                         <CardImage width='100%' height='250px'>
//                                                                                             {/* <a href='/hello'> */}
//                                                                                             <Image src={Item.get('image') ? Item.get('image')['_url'] : undefined} transform='scale(1.01)' cursor='pointer' height='100%' mediaHeight='100%' borderRadius='5px' />
//                                                                                             {/* </a> */}
//                                                                                         </CardImage>
//                                                                                         <CardText height='250px' mediaTextAlign='start'>
//                                                                                             <CardTextTitle as='a' href={Item.get('link')} target='_blank'>{Item.get('heading')}</CardTextTitle>
//                                                                                             <CardTextDescription maxHeight='270px'>{Item.get('description')}</CardTextDescription>
//                                                                                             <Button padding='10px' borderRadius='5px' as='a' href={Item.get('link')} target='_blank'>Read more</Button>
//                                                                                         </CardText>
//                                                                                     </Card>
//                                                                                     : null)) : null
//                                                                         }
//                                                                         {/* </Slider> */}
//                                                                         {/* </ReactElasticCarousel> */}
//                                                                     </ReactVisibilitySensor>


//                                                                 </Container>
//                                                             </Container>


//                                                         </Section>
//                                                         : section.get('type') === 'News' && section.get('visibility') === true ?
//                                                             <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#fff' padding='30px 0'>
//                                                                 <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
//                                                                     <H2 width="20%"
//                                                                         borderBottom="1px solid #4c4c4c"
//                                                                         borderWidth="thin"
//                                                                         padding="35px 0"
//                                                                         textAlign='center'
//                                                                         color='#000'
//                                                                         data-aos='fade-right'
//                                                                     >{section.get('sectionTitle')}</H2>
//                                                                     <Container display='flex' flexDirection='row' mediaFlexDirection='row'>
//                                                                         {
//                                                                             ['Article', 'News'].map((item, key) => <H2
//                                                                                 mediaWidth='auto'
//                                                                                 mediaMargin='0'
//                                                                                 key={key}
//                                                                                 borderBottom={ActiveArticle === item ? "1px solid #000" : "1px solid transparent"}
//                                                                                 hoverColor='#000'
//                                                                                 hoverBorderBottom='1px solid transparent'
//                                                                                 margin='0'
//                                                                                 padding="10px"
//                                                                                 textAlign='center'
//                                                                                 color={ActiveArticle === item ? '#000' : '#bfbfbf'}
//                                                                                 cursor='pointer'
//                                                                                 onClick={() => { setActiveArticle(item) }}
//                                                                             // data-aos={key === 0 ? 'fade-right' : 'fade-left'}
//                                                                             >{item}</H2>)
//                                                                         }
//                                                                     </Container>
//                                                                     <Container display='flex' padding='50px 0 30px' mediaPadding='50px 0 30px' textAlign='start' mediaFlexDirection='row' mediaTextAlign='center'>



//                                                                         {
//                                                                             ActiveArticle === 'Article' &&
//                                                                             <ReactVisibilitySensor
//                                                                                 scrollCheck={true}
//                                                                                 onChange={onVisibilityChange}
//                                                                                 delayedCall
//                                                                                 data-aos='fade-up'>
//                                                                                 {/* <Slider {...settings}> */}
//                                                                                 <ReactElasticCarousel breakPoints={breakPoints} enableautoPlay={Render && 'true'} autoPlaySpeed={Render && 3000}>
//                                                                                     {/* <Fade bottom> */}
//                                                                                     {
//                                                                                         AllSectionContent ? AllSectionContent.map(data => data.map((Item, key) =>
//                                                                                             Item.className === 'News' && Item.get('visibility') === true && Item.get('section').id === section.id && Item.get('type') === 'Article'
//                                                                                                 ?
//                                                                                                 <Card key={key} width='auto' margin='0' mediaWidth='90%' data-aos='fade-up' >
//                                                                                                     <CardImage width='100%' height='250px'>
//                                                                                                         {/* <a href='/hello'> */}
//                                                                                                         <Image src={Item.get('image') ? Item.get('image')['_url'] : undefined} transform='scale(1.01)' height='100%' mediaHeight='100%' borderRadius='5px' />
//                                                                                                         {/* </a> */}
//                                                                                                     </CardImage>
//                                                                                                     <CardText height='250px' mediaTextAlign='start'>
//                                                                                                         <CardTextTitle as='a' href={Item.get('link')} target='_blank'>{Item.get('heading')}</CardTextTitle>
//                                                                                                         <CardTextDescription maxHeight='270px'>{Item.get('description')}</CardTextDescription>
//                                                                                                         <Button padding='10px' borderRadius='5px' as='a' href={Item.get('link')} target='_blank'>Read more</Button>
//                                                                                                     </CardText>
//                                                                                                 </Card>
//                                                                                                 : null)) : null
//                                                                                     }
//                                                                                     {/* </Fade> */}
//                                                                                 </ReactElasticCarousel>
//                                                                                 {/* </Slider> */}
//                                                                             </ReactVisibilitySensor>
//                                                                         }
//                                                                         {
//                                                                             ActiveArticle === 'News' && AllSectionContent ? AllSectionContent.map(data => data.map((Item, key) =>
//                                                                                 Item.className === 'News' && Item.get('visibility') === true && Item.get('section').id === section.id && Item.get('type') === 'Videos'
//                                                                                     ?
//                                                                                     <VideoContainer data-aos='fade-up' key={key} width={'390px'} height={'220px'} padding=' 0' margin='3px'
//                                                                                         borderRadius={'10px'} cursor='pointer'
//                                                                                         mediaWidth='80%' mediaHeight='220px'>
//                                                                                         {Item.get('videoType') === 'YoutubeVideo' ?
//                                                                                             // VideoGallery.get('embadedLink') :  
//                                                                                             // <iframe src={Item.get('embadedLink')} title={Item.get('videoTitle')} height='220px' frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" width='100%' allowFullScreen={true} style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }}></iframe>
//                                                                                             <iframe src={Item.get('embadedLink')} title={Item.get('videoTitle')} height='220px' width='100%' style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }} allowFullScreen={true} ></iframe> :
//                                                                                             Item.get('videoType') === 'VimeoVideo' ?
//                                                                                                 // VideoGallery.get('embadedLink') :  
//                                                                                                 <iframe src={Item.get('embadedLink')} title={Item.get('videoTitle')} height='220px' width='100%' style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }} allowFullScreen={true} ></iframe>
//                                                                                                 // <iframe src={Item.get('embadedLink')} height='220px' width='100%' frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} title={Item.get('videoTitle')} style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }}></iframe>
//                                                                                                 :
//                                                                                                 Item.get('videoType') === 'UploadVideo' ?
//                                                                                                     <video width={'100%'} height={'220px'} style={{ objectFit: 'cover', overflow: 'hidden', borderRadius: '10px', border: '0.1px solid #ababb678' }} controls>
//                                                                                                         <Source src={Item.get('video') ? Item.get('video')['_url'] : undefined} type='video/mp4' />
//                                                                                                     </video> : undefined}

//                                                                                     </VideoContainer>
//                                                                                     : null))
//                                                                                 : null
//                                                                         }

//                                                                     </Container>
//                                                                 </Container>


//                                                             </Section>
//                                                             : section.get('type') === 'Gallery' && section.get('visibility') === true ?
//                                                                 <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#2f2f2fdb' padding='0' opacity='0.8'>
//                                                                     <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
//                                                                         <H2 width="20%"
//                                                                             borderBottom="1px solid #4c4c4c"
//                                                                             borderWidth="thin"
//                                                                             padding="35px 0"
//                                                                             textAlign='center'
//                                                                             color='#fff'
//                                                                             data-aos='fade-down'
//                                                                         >{section.get('sectionTitle')}</H2>
//                                                                         <Container>
//                                                                             <Container display='flex' flexDirection='row' mediaFlexDirection='row'>
//                                                                                 {
//                                                                                     ['Photos', 'Game play'].map((item, key) => <H2
//                                                                                         mediaWidth='auto'
//                                                                                         mediaMargin='0'
//                                                                                         key={key}
//                                                                                         borderBottom={ActiveItem === item ? "1px solid #fff" : "1px solid transparent"}
//                                                                                         hoverColor='#fff'
//                                                                                         hoverBorderBottom='1px solid transparent'
//                                                                                         margin='0'
//                                                                                         padding="10px"
//                                                                                         textAlign='center'
//                                                                                         color={ActiveItem === item ? '#fff' : '#bfbfbf'}
//                                                                                         cursor='pointer'
//                                                                                         onClick={() => { setActiveItem(item) }}
//                                                                                         data-aos={key === 0 ? 'fade-right' : 'fade-left'}
//                                                                                     >{item}</H2>)
//                                                                                 }
//                                                                             </Container>
//                                                                             <Container display='flex' padding='50px 0 30px' mediaPadding='50px 0 30px' textAlign='start' mediaFlexDirection='row' mediaTextAlign='center'>

//                                                                                 {
//                                                                                     AllSectionContent ? AllSectionContent.map(data => data.map((PhotoGallery, key) =>
//                                                                                         PhotoGallery.className === 'Gallery' && PhotoGallery.get('type') === 'Photos' && PhotoGallery.get('visibility') === true && PhotoGallery.get('section').id === section.id ?
//                                                                                             // <GallerImageContainer>  
//                                                                                             ActiveItem === 'Photos' && <Image data-aos='fade-up' key={key} src={PhotoGallery.get('image') ? PhotoGallery.get('image')['_url'] : undefined} width={'290px'} height={'200px'} padding=' 0' margin='3px' border='0.1px solid #ababb678' borderRadius={'10px'} cursor='pointer'
//                                                                                                 onClick={() => { setShowModal(!ShowModal); setImgSrc(PhotoGallery.get('image') ? PhotoGallery.get('image')['_url'] : undefined); }}
//                                                                                                 hoverFilter='opacity(0.8)'
//                                                                                                 mediaWidth='150px' mediaHeight='100px' mediaMargin='3px' />

//                                                                                             : null)) : null
//                                                                                 }

//                                                                                 {
//                                                                                     ActiveItem === 'Game play' && AllSectionContent ? AllSectionContent.map(data => data.map((VideoGallery, key) =>
//                                                                                         VideoGallery.className === 'Gallery' && VideoGallery.get('type') === 'Videos' && VideoGallery.get('visibility') === true && VideoGallery.get('section').id === section.id ?

//                                                                                             <VideoContainer data-aos='fade-up' key={key} width={'390px'} height={'220px'} padding=' 0' margin='3px'
//                                                                                                 // border='0.1px solid #ababb678' 
//                                                                                                 borderRadius={'10px'} cursor='pointer'
//                                                                                                 // onClick={() => { setShowModal(!ShowModal); setImgSrc(data); setCurrentIndex(key) }}
//                                                                                                 // filter='opacity(0.8)'
//                                                                                                 mediaWidth='80%' mediaHeight='220px'>
//                                                                                                 {VideoGallery.get('videoType') === 'YoutubeVideo' ?
//                                                                                                     // VideoGallery.get('embadedLink') :  
//                                                                                                     <iframe src={VideoGallery.get('embadedLink')} title={VideoGallery.get('videoTitle')} allowFullScreen={true} height='220px' width='100%' style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }}></iframe>

//                                                                                                     // <iframe src={VideoGallery.get('embadedLink')} title={VideoGallery.get('videoTitle')} height='220px' width='100%' allowFullScreen={true} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }}></iframe> 
//                                                                                                     :
//                                                                                                     VideoGallery.get('videoType') === 'VimeoVideo' ?
//                                                                                                         // VideoGallery.get('embadedLink') :  
//                                                                                                         <iframe src={VideoGallery.get('embadedLink')} title={VideoGallery.get('videoTitle')} allowFullScreen={true} height='220px' width='100%' style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }}></iframe>
//                                                                                                         // <iframe src={VideoGallery.get('embadedLink')} height='220px' width='100%' frameBorder="0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} title={VideoGallery.get('videoTitle')} style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }}></iframe> .
//                                                                                                         :
//                                                                                                         VideoGallery.get('videoType') === 'UploadVideo' ?
//                                                                                                             <video width={'100%'} height={'220px'} style={{ objectFit: 'cover', overflow: 'hidden', borderRadius: '10px', border: '0.1px solid #ababb678' }} controls>
//                                                                                                                 <Source src={VideoGallery.get('video') ? VideoGallery.get('video')['_url'] : undefined} type='video/mp4' />
//                                                                                                             </video> : undefined}

//                                                                                             </VideoContainer> : null)) : null
//                                                                                 }
//                                                                             </Container>
//                                                                         </Container>
//                                                                     </Container>


//                                                                 </Section> : null))

//                         : 'Wait please'
//                 }

//                 {ShowSections !== [] && CompanyProfile.length !== 0 ?
//                     <Section id='Footer' backgroundColor='#001526' padding='20px 0 0 '>
//                         <Container width='80%' display='flex' justifyContent='space-between' mediaWidth='90%'>
//                             <Container display='flex' padding='40px 0' width='100%' margin='0' flexDirection='row' mediaFlexDirection='row' alignItems='start' justifyContent='space-between'>



//                                 {
//                                     CompanyProfile.map(logo => (
//                                         logo.get('projectOwner') ? <Container width='20%' height='175px' display='flex' mediaAlignItems='start' mediaPadding='10px' margin='0' flexDirection='column' justifyContent='space-between' mediaWidth='40%'>
//                                             <H3 color='#fff' textAlign='center' margin='0' >Project By</H3>
//                                             <Image src={logo.get('projectOwner') ? logo.get('projectOwner')['_url'] : undefined} width='150px' mediaWidth='100%' mediaMargin='auto 0' margin='auto 0' />
//                                         </Container> : undefined
//                                     ))
//                                 }
//                                 {
//                                     CompanyProfile.map(logo => (
//                                         logo.get('projectPartner') ? <Container width='20%' height='175px' display='flex' mediaAlignItems='start' mediaPadding='10px' margin='0' flexDirection='column' justifyContent='space-between' mediaWidth='40%'>
//                                             <H3 color='#fff' textAlign='center' margin='0' >Developement Partner</H3>
//                                             <Image src={logo.get('projectPartner') ? logo.get('projectPartner')['_url'] : undefined} width='200px' mediaWidth='100%' mediaMargin='auto 0' margin='auto 0' />
//                                         </Container> : undefined
//                                     ))
//                                 }

//                                 {/* </Container>
//                     <Container width='5%'></Container>
//                     <Container width='55%' display='flex' mediaFlexDirection='row' justifyContent='space-between'> */}
//                                 <Container width='20%' height='175px' display='flex' margin='0' flexDirection='column' justifyContent='start' mediaWidth='40%' mediaAlignItems='start' mediaPadding='10px' >
//                                     <H3 color='#fff' textAlign='center' margin='0' >Leave a massage</H3>

//                                     <Button display='flex' margin='50px 0 0' padding='10px' borderRadius='5px' onClick={() => { setShowForm(true); setShowModal(true); setImgSrc(''); }}>Massage <BiMessageAltDetail fontSize={'1.5rem'} style={{ paddingLeft: '5px' }} /> </Button>

//                                 </Container>
//                                 {/* <Container width='16%' height='175px' display='flex' margin='0' flexDirection='column' justifyContent='space-between' mediaWidth='auto'>
//                             <H3 color='#fff' textAlign='start' margin='0'  >Get The App</H3>

//                             {
//                                 StoreLogo.map(logo => (<Container height='auto'>

//                                     <a target='_blank' href={logo.link} rel="noreferrer">
//                                         <Image src={logo.logo} mediaWidth='auto' />
//                                     </a>
//                                 </Container>))
//                             }


//                         </Container> */}
//                                 <Container height='175px' display='flex' margin='0' flexDirection='column' justifyContent='start' alignItems='start' mediaWidth='40%' mediaAlignItems='start' mediaPadding='10px' width='20%' mediaMargin='0'>
//                                     <H3 color='#fff' textAlign='start' margin='0 0 10px'>Contact Us</H3>
//                                     {
//                                         CompanyProfile.map(profile =>
//                                             < >
//                                                 <P margin='0'></P>
//                                                 <P mediaFontSize='.7rem' style={{ margin: '0 0 5px', color: 'white', textAlign: 'start' }}>{profile.get('companyEmail')}</P>
//                                                 <P mediaFontSize='.7rem' style={{ margin: '0 0 5px', color: 'white', textAlign: 'start' }}>0{profile.get('companyPhoneNumber')}</P>
//                                                 <P mediaFontSize='.7rem' style={{ margin: '0 0 5px', color: 'white', textAlign: 'start' }}>{profile.get('companyAddress')}</P>
//                                             </ >
//                                         )}
//                                 </Container>
//                             </Container>
//                         </Container>
//                         <Container display='flex' justifyContent='space-between' borderTop='1px solid white' borderBottom='1px solid white' width='80%' padding='20px 0 ' mediaWidth='90%' mediaFlexDirection='column' flexDirection='column' mediaPadding='20px 0 10px'>
//                             <H3 textAlign='center' color='#fff' margin='0 0 20px' >Technology We use :</H3>
//                             <Container display='flex' justifyContent='space-between' mediaFlexDirection='row' width='100%'>
//                                 {UsedInstrument.map((item, key) =>
//                                     // <a href={item.link} target='_blank' rel='noreferrer'>
//                                     <Image src={item.image} alt={item.name} key={key} height='100px' width='100px' padding='40px' mediaWidth='60px' mediaHeight='60px' margin='auto' mediaMargin='8px auto' backgroundColor='white' objectFit='contain' mediaObjectFit='contain' borderRadius='3px' cursor='pointer' />
//                                     // </a>
//                                 )}
//                             </Container>
//                         </Container>
//                         <Container display='flex' justifyContent='space-between' width='80%' padding='20px 0 10px' mediaWidth='90%' mediaFlexDirection='row' mediaPadding='20px 0 10px'>
//                             {
//                                 CompanyProfile.map(profile => <Image src={profile.get('companyLogo') ? profile.get('companyLogo')['_url'] : undefined} alt={profile.get('companyName')} width='100px' margin='0' mediaWidth='60px' mediaMargin='0' />)

//                             }

//                             <H3 textAlign='center' color='#fff' fontSize='1rem' margin='0' mediaFontSize='0.6rem'>Copyright © {
//                                 CompanyProfile.map(profile => <>{profile.get('companyName')}</>)
//                             }</H3>
//                         </Container>

//                     </Section>
//                     : undefined}


//                 <div className={ShowModal ? 'Modal Open' : 'Modal'}>
//                     {
//                         ImgSrc ?
//                             <img src={ImgSrc} alt='' style={ImageZoom ? { width: "100% ", height: "100% ", padding: '0', transition: 'transform 0.5s ease ' } : { width: "80% ", height: "auto" }} />
//                             : ShowForm ?
//                                 <form onSubmit={MassageSubmit} style={{ boxShadow: 'none', width: '50%' }}>
//                                     <h3>Contact us</h3>
//                                     {
//                                         FormInputs.map(input =>
//                                             <div key={input.id}>
//                                                 <label>{input.name}: </label>
//                                                 < br />
//                                                 {
//                                                     input.type !== 'textarea' ?
//                                                         <input required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} value={input.value} /> :
//                                                         <textarea required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} value={input.value} />
//                                                 }

//                                             </div>
//                                         )
//                                     }

//                                     <Button type='submit' margin='20px 0 0' padding='10px' borderRadius='5px' display='flex'>Send <BiMailSend fontSize='1.5rem' style={{ paddingLeft: '5px' }} /> </Button>
//                                 </form>
//                                 : undefined
//                     }
//                     < div className='CloseBtn' >
//                         {
//                             ImgSrc ?
//                                 ImageZoom ?
//                                     <AiOutlineFullscreenExit color={'#fff'} onClick={() => setImageZoom(false)} /> :
//                                     <AiOutlineFullscreen color={'#fff'} onClick={() => setImageZoom(true)} />
//                                 : undefined
//                         }

//                         <IconContainer top='10px' onClick={() => setShowModal(false)}>
//                             <RiCloseFill color={'#fff'} onClick={() => setShowModal(false)} />
//                         </IconContainer>

//                     </div>
//                     {/* <div className='NextBtn'>
//                     <GrFormNext color={'#fff'} onClick={() => NextPrev({ type: 'next' })} />
//                 </div>
//                 <div className='PreviousBtn'>
//                     <GrFormPrevious color={'#fff'} onClick={() => NextPrev({ type: 'prev' })} />
//                 </div> */}
//                 </div>
//             </> : undefined
//             }

//             <Toaster
//                 position="top-center"
//                 reverseOrder={false}
//                 autoClose={10000}
//             />

//             <ScrollButton props={false} />
//         </ >
//     );
// };

// export default DynamicFront;















// editing start here



import React, { useEffect, useState } from 'react';
import Navbar from '../Component/Navbar/Navbar';
import './Home.css';
import ScrollButton from '../Component/ScrollBtn/ScrollButton';
import { Button, Card, CardImage, CardText, CardTextDescription, CardTextTitle, Container, H1, H2, H3, IconContainer, Image, ImageContainer, P, Section, Source, TextContainer, VideoContainer } from '../Component/StyledComponent/VMBALLStyled';
import { RiCloseFill } from 'react-icons/ri';
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai';
import { BiMessageAltDetail, BiMailSend } from 'react-icons/bi';
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa';
import ReactElasticCarousel from 'react-elastic-carousel';
import ReactVisibilitySensor from 'react-visibility-sensor';
import toast, { Toaster } from 'react-hot-toast';
import Parse from 'parse/dist/parse.min.js';
import Blender from '../Assets/Blender.png';
import Megascan from '../Assets/Megascan.png';
import Unreal from '../Assets/Unreal.svg';
import Adobe from '../Assets/Adobe.png';
import Zbrush from '../Assets/Zbrush.svg';
import Autodesk from '../Assets/Autodesk.svg';


const DynamicFront = (props) => {


    const UsedInstrument = [
        {
            name: 'Unreal',
            image: Unreal,
            link: 'https://www.unrealengine.com/en-US/',
        },
        {
            name: 'Quixel Megascan',
            image: Megascan,
            link: 'https://www.quixel.com/',
        },
        {
            name: 'Blender',
            image: Blender,
            link: 'https://www.blender.org/',
        },
        {
            name: 'Adobe',
            image: Adobe,
            link: 'https://www.adobe.com/',
        },
        {
            name: 'ZBrush',
            image: Zbrush,
            link: 'https://www.zbrush.com/',
        },
        {
            name: 'Autodesk',
            image: Autodesk,
            link: 'https://www.autodesk.com/',
        },
    ]
    // console.log('window.pageYOffset', window.pageYOffset)

    const [ShowForm, setShowForm] = useState(false);
    // const [ShowVr, setShowVr] = useState(false);
    const [ActiveItem, setActiveItem] = useState('Photos');
    const [ActiveArticle, setActiveArticle] = useState('Article');
    const [CompanyProfile, setCompanyProfile] = useState([]);
    const [ShowSections, setShowSections] = useState([]);
    const [AllSectionContent, setAllSectionContent] = useState([]);
    const [SectionContent, setSectionContent] = useState([]);
    const SectionContentSorting = SectionContent.sort((firstItem, secondItem) => firstItem.get('sectionPosition') - secondItem.get('sectionPosition'));
    const [ShowModal, setShowModal] = useState(false);
    const [ImgSrc, setImgSrc] = useState({});
    const [ImageZoom, setImageZoom] = useState(false);
    const [MassageInput, setMassageInput] = useState({
        name: '',
        email: '',
        massage: '',
        phone: ''
    });
    const [Render, setRender] = useState();

    const onVisibilityChange = (isVisible = Boolean) => {
        if (isVisible) {
            setRender(true);
        }
    };

    const PagePath = window.location.pathname.replace(/\\|\//g, '');


    useEffect(() => {
        const allFetch = async () => {

            const fetchPages = async () => {
                const pagesQuery = new Parse.Query('Pages');
                // const navarLogoQuery = new Parse.Query('NavbarLogo');
                // let foundNavarLogo = await navarLogoQuery.first();

                // const navLogoLink = (foundNavarLogo !== undefined ? foundNavarLogo.get('navLogoLink').replace(/\\|\//g, '') : undefined);


                // if (props.props) {
                //     pagesQuery.equalTo('pageRoot', props.props);
                // }
                // if (!props.props) {
                //     pagesQuery.equalTo('pageRoute', PagePath);
                //     // console.log('pageRoot', true);
                // }
                try {
                    let allPages = await pagesQuery.first();
                    if (allPages.get('visibility') === true) {
                        if (allPages) {
                            let sectionsQuery = new Parse.Query('Sections');
                            sectionsQuery.equalTo('page', allPages);
                            try {
                                let foundSections = await sectionsQuery.find();
                                setShowSections(foundSections);
                                // console.log('foundSections', foundSections);
                                return true;
                            } catch (error) {
                                // console.log('section error', error);
                            }
                        }
                    }
                } catch (error) {
                    // console.log('page error', error);
                }
            }
            await fetchPages();

            const FetchCompanyProfile = async () => {
                const CompanyProfileQuery = new Parse.Query('CompanyProfile');

                try {
                    let allCompanyProfile = await CompanyProfileQuery.find();
                    allCompanyProfile.map(item => item.get('visibility') === true ? setCompanyProfile([item]) : null)
                    // setCompanyProfile(allCompanyProfile);

                } catch (error) {
                    // console.log('page error', error);
                }
            }
            await FetchCompanyProfile();
        }
        allFetch();
        window.scrollTo(0, 0);
        // window.addEventListener('click', allFetch);

        return () => {
            window.removeEventListener('click', allFetch);
        }
    }, [PagePath]);


    useEffect(() => {
        setAllSectionContent([]);
        setSectionContent([]);
        const FetchAllContent = async () => {
            const FetchLandingPage = async () => {
                let LandingPageQuery = new Parse.Query('LandingPage');
                if (ShowSections !== undefined || null) {
                    for (let newType of ShowSections) {
                        if (newType.get('type') === 'LandingPage') {
                            LandingPageQuery.equalTo("section", newType);
                            setSectionContent(all => [...all, newType]);
                            try {
                                let FoundCard = await LandingPageQuery.find();
                                setAllSectionContent(all => [...all, FoundCard]);
                                // console.log(foundTiltPicture);
                            } catch (error) {
                                // console.log(error);

                            }
                        }
                    }
                }
            }
            await FetchLandingPage();
            const FetchVRTour = async () => {
                let ItemQuery = new Parse.Query('VRTour');
                if (ShowSections !== undefined || null) {
                    for (let newType of ShowSections) {
                        if (newType.get('type') === 'VRTour') {
                            ItemQuery.equalTo("section", newType);
                            setSectionContent(all => [...all, newType]);
                            try {
                                let FoundItem = await ItemQuery.find();
                                setAllSectionContent(all => [...all, FoundItem]);
                                // console.log(foundTiltPicture);
                            } catch (error) {
                                // console.log(error);

                            }
                        }
                    }
                }
            }
            await FetchVRTour();
            const FetchAbout = async () => {
                let ItemQuery = new Parse.Query('About');
                if (ShowSections !== undefined || null) {
                    for (let newType of ShowSections) {
                        if (newType.get('type') === 'About') {
                            ItemQuery.equalTo("section", newType);
                            setSectionContent(all => [...all, newType]);
                            try {
                                let FoundItem = await ItemQuery.find();
                                setAllSectionContent(all => [...all, FoundItem]);
                                // console.log(foundTiltPicture);
                            } catch (error) {
                                // console.log(error);

                            }
                        }
                    }
                }
            }
            await FetchAbout();
            const FetchCard = async () => {
                let CardQuery = new Parse.Query('Card');
                if (ShowSections !== undefined || null) {
                    for (let newType of ShowSections) {
                        if (newType.get('type') === 'Card') {
                            CardQuery.equalTo("section", newType);
                            setSectionContent(all => [...all, newType]);
                            try {
                                let FoundCard = await CardQuery.find();
                                setAllSectionContent(all => [...all, FoundCard]);
                                // console.log(foundTiltPicture);
                            } catch (error) {
                                // console.log(error);

                            }
                        }
                    }
                }
            }
            await FetchCard();
            const FetchPhotoGallery = async () => {
                let PhotoGalleryQuery = new Parse.Query('PhotoGallery');
                if (ShowSections !== undefined || null) {
                    for (let newType of ShowSections) {
                        if (newType.get('type') === 'PhotoGallery') {
                            PhotoGalleryQuery.equalTo("section", newType);
                            setSectionContent(all => [...all, newType]);
                            try {
                                let FoundPhotoGallery = await PhotoGalleryQuery.find();
                                setAllSectionContent(all => [...all, FoundPhotoGallery]);;
                                // console.log(foundTiltPicture);
                            } catch (error) {
                                // console.log(error);

                            }
                        }
                    }
                }
            }
            await FetchPhotoGallery();
            const FetchVideoGallery = async () => {
                let VideoGalleryQuery = new Parse.Query('VideoGallery');
                if (ShowSections !== undefined || null) {
                    for (let newType of ShowSections) {
                        if (newType.get('type') === 'VideoGallery') {
                            VideoGalleryQuery.equalTo("section", newType);
                            setSectionContent(all => [...all, newType]);
                            try {
                                let FoundVideoGallery = await VideoGalleryQuery.find();
                                setAllSectionContent(all => [...all, FoundVideoGallery]);;
                                // console.log(foundTiltPicture);
                            } catch (error) {
                                // console.log(error);

                            }
                        }
                    }
                }
            }
            await FetchVideoGallery();

            const FetchNews = async () => {
                let ArticleQuery = new Parse.Query('News');
                if (ShowSections !== undefined || null) {
                    for (let newType of ShowSections) {
                        if (newType.get('type') === 'News') {
                            ArticleQuery.equalTo("section", newType);
                            setSectionContent(all => [...all, newType]);
                            try {
                                let FoundArticle = await ArticleQuery.find();
                                setAllSectionContent(all => [...all, FoundArticle]);;
                                // console.log(foundTiltPicture);
                            } catch (error) {
                                // console.log(error);

                            }
                        }
                    }
                }
            }
            await FetchNews();





            const FetchGallery = async () => {
                let ItemQuery = new Parse.Query('Gallery');
                if (ShowSections !== undefined || null) {
                    for (let newType of ShowSections) {
                        if (newType.get('type') === 'Gallery') {
                            ItemQuery.equalTo("section", newType);
                            setSectionContent(all => [...all, newType]);
                            try {
                                let FoundItem = await ItemQuery.find();
                                setAllSectionContent(all => [...all, FoundItem]);

                            } catch (error) {
                                // console.log(error);

                            }
                        }
                    }
                }
            }
            await FetchGallery();
        }

        // window.addEventListener('click',fetchAllContent);
        FetchAllContent();
        return () => {
            window.removeEventListener('click', FetchAllContent);
        }


    }, [ShowSections]);





    const FormInputs = [
        {
            required: true,
            name: 'Name',
            type: 'text',
            placeholder: 'Enter your name',
            value: MassageInput.name,
            onChange: (e) => {
                setMassageInput({ ...MassageInput, name: e.target.value });
            },
        },
        {
            required: true,
            name: 'Email',
            type: 'email',
            placeholder: 'Enter your email',
            value: MassageInput.email,
            onChange: (e) => {
                setMassageInput({ ...MassageInput, email: e.target.value });
            }

        },
        {
            required: true,
            name: 'Phone Number',
            type: 'number',
            placeholder: 'Enter your phone number',
            value: MassageInput.phone,
            onChange: (e) => {
                setMassageInput({ ...MassageInput, phone: e.target.value })
            },
        },
        {
            required: true,
            name: 'Massage',
            type: 'textarea',
            placeholder: 'Enter your massage',
            value: MassageInput.massage,
            onChange: (e) => {
                setMassageInput({ ...MassageInput, massage: e.target.value })
            },
        },
    ]


    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 3 },
        { width: 1500, itemsToShow: 4 },

    ];

    const MassageSubmit = async (e) => {
        e.preventDefault();
        const { name, email, massage, phone } = MassageInput;

        try {
            const Massage = new Parse.Object('Massage');

            Massage.set('name', name);
            Massage.set('email', email);
            Massage.set('phone', phone);
            Massage.set('massage', massage);

            await Massage.save();
            setMassageInput({
                name: '',
                email: '',
                phone: '',
                massage: ''
            })
            toast.success('Massage Send Successfully');
            setShowForm(false);
            setShowModal(false);
        } catch (error) {
            // console.log(error);
            toast.error('Something went wrong!');
        }
    }



    return (
        <>
            <Navbar />

            {/* <div className={SectionContent.length === 0 && AllSectionContent.length === 0 ? 'dataPreLoaderActive' : 'dataPreLoader'}>
                <img src={Preloader} alt='loader' />
            </div> */}

            {SectionContent.length !== 0 && AllSectionContent.length !== 0 ? <>
                {
                    CompanyProfile.map((profile, key) =>
                        <div key={key} style={profile.get('companyFacebook') || profile.get('companyTwitter') || profile.get('companyYoutube') || profile.get('companyInstagram') || profile.get('companyLinkedIn') ? { position: 'fixed', display: 'flex', flexDirection: 'column', bottom: '85px', left: '-5px', background: 'white', padding: '5px', borderRadius: '5px' } : { display: 'none' }}>
                            {profile.get('companyFacebook') && <a href={profile.get('companyFacebook')} target='_blank' rel="noreferrer" style={{ margin: '3px 5px 3px  ' }}><FaFacebook size={'1.5rem'} color='#1876f2' /></a >}
                            {profile.get('companyTwitter') && <a href={profile.get('companyTwitter')} target='_blank' rel="noreferrer" style={{ margin: '3px 5px 3px  ' }}><FaTwitter size={'1.5rem'} color='#1d9bf0' /></a >}
                            {profile.get('companyYoutube') && <a href={profile.get('companyYoutube')} target='_blank' rel="noreferrer" style={{ margin: '3px 5px 3px  ' }}><FaYoutube size={'1.5rem'} color='#ff0000' /></a >}
                            {profile.get('companyInstagram') && <a href={profile.get('companyInstagram')} target='_blank' rel="noreferrer" style={{ margin: '3px 5px 3px  ' }}><FaInstagram size={'1.5rem'} color='#8a3ab9' /></a >}
                            {profile.get('companyLinkedIn') && <a href={profile.get('companyLinkedIn')} target='_blank' rel="noreferrer" style={{ margin: '3px 5px 3px  ' }}><FaLinkedin size={'1.5rem'} color='#0e76a8' /></a >}
                        </div>
                    )
                }


                {
                    ShowSections !== [] && SectionContent !== [] && AllSectionContent !== [] ?

                        SectionContentSorting.map((section, idx) => (
                            section.get('type') === 'LandingPage' && section.get('visibility') === true ?
                                <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} height='100vh'>

                                    {/* <img src="http://www.virtualmuseumbd.com/wp-content/uploads/2020/09/VMB.0093_2a.jpg" alt="VMB_LOGO" className='VMB_Image' />  onClick={() => window.location.replace('/#Footer')}  */}
                                    {AllSectionContent ? AllSectionContent.map(data => data.map((LandingPage, key) =>
                                        LandingPage.className === 'LandingPage' && LandingPage.get('visibility') === true && LandingPage.get('section').id === section.id ?
                                            <TextContainer key={key} height='100%' backgroundColor='#00000073' textAlign='center' mediaWidth='100%' display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
                                                <H2 data-aos='fade-bottom' color='#fff' textAlign='center' margin='20px 0'>{LandingPage.get('subHeading')}</H2>
                                                <H1 data-aos='fade-bottom' margin='20px 0 30px'>
                                                    {LandingPage.get('heading')}
                                                </H1>
                                                <a data-aos='fade-bottom' href={LandingPage.get('link')} target='_blank' rel='noreferrer'> <Button>{LandingPage.get('buttonName')}</Button></a>
                                            </TextContainer>
                                            : null)) : null
                                    }
                                </Section>
                                : section.get('type') === 'VRTour' && section.get('visibility') === true ?
                                    <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} >
                                        <Container>
                                            {/* <Container backgroundColor='#232323' minHeight="200px" display='flex' flexDirection='column'> */}
                                            <H3 display='flex' justifyContent="center" alignItems="center" color='#bfbfbf' backgroundColor='#232323' minHeight="250px">
                                                {section.get('sectionTitle')}
                                            </H3>
                                            {/* <Button onClick={() => setShowVr(!ShowVr)}>{ShowVr ? 'Hide' : 'Visit'}</Button> */}
                                            {/* </Container>  && ShowVr */}

                                            {AllSectionContent ? AllSectionContent.map(data => data.map((VRTour, key) =>
                                                VRTour.className === 'VRTour' && VRTour.get('visibility') === true && VRTour.get('section').id === section.id ?
                                                    <iframe key={key} src={VRTour.get('link')} title='Vmb' height='700px' width='100%' allowFullScreen={true} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                                    : null)) : null
                                            }
                                        </Container>

                                    </Section>
                                    : section.get('type') === 'About' && section.get('visibility') === true ?
                                        <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#f5f5f5' padding='20px 0'>
                                            <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
                                                <H2
                                                    data-aos='fade-right'
                                                    color='#2b2b2b'
                                                    width="20%"
                                                    borderBottom="3px solid #4c4c4c"
                                                    padding="35px 0"
                                                    margin='0 20px 20px'
                                                >{section.get('sectionTitle')}</H2>

                                                {AllSectionContent ? AllSectionContent.map(data => data.map((Item, key) =>
                                                    Item.className === 'About' && Item.get('visibility') === true && Item.get('section').id === section.id ?
                                                        <Container key={key} display='flex' justifyContent='space-between' mediaWidth='100%'>

                                                            <TextContainer width='50%' color='#2b2b2b' padding='10px' data-aos={"fade-right"}>
                                                                <P>{Item.get('description')}</P>
                                                                <P>{Item.get('description1')}</P>
                                                                <P>{Item.get('description2')}</P>
                                                            </TextContainer>

                                                            <ImageContainer width='45%' data-aos={"fade-left"}>
                                                                <Image src={Item.get('image') ? Item.get('image')['_url'] : undefined} alt="VMB_LOGO" />
                                                            </ImageContainer>

                                                        </Container> : null)) : null
                                                }
                                            </Container>
                                        </Section>
                                        : section.get('type') === 'Card' && section.get('visibility') === true ?
                                            <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#ededed' padding='30px 0'>
                                                <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
                                                    <H2 width="20%"
                                                        borderBottom="1px solid #4c4c4c"
                                                        borderWidth="thin"
                                                        padding="35px 0"
                                                        textAlign='center'
                                                        data-aos='fade-up'
                                                    >{section.get('sectionName')}</H2>
                                                    <Container display='flex'>

                                                        {AllSectionContent ? AllSectionContent.map(data => data.map((card, key) =>
                                                            card.className === 'Card' && card.get('visibility') === true && card.get('section').id === section.id ?

                                                                <Card key={card.id} data-aos={key === 0 ? "fade-right" : key === 1 ? "fade-up" : "fade-left"}>
                                                                    <CardImage>
                                                                        <Image src={card.get('image') ? card.get('image')['_url'] : null} width='70%' transform='scale(1.1)' filter='opacity(0.5)' mediaFilter='opacity(1)' />
                                                                    </CardImage>
                                                                    <CardText mediaHeight='auto'>
                                                                        <h3>{card.get('heading')}</h3>
                                                                        <P textAlign='center'>{card.get('description')}</P>
                                                                    </CardText>
                                                                </Card>
                                                                : null)) : null
                                                        }


                                                        {/* {
                                                            FakeFeatures.map(feature => (
                                                                <Card key={feature.id}>
                                                                    <CardImage>
                                                                        <Image src={AboutUsImg} transform='scale(1.1)' />
                                                                    </CardImage>
                                                                    <CardText>
                                                                        <h3>{feature.title}</h3>
                                                                        <p>{feature.description}</p>
                                                                    </CardText>
                                                                </Card>
                                                            ))

                                                        } */}
                                                    </Container>
                                                </Container>
                                            </Section>
                                            : section.get('type') === 'PhotoGallery' && section.get('visibility') === true ?
                                                <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#011627' padding='30px 0'>
                                                    <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
                                                        <H2 width="20%"
                                                            borderBottom="1px solid #4c4c4c"
                                                            borderWidth="thin"
                                                            padding="35px 0"
                                                            textAlign='center'
                                                            color='#fff'>{section.get('sectionName')}</H2>
                                                        <Container display='flex' padding='50px 0 30px' textAlign='start' mediaFlexDirection='row' mediaTextAlign='center'>
                                                            {AllSectionContent ? AllSectionContent.map(data => data.map((PhotoGallery, key) =>
                                                                PhotoGallery.className === 'PhotoGallery' && PhotoGallery.get('visibility') === true && PhotoGallery.get('section').id === section.id ?
                                                                    <Image key={key} src={PhotoGallery.get('image') ? PhotoGallery.get('image')['_url'] : null} width={'380px'} height={'250px'} padding='20px 20px 0 0' margin='0' cursor='pointer'
                                                                        onClick={() => { setShowModal(!ShowModal); setImgSrc(PhotoGallery.get('image')['_url']); }} transform='scale(1.04)'
                                                                        mediaWidth='150px' mediaHeight='100px' />
                                                                    : null)) : null
                                                            }

                                                        </Container>
                                                    </Container>


                                                </Section>
                                                : section.get('type') === 'VideoGallery' && section.get('visibility') === true ?
                                                    <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#fff' padding='30px 0'>
                                                        <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
                                                            <H2 width="20%"
                                                                borderBottom="1px solid #4c4c4c"
                                                                borderWidth="thin"
                                                                padding="35px 0"
                                                                textAlign='center'
                                                                color='#000'>{section.get('sectionName')}</H2>
                                                            <Container display='flex' padding='50px 0 30px' textAlign='start'>
                                                                {AllSectionContent ? AllSectionContent.map(data => data.map((VideoGallery, key) =>
                                                                    VideoGallery.className === 'VideoGallery' && VideoGallery.get('visibility') === true && VideoGallery.get('section').id === section.id ?
                                                                        (VideoGallery.get('type') !== 'UploadVideo' ?
                                                                            // Youtube emdede video link get
                                                                            // <iframe key={key} width="560" height="315" title="YouTube video player" src={VideoGallery.get('embadedLink') ? VideoGallery.get('embadedLink') : null} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                                                                            <iframe key={key} width="560" height="315" title="YouTube video player" src={VideoGallery.get('embadedLink') ? VideoGallery.get('embadedLink') : undefined} ></iframe>
                                                                            : VideoGallery.get('type'))
                                                                        : null)) : null
                                                                }
                                                                {/* Youtube iframe
                                                                 */}
                                                            </Container>
                                                        </Container>


                                                    </Section>
                                                    : section.get('type') === 'Article' && section.get('visibility') === true ?
                                                        <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#fff' padding='30px 0'>
                                                            <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
                                                                <H2 width="20%"
                                                                    borderBottom="1px solid #4c4c4c"
                                                                    borderWidth="thin"
                                                                    padding="35px 0"
                                                                    textAlign='center'
                                                                    color='#000'
                                                                    data-aos='fade-right'
                                                                >Articales</H2>
                                                                <Container display='flex' padding='50px 0 30px' textAlign='start'>


                                                                    <ReactVisibilitySensor
                                                                        scrollCheck={true}
                                                                        onChange={onVisibilityChange}
                                                                        delayedCall >
                                                                        {/* <ReactElasticCarousel breakPoints={breakPoints} enableautoPlay={Render && 'true'} autoPlaySpeed={Render && 3000}> */}
                                                                        {/* <Slider {...settings}> */}


                                                                        {
                                                                            AllSectionContent ? AllSectionContent.map(data => data.map((Item, key) =>
                                                                                Item.className === 'Article' && Item.get('visibility') === true && Item.get('section').id === section.id
                                                                                    ?
                                                                                    <Card key={key} width='auto' margin='0' >
                                                                                        <CardImage width='100%' height='250px'>
                                                                                            {/* <a href='/hello'> */}
                                                                                            <Image src={Item.get('image') ? Item.get('image')['_url'] : undefined} transform='scale(1.01)' cursor='pointer' height='100%' mediaHeight='100%' borderRadius='5px' />
                                                                                            {/* </a> */}
                                                                                        </CardImage>
                                                                                        <CardText height='250px' mediaTextAlign='start'>
                                                                                            <CardTextTitle as='a' href={Item.get('link')} target='_blank'>{Item.get('heading')}</CardTextTitle>
                                                                                            <CardTextDescription maxHeight='270px'>{Item.get('description')}</CardTextDescription>
                                                                                            <Button padding='10px' borderRadius='5px' as='a' href={Item.get('link')} target='_blank'>Read more</Button>
                                                                                        </CardText>
                                                                                    </Card>
                                                                                    : null)) : null
                                                                        }
                                                                        {/* </Slider> */}
                                                                        {/* </ReactElasticCarousel> */}
                                                                    </ReactVisibilitySensor>


                                                                </Container>
                                                            </Container>


                                                        </Section>
                                                        : section.get('type') === 'News' && section.get('visibility') === true ?
                                                            <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#fff' padding='30px 0'>
                                                                <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
                                                                    <H2 width="20%"
                                                                        borderBottom="1px solid #4c4c4c"
                                                                        borderWidth="thin"
                                                                        padding="35px 0"
                                                                        textAlign='center'
                                                                        color='#000'
                                                                        data-aos='fade-right'
                                                                    >{section.get('sectionTitle')}</H2>
                                                                    <Container display='flex' flexDirection='row' mediaFlexDirection='row'>
                                                                        {
                                                                            ['Article', 'News', 'Testimonials'].map((item, key) => <H2
                                                                                mediaWidth='auto'
                                                                                mediaMargin='0'
                                                                                key={key}
                                                                                borderBottom={ActiveArticle === item ? "1px solid #000" : "1px solid transparent"}
                                                                                hoverColor='#000'
                                                                                hoverBorderBottom='1px solid transparent'
                                                                                margin='0'
                                                                                padding="10px"
                                                                                textAlign='center'
                                                                                color={ActiveArticle === item ? '#000' : '#bfbfbf'}
                                                                                cursor='pointer'
                                                                                onClick={() => { setActiveArticle(item) }}
                                                                            // data-aos={key === 0 ? 'fade-right' : 'fade-left'}
                                                                            >
                                                                                {/* Testimonials */}
                                                                                {item}
                                                                            </H2>)
                                                                        }

                                                                    </Container>
                                                                    <Container display='flex' padding='50px 0 30px' mediaPadding='50px 0 30px' textAlign='start' mediaFlexDirection='row' mediaTextAlign='center'>


                                                                        {
                                                                            ActiveArticle === 'Article' &&
                                                                            <ReactVisibilitySensor
                                                                                scrollCheck={true}
                                                                                onChange={onVisibilityChange}
                                                                                delayedCall
                                                                                data-aos='fade-up'>
                                                                                {/* <Slider {...settings}> */}
                                                                                <ReactElasticCarousel breakPoints={breakPoints} enableautoPlay={Render && 'true'} autoPlaySpeed={Render && 3000}>
                                                                                    {/* <Fade bottom> */}
                                                                                    {
                                                                                        AllSectionContent ? AllSectionContent.map(data => data.map((Item, key) =>
                                                                                            Item.className === 'News' && Item.get('visibility') === true && Item.get('section').id === section.id && Item.get('type') === 'Article'
                                                                                                ?
                                                                                                <Card key={key} width='auto' margin='0' mediaWidth='90%' data-aos='fade-up' >
                                                                                                    <CardImage width='100%' height='250px'>
                                                                                                        {/* <a href='/hello'> */}
                                                                                                        <Image src={Item.get('image') ? Item.get('image')['_url'] : undefined} transform='scale(1.01)' height='100%' mediaHeight='100%' borderRadius='5px' />
                                                                                                        {/* </a> */}
                                                                                                    </CardImage>
                                                                                                    <CardText height='250px' mediaTextAlign='start'>
                                                                                                        <CardTextTitle as='a' href={Item.get('link')} target='_blank'>{Item.get('heading')}</CardTextTitle>
                                                                                                        <CardTextDescription maxHeight='270px'>{Item.get('description')}</CardTextDescription>
                                                                                                        <Button padding='10px' borderRadius='5px' as='a' href={Item.get('link')} target='_blank'>Read more</Button>
                                                                                                    </CardText>
                                                                                                </Card>
                                                                                                : null)) : null
                                                                                    }
                                                                                    {/* </Fade> */}
                                                                                </ReactElasticCarousel>
                                                                                {/* </Slider> */}
                                                                            </ReactVisibilitySensor>
                                                                        }
                                                                        {/* <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fvrmuseumbd%2Fvideos%2F961281244545563%2F&show_text=false&width=560&t=0" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe> */}

                                                                        {/* <iframe src={'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fgangunknown%2Fvideos%2F2012719398918326%2F&show_text=false&width=560&t=0'} title={"Item.get('videoTitle')"} height='220px' width='100%' style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }} allowFullScreen={true} ></iframe> */}
                                                                        {/* <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fvrmuseumbd%2Fvideos%2F485469280045883%2F&show_text=false&width=560&t=0" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe> */}
                                                                        {/* <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fvrmuseumbd%2Fvideos%2F369965575206458%2F&show_text=false&width=560&t=0" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe> */}
                                                                        {
                                                                            ActiveArticle === 'Testimonials' && AllSectionContent ? AllSectionContent.map(data => data.map((Item, key) =>
                                                                                Item.className === 'News' && Item.get('visibility') === true && Item.get('section').id === section.id && Item.get('type') === 'Testimonial'
                                                                                    ?
                                                                                    <VideoContainer data-aos='fade-up' key={key} width={'390px'} height={'220px'} padding=' 0' margin='3px'
                                                                                        borderRadius={'10px'} cursor='pointer'
                                                                                        mediaWidth='80%' mediaHeight='220px'>
                                                                                        {Item.get('videoType') === 'YoutubeVideo' ?

                                                                                            <iframe src={Item.get('embadedLink')} title={Item.get('videoTitle')} height='220px' width='100%' style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }} allowFullScreen={true} ></iframe> :
                                                                                            Item.get('videoType') === 'VimeoVideo' ?
                                                                                                // VideoGallery.get('embadedLink') :  
                                                                                                <iframe src={Item.get('embadedLink')} title={Item.get('videoTitle')} height='220px' width='100%' style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }} allowFullScreen={true} ></iframe>
                                                                                                // <iframe src={Item.get('embadedLink')} height='220px' width='100%' frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} title={Item.get('videoTitle')} style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }}></iframe>
                                                                                                :
                                                                                                Item.get('videoType') === 'FacebookVideo' ?
                                                                                                    // VideoGallery.get('embadedLink') :  
                                                                                                    <iframe src={Item.get('embadedLink')} title={Item.get('videoTitle')} height='220px' width='100%' style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }} allowFullScreen={true} ></iframe> :
                                                                                                    Item.get('videoType') === 'UploadVideo' ?
                                                                                                        <video width={'100%'} height={'220px'} style={{ objectFit: 'cover', overflow: 'hidden', borderRadius: '10px', border: '0.1px solid #ababb678' }} controls>
                                                                                                            <Source src={Item.get('video') ? Item.get('video')['_url'] : undefined} type='video/mp4' />
                                                                                                        </video> : undefined}

                                                                                    </VideoContainer>
                                                                                    : null))
                                                                                : null
                                                                        }


                                                                        {
                                                                            ActiveArticle === 'News' && AllSectionContent ? AllSectionContent.map(data => data.map((Item, key) =>
                                                                                Item.className === 'News' && Item.get('visibility') === true && Item.get('section').id === section.id && Item.get('type') === 'Videos'
                                                                                    ?
                                                                                    <VideoContainer data-aos='fade-up' key={key} width={'390px'} height={'220px'} padding=' 0' margin='3px'
                                                                                        borderRadius={'10px'} cursor='pointer'
                                                                                        mediaWidth='80%' mediaHeight='220px'>
                                                                                        {Item.get('videoType') === 'YoutubeVideo' ?

                                                                                            <iframe src={Item.get('embadedLink')} title={Item.get('videoTitle')} height='220px' width='100%' style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }} allowFullScreen={true} ></iframe> :
                                                                                            Item.get('videoType') === 'VimeoVideo' ?
                                                                                                // VideoGallery.get('embadedLink') :  
                                                                                                <iframe src={Item.get('embadedLink')} title={Item.get('videoTitle')} height='220px' width='100%' style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }} allowFullScreen={true} ></iframe>
                                                                                                // <iframe src={Item.get('embadedLink')} height='220px' width='100%' frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} title={Item.get('videoTitle')} style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }}></iframe>
                                                                                                :
                                                                                                Item.get('videoType') === 'UploadVideo' ?
                                                                                                    <video width={'100%'} height={'220px'} style={{ objectFit: 'cover', overflow: 'hidden', borderRadius: '10px', border: '0.1px solid #ababb678' }} controls>
                                                                                                        <Source src={Item.get('video') ? Item.get('video')['_url'] : undefined} type='video/mp4' />
                                                                                                    </video> : undefined}

                                                                                    </VideoContainer>
                                                                                    : null))
                                                                                : null
                                                                        }


                                                                    </Container>
                                                                </Container>


                                                            </Section>
                                                            : section.get('type') === 'Gallery' && section.get('visibility') === true ?
                                                                <Section key={idx} id={section.get('sectionRoute')} backgroundImage={section.get('image') && section.get('image')['_url']} backgroundColor='#2f2f2fdb' padding='0' opacity='0.8'>
                                                                    <Container width='80%' padding='80px 0 0' mediaPadding='50px 0 0'>
                                                                        <H2 width="20%"
                                                                            borderBottom="1px solid #4c4c4c"
                                                                            borderWidth="thin"
                                                                            padding="35px 0"
                                                                            textAlign='center'
                                                                            color='#fff'
                                                                            data-aos='fade-down'
                                                                        >{section.get('sectionTitle')}</H2>
                                                                        <Container>
                                                                            <Container display='flex' flexDirection='row' mediaFlexDirection='row'>
                                                                                {
                                                                                    ['Photos', 'Game play'].map((item, key) => <H2
                                                                                        mediaWidth='auto'
                                                                                        mediaMargin='0'
                                                                                        key={key}
                                                                                        borderBottom={ActiveItem === item ? "1px solid #fff" : "1px solid transparent"}
                                                                                        hoverColor='#fff'
                                                                                        hoverBorderBottom='1px solid transparent'
                                                                                        margin='0'
                                                                                        padding="10px"
                                                                                        textAlign='center'
                                                                                        color={ActiveItem === item ? '#fff' : '#bfbfbf'}
                                                                                        cursor='pointer'
                                                                                        onClick={() => { setActiveItem(item) }}
                                                                                        data-aos={key === 0 ? 'fade-right' : 'fade-left'}
                                                                                    >{item}</H2>)
                                                                                }
                                                                            </Container>
                                                                            <Container display='flex' padding='50px 0 30px' mediaPadding='50px 0 30px' textAlign='start' mediaFlexDirection='row' mediaTextAlign='center'>

                                                                                {
                                                                                    AllSectionContent ? AllSectionContent.map(data => data.map((PhotoGallery, key) =>
                                                                                        PhotoGallery.className === 'Gallery' && PhotoGallery.get('type') === 'Photos' && PhotoGallery.get('visibility') === true && PhotoGallery.get('section').id === section.id ?
                                                                                            // <GallerImageContainer>  
                                                                                            ActiveItem === 'Photos' && <Image data-aos='fade-up' key={key} src={PhotoGallery.get('image') ? PhotoGallery.get('image')['_url'] : undefined} width={'290px'} height={'200px'} padding=' 0' margin='3px' border='0.1px solid #ababb678' borderRadius={'10px'} cursor='pointer'
                                                                                                onClick={() => { setShowModal(!ShowModal); setImgSrc(PhotoGallery.get('image') ? PhotoGallery.get('image')['_url'] : undefined); }}
                                                                                                hoverFilter='opacity(0.8)'
                                                                                                mediaWidth='150px' mediaHeight='100px' mediaMargin='3px' />

                                                                                            : null)) : null
                                                                                }

                                                                                {
                                                                                    ActiveItem === 'Game play' && AllSectionContent ? AllSectionContent.map(data => data.map((VideoGallery, key) =>
                                                                                        VideoGallery.className === 'Gallery' && VideoGallery.get('type') === 'Videos' && VideoGallery.get('visibility') === true && VideoGallery.get('section').id === section.id ?

                                                                                            <VideoContainer data-aos='fade-up' key={key} width={'390px'} height={'220px'} padding=' 0' margin='3px'
                                                                                                // border='0.1px solid #ababb678' 
                                                                                                borderRadius={'10px'} cursor='pointer'
                                                                                                // onClick={() => { setShowModal(!ShowModal); setImgSrc(data); setCurrentIndex(key) }}
                                                                                                // filter='opacity(0.8)'
                                                                                                mediaWidth='80%' mediaHeight='220px'>
                                                                                                {VideoGallery.get('videoType') === 'YoutubeVideo' ?
                                                                                                    // VideoGallery.get('embadedLink') :  
                                                                                                    <iframe src={VideoGallery.get('embadedLink')} title={VideoGallery.get('videoTitle')} allowFullScreen={true} height='220px' width='100%' style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }}></iframe>

                                                                                                    // <iframe src={VideoGallery.get('embadedLink')} title={VideoGallery.get('videoTitle')} height='220px' width='100%' allowFullScreen={true} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }}></iframe> 
                                                                                                    :
                                                                                                    VideoGallery.get('videoType') === 'VimeoVideo' ?
                                                                                                        // VideoGallery.get('embadedLink') :  
                                                                                                        <iframe src={VideoGallery.get('embadedLink')} title={VideoGallery.get('videoTitle')} allowFullScreen={true} height='220px' width='100%' style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }}></iframe>
                                                                                                        // <iframe src={VideoGallery.get('embadedLink')} height='220px' width='100%' frameBorder="0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} title={VideoGallery.get('videoTitle')} style={{ borderRadius: '10px', border: '0.1px solid #ababb678' }}></iframe> .
                                                                                                        :
                                                                                                        VideoGallery.get('videoType') === 'UploadVideo' ?
                                                                                                            <video width={'100%'} height={'220px'} style={{ objectFit: 'cover', overflow: 'hidden', borderRadius: '10px', border: '0.1px solid #ababb678' }} controls>
                                                                                                                <Source src={VideoGallery.get('video') ? VideoGallery.get('video')['_url'] : undefined} type='video/mp4' />
                                                                                                            </video> : undefined}

                                                                                            </VideoContainer> : null)) : null
                                                                                }
                                                                            </Container>
                                                                        </Container>
                                                                    </Container>


                                                                </Section> : null))

                        : 'Wait please'
                }

                {ShowSections !== [] && CompanyProfile.length !== 0 ?
                    <Section id='Footer' backgroundColor='#001526' padding='20px 0 0 '>
                        <Container width='80%' display='flex' justifyContent='space-between' mediaWidth='90%'>
                            <Container display='flex' padding='40px 0' width='100%' margin='0' flexDirection='row' mediaFlexDirection='row' alignItems='start' justifyContent='space-between'>



                                {
                                    CompanyProfile.map(logo => (
                                        logo.get('projectOwner') ? <Container width='20%' height='175px' display='flex' mediaAlignItems='start' mediaPadding='10px' margin='0' flexDirection='column' justifyContent='space-between' mediaWidth='40%'>
                                            <H3 color='#fff' textAlign='center' margin='0' >Project By</H3>
                                            <Image src={logo.get('projectOwner') ? logo.get('projectOwner')['_url'] : undefined} width='150px' mediaWidth='100%' mediaMargin='auto 0' margin='auto 0' />
                                        </Container> : undefined
                                    ))
                                }
                                {
                                    CompanyProfile.map(logo => (
                                        logo.get('projectPartner') ? <Container width='20%' height='175px' display='flex' mediaAlignItems='start' mediaPadding='10px' margin='0' flexDirection='column' justifyContent='space-between' mediaWidth='40%'>
                                            <H3 color='#fff' textAlign='center' margin='0' >Developement Partner</H3>
                                            <Image src={logo.get('projectPartner') ? logo.get('projectPartner')['_url'] : undefined} width='200px' mediaWidth='100%' mediaMargin='auto 0' margin='auto 0' />
                                        </Container> : undefined
                                    ))
                                }

                                {/* </Container>
                    <Container width='5%'></Container>
                    <Container width='55%' display='flex' mediaFlexDirection='row' justifyContent='space-between'> */}
                                <Container width='20%' height='175px' display='flex' margin='0' flexDirection='column' justifyContent='start' mediaWidth='40%' mediaAlignItems='start' mediaPadding='10px' >
                                    <H3 color='#fff' textAlign='center' margin='0' >Leave a massage</H3>

                                    <Button display='flex' margin='50px 0 0' padding='10px' borderRadius='5px' onClick={() => { setShowForm(true); setShowModal(true); setImgSrc(''); }}>Massage <BiMessageAltDetail fontSize={'1.5rem'} style={{ paddingLeft: '5px' }} /> </Button>

                                </Container>
                                {/* <Container width='16%' height='175px' display='flex' margin='0' flexDirection='column' justifyContent='space-between' mediaWidth='auto'>
                            <H3 color='#fff' textAlign='start' margin='0'  >Get The App</H3>

                            {
                                StoreLogo.map(logo => (<Container height='auto'>

                                    <a target='_blank' href={logo.link} rel="noreferrer">
                                        <Image src={logo.logo} mediaWidth='auto' />
                                    </a>
                                </Container>))
                            }


                        </Container> */}
                                <Container height='175px' display='flex' margin='0' flexDirection='column' justifyContent='start' alignItems='start' mediaWidth='40%' mediaAlignItems='start' mediaPadding='10px' width='20%' mediaMargin='0'>
                                    <H3 color='#fff' textAlign='start' margin='0 0 10px'>Contact Us</H3>
                                    {
                                        CompanyProfile.map(profile =>
                                            < >
                                                <P margin='0'></P>
                                                <P mediaFontSize='.7rem' style={{ margin: '0 0 5px', color: 'white', textAlign: 'start' }}>{profile.get('companyEmail')}</P>
                                                <P mediaFontSize='.7rem' style={{ margin: '0 0 5px', color: 'white', textAlign: 'start' }}>0{profile.get('companyPhoneNumber')}</P>
                                                <P mediaFontSize='.7rem' style={{ margin: '0 0 5px', color: 'white', textAlign: 'start' }}>{profile.get('companyAddress')}</P>
                                            </ >
                                        )}
                                </Container>
                            </Container>
                        </Container>
                        <Container display='flex' justifyContent='space-between' borderTop='1px solid white' borderBottom='1px solid white' width='80%' padding='20px 0 ' mediaWidth='90%' mediaFlexDirection='column' flexDirection='column' mediaPadding='20px 0 10px'>
                            <H3 textAlign='center' color='#fff' margin='0 0 20px' >Technology We use :</H3>
                            <Container display='flex' justifyContent='space-between' mediaFlexDirection='row' width='100%'>
                                {UsedInstrument.map((item, key) =>
                                    // <a href={item.link} target='_blank' rel='noreferrer'>
                                    <Image src={item.image} alt={item.name} key={key} height='100px' width='100px' padding='40px' mediaWidth='60px' mediaHeight='60px' margin='auto' mediaMargin='8px auto' backgroundColor='white' objectFit='contain' mediaObjectFit='contain' borderRadius='3px' cursor='pointer' />
                                    // </a>
                                )}
                            </Container>
                        </Container>
                        <Container display='flex' justifyContent='space-between' width='80%' padding='20px 0 10px' mediaWidth='90%' mediaFlexDirection='row' mediaPadding='20px 0 10px'>
                            {
                                CompanyProfile.map(profile => <Image src={profile.get('companyLogo') ? profile.get('companyLogo')['_url'] : undefined} alt={profile.get('companyName')} width='100px' margin='0' mediaWidth='60px' mediaMargin='0' />)

                            }

                            <H3 textAlign='center' color='#fff' fontSize='1rem' margin='0' mediaFontSize='0.6rem'>Copyright © {
                                CompanyProfile.map(profile => <>{profile.get('companyName')}</>)
                            }</H3>
                        </Container>

                    </Section>
                    : undefined}


                <div className={ShowModal ? 'Modal Open' : 'Modal'}>
                    {
                        ImgSrc ?
                            <img src={ImgSrc} alt='' style={ImageZoom ? { width: "100% ", height: "100% ", padding: '0', transition: 'transform 0.5s ease ' } : { width: "80% ", height: "auto" }} />
                            : ShowForm ?
                                <form onSubmit={MassageSubmit} style={{ boxShadow: 'none', width: '50%' }}>
                                    <h3>Contact us</h3>
                                    {
                                        FormInputs.map(input =>
                                            <div key={input.id}>
                                                <label>{input.name}: </label>
                                                < br />
                                                {
                                                    input.type !== 'textarea' ?
                                                        <input required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} value={input.value} /> :
                                                        <textarea required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} value={input.value} />
                                                }

                                            </div>
                                        )
                                    }

                                    <Button type='submit' margin='20px 0 0' padding='10px' borderRadius='5px' display='flex'>Send <BiMailSend fontSize='1.5rem' style={{ paddingLeft: '5px' }} /> </Button>
                                </form>
                                : undefined
                    }
                    < div className='CloseBtn' >
                        {
                            ImgSrc ?
                                ImageZoom ?
                                    <AiOutlineFullscreenExit color={'#fff'} onClick={() => setImageZoom(false)} /> :
                                    <AiOutlineFullscreen color={'#fff'} onClick={() => setImageZoom(true)} />
                                : undefined
                        }

                        <IconContainer top='10px' onClick={() => setShowModal(false)}>
                            <RiCloseFill color={'#fff'} onClick={() => setShowModal(false)} />
                        </IconContainer>

                    </div>
                    {/* <div className='NextBtn'>
                    <GrFormNext color={'#fff'} onClick={() => NextPrev({ type: 'next' })} />
                </div>
                <div className='PreviousBtn'>
                    <GrFormPrevious color={'#fff'} onClick={() => NextPrev({ type: 'prev' })} />
                </div> */}
                </div>
            </> : undefined
            }

            <Toaster
                position="top-center"
                reverseOrder={false}
                autoClose={10000}
            />

            <ScrollButton props={false} />
        </ >
    );
};

export default DynamicFront;















// editing end  here















