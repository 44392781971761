import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Parse from 'parse/dist/parse.min.js';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { DangerButton, DashContentDiv, DashContentTableDiv, H1, H2, H3, InputGroup, InputGroupDiv, LclTable, LclTData, LclTRow, P, PrimaryButton } from '../Component/StyledComponent/VMBALLStyled';
import { BiHide, BiShowAlt } from 'react-icons/bi';
import { AiFillDelete, AiFillWarning, AiOutlineReload } from 'react-icons/ai';
const DashCompanyAndUser = (data) => {


    const PropsData = data.data;



    const [Changedlogo, setChangedLogo] = useState(false);
    const [ChangedOwner, setChangedOwner] = useState(false);
    const [ChangedPartner, setChangedPartner] = useState(false);
    const [ShowForm, setShowForm] = useState(false);
    const [CurrentUser, setCurrentUser] = useState({});
    const [ShowFetchedData, setShowFetchedData] = useState([]);
    const [PasswordCheck, setPasswordCheck] = useState(false);
    const [UserUpdate, setUserUpdate] = useState(false);
    const [UserUpdateData, setUserUpdateData] = useState('');
    const [UpdatedData, setUpdatedData] = useState('');
    const [FormLoading, setFormLoading] = useState(false);
    const [IsUpdate, setIsUpdate] = useState(false);
    // const history = useHistory();
    // let { from } = location.state || { from: { pathname: '/dashboard' } }
    // const location = useLocation();
    const [DeleteConfirmaiton, setDeleteConfirmaiton] = useState(false);
    const [ShowDeleteModal, setShowDeleteModal] = useState(false);
    const [DeleteContent, setDeleteContent] = useState(null);
    const [AllInputs, setAllInputs] = useState({
        Role: '',
        UserFirstName: '',
        UserLastName: '',
        UserEmail: '',
        UserName: '',
        UserPhoneNumber: '',
        UserImage: '',
        UserPassword: '',
        UserConfirmPassword: '',


        CompanyName: '',
        CompanyAddress: '',
        CompanyEmail: '',
        CompanyPhoneNumber: '',
        CompanyDescription: '',
        CompanyLogo: undefined,
        ProjectOwner: undefined,
        ProjectPartner: undefined,
        CompanyCover: undefined,
        CompanyFacebook: '',
        CompanyTwitter: '',
        CompanyInstagram: '',
        CompanyLinkedIn: '',
        CompanyYoutube: '',
    });



    const FormAllInputs = [
        IsUpdate && {
            id: 1,
            ContentType: 'User',
            Content: [{
                name: 'Username',
                label: 'User Name',
                type: 'text',
                placeholder: 'Username',
                value: AllInputs.UserName,
                required: true,
                onChange: (e) => {
                    setAllInputs({ ...AllInputs, UserName: e.target.value });
                }
                // error: '',
                // valid: false,
                // touched: false,
                // validationRules: {
                //     isEmail: true,
                //     minLength: 6,
                //     maxLength: 30,
                // },
            },
            {
                name: 'UserEmail',
                label: 'Email',
                type: 'email',
                placeholder: 'Email',
                value: AllInputs.UserEmail,
                required: true,
                onChange: (e) => {
                    setAllInputs({ ...AllInputs, UserEmail: e.target.value });
                }
                // error: '',
                // valid: false,
                // touched: false,
                // validationRules: {
                //     isEmail: true,
                //     minLength: 6,
                //     maxLength: 30,
                // },

            },
            {
                name: 'UserPassword',
                label: 'Password',
                type: 'password',
                placeholder: 'Password',
                value: AllInputs.UserPassword,
                required: true,
                onChange: (e) => {
                    setAllInputs({ ...AllInputs, UserPassword: e.target.value });
                },
                // error: '',
                // valid: false,
                // touched: false,
                // validationRules: {
                //     isEmail: true,
                //     minLength: 6,
                //     maxLength: 30,
                // },
            },
            {
                name: 'ConfirmPassword',
                label: 'Confirm Password',
                type: 'password',
                placeholder: 'ConfirmPassword',
                value: AllInputs.UserConfirmPassword,
                required: true,
                onChange: (e) => {
                    setAllInputs({ ...AllInputs, UserConfirmPassword: e.target.value });
                },
                error: 'Password and Confirm Password must be same',
                // valid: false,
                // touched: false,
                // validationRules: {
                //     isEmail: true,
                //     minLength: 6,
                //     maxLength: 30,
                // },
            },
                // {
                //     name: 'UserFirstName',
                //     label: 'First Name',
                //     type: 'text',
                //     placeholder: 'First Name',
                //     value: AllInputs.UserFirstName,
                //     required: true,
                //     onChange: (e) => {
                //         setAllInputs({ ...AllInputs, UserFirstName: e.target.value });
                //     }
                //     // error: '',
                //     // valid: false,
                //     // touched: false,
                //     // validationRules: {
                //     //     isEmail: true,
                //     //     minLength: 6,
                //     //     maxLength: 30,
                //     // },
                // },
                // {
                //     name: 'UserLastName',
                //     label: 'Last Name',
                //     type: 'text',
                //     placeholder: 'Last Name',
                //     value: AllInputs.UserLastName,
                //     required: true,
                //     onChange: (e) => {
                //         setAllInputs({ ...AllInputs, UserLastName: e.target.value });
                //     }
                //     // error: '',
                //     // valid: false,
                //     // touched: false,
                //     // validationRules: {
                //     //     isEmail: true,
                //     //     minLength: 6,
                //     //     maxLength: 30,
                //     // },
                // }, 
            ]
        },
        IsUpdate && {
            id: 2,
            ContentType: 'CompanyProfile',
            Content: [
                {
                    id: 1,
                    name: 'CompanyName',
                    label: 'Company Name',
                    type: 'text',
                    placeholder: 'Company Name',
                    value: AllInputs.CompanyName,
                    required: true,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, CompanyName: e.target.value });
                    },
                },
                {
                    id: 2,
                    name: 'CompanyAddress',
                    label: 'Company Address',
                    required: true,
                    type: 'text',
                    placeholder: 'Company Address',
                    value: AllInputs.CompanyAddress,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, CompanyAddress: e.target.value });
                    },
                },
                {
                    id: 3,
                    name: 'CompanyEmail',
                    label: 'Company Email',
                    required: true,
                    type: 'email',
                    placeholder: 'Company Email',
                    value: AllInputs.CompanyEmail,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, CompanyEmail: e.target.value });
                    },
                },
                {
                    id: 4,
                    name: 'CompanyPhoneNumber',
                    label: 'Company Phone',
                    type: 'number',
                    placeholder: 'Company Phone',
                    value: AllInputs.CompanyPhoneNumber,
                    required: true,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, CompanyPhoneNumber: e.target.value });
                    },
                },
                {
                    id: 5,
                    name: 'CompanyDescription',
                    label: 'Company Description',
                    type: 'textarea',
                    placeholder: 'Company Description',
                    value: AllInputs.CompanyDescription,
                    required: true,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, CompanyDescription: e.target.value });
                    },
                },
                {
                    name: 'CompanyLogo',
                    label: 'Company Logo',
                    type: 'file',
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, CompanyLogo: e.target.files[0] });
                    },
                },
                {
                    name: 'ProjectOwner',
                    label: 'Project Owner',
                    type: 'file',
                    // placeholder: 'Company Logo',
                    // value: AllInputs.CompanyLogo,
                    // required: true,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, ProjectOwner: e.target.files[0] });
                    },
                },
                {
                    name: 'ProjectPartner',
                    label: 'Project Partner',
                    type: 'file',
                    // placeholder: 'Company Logo',
                    // value: AllInputs.CompanyLogo,
                    // required: true,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, ProjectPartner: e.target.files[0] });
                    },
                },

                {
                    id: 7,
                    formGroup: true,
                    name: 'CompanySocial',
                    Content: [
                        {
                            id: 1,
                            width: '20%',
                            name: 'CompanyFacebook',
                            label: 'Company Facebook',
                            type: 'text',
                            placeholder: 'Company Facebook',
                            value: AllInputs.CompanyFacebook,
                            required: true,
                            onChange: (e) => {
                                setAllInputs({ ...AllInputs, CompanyFacebook: e.target.value });
                            },

                        },
                        {
                            id: 2,
                            width: '20%',
                            name: 'CompanyTwitter',
                            label: 'Company Twitter',
                            type: 'text',
                            placeholder: 'Company twitter',
                            value: AllInputs.CompanyTwitter,
                            required: true,
                            onChange: (e) => {
                                setAllInputs({ ...AllInputs, CompanyTwitter: e.target.value });
                            },

                        },
                        {
                            id: 3,
                            width: '20%',
                            name: 'CompanyYoutube',
                            label: 'Company Youtube',
                            type: 'text',
                            placeholder: 'Company Youtube',
                            value: AllInputs.CompanyYoutube,
                            required: true,
                            onChange: (e) => {
                                setAllInputs({ ...AllInputs, CompanyYoutube: e.target.value });
                            },

                        },
                        {
                            id: 4,
                            width: '20%',
                            name: 'CompanyInstagram',
                            label: 'Company Instagram',
                            type: 'text',
                            placeholder: 'Company Instagram',
                            value: AllInputs.CompanyInstagram,
                            required: true,
                            onChange: (e) => {
                                setAllInputs({ ...AllInputs, CompanyInstagram: e.target.value });
                            },

                        },
                        {
                            id: 4,
                            width: '20%',
                            name: 'CompanyLinkedIn',
                            label: 'Company LinkedIn',
                            type: 'text',
                            placeholder: 'Company LinkedIn',
                            value: AllInputs.CompanyLinkedIn,
                            required: true,
                            onChange: (e) => {
                                setAllInputs({ ...AllInputs, CompanyLinkedIn: e.target.value });
                            },

                        },
                    ]
                },
            ]
        },
        !IsUpdate && {
            id: 1,
            ContentType: 'User',
            Content: [{
                name: 'Username',
                label: 'User Name',
                type: 'text',
                placeholder: 'Username',
                value: AllInputs.UserName,
                required: true,
                onChange: (e) => {
                    setAllInputs({ ...AllInputs, UserName: e.target.value });
                }
            },
            {
                name: 'UserEmail',
                label: 'Email',
                type: 'email',
                placeholder: 'Email',
                value: AllInputs.UserEmail,
                required: true,
                onChange: (e) => {
                    setAllInputs({ ...AllInputs, UserEmail: e.target.value });
                }
            },
            {
                name: 'UserPassword',
                label: <>Password <small style={{ color: 'red' }}>( Password must contain a special characters, minimum of 8 and uppercase letter ) </small></>,
                type: 'password',
                placeholder: 'Password',
                value: AllInputs.UserPassword,
                required: true,
                onChange: (e) => {
                    setAllInputs({ ...AllInputs, UserPassword: e.target.value });
                },
            },
            {
                name: 'ConfirmPassword',
                label: 'Confirm Password',
                type: 'password',
                placeholder: 'ConfirmPassword',
                value: AllInputs.UserConfirmPassword,
                required: true,
                onChange: (e) => {
                    setAllInputs({ ...AllInputs, UserConfirmPassword: e.target.value });
                },
            },

            ]
        },
        !IsUpdate && {
            id: 2,
            ContentType: 'CompanyProfile',
            Content: [
                {
                    id: 1,
                    name: 'CompanyName',
                    label: 'Company Name',
                    type: 'text',
                    placeholder: 'Company Name',
                    value: AllInputs.CompanyName,
                    required: true,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, CompanyName: e.target.value });
                    },
                },
                {
                    id: 2,
                    name: 'CompanyAddress',
                    label: 'Company Address',
                    type: 'text',
                    placeholder: 'Company Address',
                    value: AllInputs.CompanyAddress,
                    required: true,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, CompanyAddress: e.target.value });
                    },
                },
                {
                    id: 3,
                    name: 'CompanyEmail',
                    label: 'Company Email',
                    type: 'email',
                    placeholder: 'Company Email',
                    value: AllInputs.CompanyEmail,
                    required: true,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, CompanyEmail: e.target.value });
                    },
                },
                {
                    id: 4,
                    name: 'CompanyPhoneNumber',
                    label: 'Company Phone',
                    type: 'number',
                    placeholder: 'Company Phone',
                    value: AllInputs.CompanyPhoneNumber,
                    required: true,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, CompanyPhoneNumber: e.target.value });
                    },
                },
                {
                    id: 5,
                    name: 'CompanyDescription',
                    label: 'Company Description',
                    type: 'textarea',
                    placeholder: 'We do something...',
                    value: AllInputs.CompanyDescription,
                    required: true,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, CompanyDescription: e.target.value });
                    },
                },
                {
                    name: 'CompanyLogo',
                    label: 'Company Logo',
                    type: 'file',
                    // placeholder: 'Company Logo',
                    // value: AllInputs.CompanyLogo,
                    // required: true,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, CompanyLogo: e.target.files[0] });
                    },
                },
                {
                    name: 'ProjectOwner',
                    label: 'Project Owner',
                    type: 'file',
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, ProjectOwner: e.target.files[0] });
                    },
                },
                {
                    name: 'ProjectPartner',
                    label: 'Project Partner',
                    type: 'file',
                    // placeholder: 'Company Logo',
                    // value: AllInputs.CompanyLogo,
                    // required: true,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, ProjectPartner: e.target.files[0] });
                    },
                },

                {
                    id: 7,
                    formGroup: true,
                    name: 'CompanySocial',
                    Content: [
                        {
                            id: 1,
                            width: '20%',
                            name: 'CompanyFacebook',
                            label: 'Company Facebook',
                            type: 'text',
                            placeholder: 'Company Facebook',
                            value: AllInputs.CompanyFacebook,
                            required: true,
                            onChange: (e) => {
                                setAllInputs({ ...AllInputs, CompanyFacebook: e.target.value });
                            },

                        },
                        {
                            id: 2,
                            width: '20%',
                            name: 'CompanyTwitter',
                            label: 'Company Twitter',
                            type: 'text',
                            placeholder: 'Company twitter',
                            value: AllInputs.CompanyTwitter,
                            required: true,
                            onChange: (e) => {
                                setAllInputs({ ...AllInputs, CompanyTwitter: e.target.value });
                            },

                        },
                        {
                            id: 3,
                            width: '20%',
                            name: 'CompanyYoutube',
                            label: 'Company Youtube',
                            type: 'text',
                            placeholder: 'Company Youtube',
                            value: AllInputs.CompanyYoutube,
                            required: true,
                            onChange: (e) => {
                                setAllInputs({ ...AllInputs, CompanyYoutube: e.target.value });
                            },

                        },
                        {
                            id: 4,
                            width: '20%',
                            name: 'CompanyInstagram',
                            label: 'Company Instagram',
                            type: 'text',
                            placeholder: 'Company Instagram',
                            value: AllInputs.CompanyInstagram,
                            required: true,
                            onChange: (e) => {
                                setAllInputs({ ...AllInputs, CompanyInstagram: e.target.value });
                            },

                        },
                        {
                            id: 4,
                            width: '20%',
                            name: 'CompanyLinkedIn',
                            label: 'Company LinkedIn',
                            type: 'text',
                            placeholder: 'Company LinkedIn',
                            value: AllInputs.CompanyLinkedIn,
                            required: true,
                            onChange: (e) => {
                                setAllInputs({ ...AllInputs, CompanyLinkedIn: e.target.value });
                            },

                        },
                    ]
                },
            ]
        }

    ];
    const InputObject = ({ name, value, label, type, onChange }) => {
        return {
            id: 3,
            name: name,
            value: value,
            label: label,
            type: type,
            onChange: onChange,

        }
    }
    useEffect(() => {
        setShowFetchedData([]);
        setIsUpdate(false)
        setShowForm(false);
        setPasswordCheck(false);
        setAllInputs({
            Role: '',
            UserFirstName: '',
            UserLastName: '',
            UserEmail: '',
            UserName: '',
            UserPhoneNumber: '',
            UserImage: '',
            UserPassword: '',
            UserConfirmPassword: '',


            CompanyName: '',
            CompanyAddress: '',
            CompanyEmail: '',
            CompanyPhoneNumber: '',
            CompanyDescription: '',
            CompanyLogo: undefined,
            CompanyCover: undefined,
            CompanyFacebook: '',
            CompanyTwitter: '',
            CompanyInstagram: '',
            CompanyLinkedIn: '',
            CompanyYoutube: '',
        });

        const allFetch = async () => {

            const FetchCompanyProfile = async () => {
                const CompanyProfileQuery = new Parse.Query('CompanyProfile');
                const UserQuery = new Parse.Query('_User');

                try {
                    if (PropsData.className === 'CompanyProfile') {
                        let allCompanyProfile = await CompanyProfileQuery.find();
                        setShowFetchedData(allCompanyProfile);
                    }
                    if (PropsData.className === 'User') {
                        let allUser = await UserQuery.find();
                        setShowFetchedData(allUser);

                    }
                } catch (error) {
                    // console.log('page error', error);
                }
            }
            await FetchCompanyProfile();
        }
        allFetch();
        window.scrollTo(0, 0);
        // window.addEventListener('click', allFetch);

        return () => {
            window.removeEventListener('click', allFetch);
        }
    }, [PropsData]);


    const ClearAllInputs = () => {
        setAllInputs({
            role: '',
            userFirstName: '',
            userLastName: '',
            userEmail: '',
            userName: '',
            userPhoneNumber: '',
            userImage: '',
            password: '',
            confirmPassword: '',
        });
    }

    const doUserRegistration = async function (e) {
        // setLoading(true);
        e.preventDefault();

        // Note that these values come from state variables that we've declared before
        const { Role, UserName, UserEmail, UserPassword, UserConfirmPassword, UserFirstName, UserLastName, UserPhoneNumber, UserImage } = AllInputs;

        if (!UserUpdate) {
            if (UserPassword !== UserConfirmPassword) {
                setPasswordCheck(true);
                // setLoading(false);
            }
            else {
                const user = new Parse.User();
                // user.set('role', Role);
                user.set('email', UserEmail);
                user.set('username', UserName);
                user.set('password', UserPassword);
                user.set('createdBy', CurrentUser);
                user.set('updatedBy', CurrentUser);
                // user.set('firstName', UserFirstName);
                // user.set('lastName', UserLastName);
                // user.set('phoneNumber', UserPhoneNumber);
                // if (userImageValue !== '') {
                //     const newImage = new Parse.File(userImageValue.name, userImageValue);
                //     user.set('image', newImage);
                // }


                try {
                    await user.signUp();

                    toast.success(
                        `Success! User ${user.getUsername()} was successfully created!`
                    );
                    ClearAllInputs();
                    setPasswordCheck(false);
                    // history.push('/dashboard');
                    // setLoading(false);
                } catch (error) {
                    toast.error("Error: " + error.code + " " + error.message);
                    console.log(error);
                    // setLoading(false);
                }
            }
        }
        if (UserUpdate) {

            const userQuery = new Parse.Query("_User");
            userQuery.equalTo("objectId", UserUpdateData);
            // // console.log(userQuery);
            try {
                let user = await userQuery.first();
                user.set('email', UserEmail);
                user.set('username', UserName);
                user.set('password', UserPassword);
                user.set('updatedBy', CurrentUser);
                // user.set('firstName', UserFirstName);
                // user.set('lastName', UserLastName);
                // user.set('phoneNumber', UserPhoneNumber);
                // if (ChangedLogo || AllInputs.userImage !== '' || AllInputs.userImage !== undefined) {
                //     const newImage = new Parse.File(userImageValue.name, userImageValue);
                //     user.set('image', newImage);
                // }

                await user.save();
                toast.success(
                    `Success! User was successfully updated!`
                );
                ClearAllInputs();
                setPasswordCheck(false);
                // setLoading(false);
                setUserUpdate(false);

            } catch (error) {
                // console.log(error);
                // setLoading(false);
            }

        }



        // setLoading(false);
    };



    const VisibilityChange = async (data, visibility) => {
        const newData = data.data;
        const visibilityStatus = visibility.visibility;
        // console.log('Get Data', data, visibility)
        // console.log('newData', newData)
        // console.log('visibilityStatus', visibilityStatus)
        const contentQuery = new Parse.Query(newData.className);
        contentQuery.equalTo("objectId", newData.id);
        try {
            let foundContent = await contentQuery.first();

            // console.log(foundContent.get('visibility'))
            if (visibilityStatus === 'Hide') {
                foundContent.set('visibility', false);
            }
            if (visibilityStatus === 'Show') {
                foundContent.set('visibility', true);
            }
            await foundContent.save();
            toast.success(`Visibility changed to ${visibilityStatus} Succesfully`)
        } catch (error) {
            // console.log(error);
        }
    }
    const FormatTime = (date) => {
        try {
            let time = date.split(" ");
            let hours = time[4].split(":")[0];
            let minutes = time[4].split(":")[1];
            let second = time[4].split(":")[2];
            let day = time[0].split(",")[0];
            let lcldate = time[1];
            let month = time[2];
            let year = time[3];
            hours = Number(hours) + 6
            hours = hours || 12;
            const ampm = hours >= 12 ? "PM" : "AM";
            minutes = minutes < 10 ? `${minutes}` : minutes;
            hours %= 12;
            const strTime = `${hours}:${minutes} ${ampm} ${day} ${lcldate} ${month} ${year}`;
            return strTime;
        } catch (e) {
            return "";
        }
    };


    const FormSubmit = async (e) => {

        e.preventDefault();
        const { ProjectOwner, ProjectPartner, CompanyName, CompanyAddress, CompanyPhoneNumber, CompanyEmail, CompanyWebsite, CompanyDescription, CompanyLogo, CompanyFacebook, CompanyTwitter, CompanyInstagram, CompanyLinkedIn, CompanyYoutube } = AllInputs;


        setFormLoading(true);
        if (IsUpdate) {
            const NewCompanyProfile = new Parse.Query('CompanyProfile');
            NewCompanyProfile.equalTo('objectId', UpdatedData.id);
            let FoundCompanyProfile = await NewCompanyProfile.first();
            try {

                FoundCompanyProfile.set('companyName', CompanyName);
                FoundCompanyProfile.set('companyAddress', CompanyAddress);
                FoundCompanyProfile.set('companyPhoneNumber', Number(CompanyPhoneNumber));
                FoundCompanyProfile.set('companyEmail', CompanyEmail);
                FoundCompanyProfile.set('companyWebsite', CompanyWebsite);
                FoundCompanyProfile.set('companyDescription', CompanyDescription);
                FoundCompanyProfile.set('companyFacebook', CompanyFacebook);
                FoundCompanyProfile.set('companyTwitter', CompanyTwitter);
                FoundCompanyProfile.set('companyInstagram', CompanyInstagram);
                FoundCompanyProfile.set('companyLinkedIn', CompanyLinkedIn);
                FoundCompanyProfile.set('companyYoutube', CompanyYoutube);
                FoundCompanyProfile.set('updatedBy', CurrentUser);

                if (!Changedlogo && CompanyLogo !== '') {
                    // console.log('Changedlogo', AllInputs.CompanyLogo);
                    const newImage = new Parse.File(CompanyLogo.name, CompanyLogo);
                    FoundCompanyProfile.set('companyLogo', newImage);
                }
                if (!ChangedOwner && ProjectOwner !== '') {
                    // console.log('ChangedOwner', AllInputs.ProjectOwner);
                    const newImage = new Parse.File(ProjectOwner.name, ProjectOwner);
                    FoundCompanyProfile.set('projectOwner', newImage);
                }
                if (!ChangedPartner && ProjectPartner !== '') {
                    // console.log('ChangedPartner', AllInputs.ProjectPartner);
                    const newImage = new Parse.File(ProjectPartner.name, ProjectPartner);
                    FoundCompanyProfile.set('projectPartner', newImage);
                }

                await FoundCompanyProfile.save();

                toast.success(`Company profile details updated  Succesfully`);
            } catch (error) {
                // console.log(error);
                setFormLoading(false);
            }
        }
        if (!IsUpdate) {
            const NewCompanyProfile = new Parse.Object('CompanyProfile');
            try {

                NewCompanyProfile.set('companyName', CompanyName);
                NewCompanyProfile.set('companyAddress', CompanyAddress);
                NewCompanyProfile.set('companyPhoneNumber', Number(CompanyPhoneNumber));
                NewCompanyProfile.set('companyEmail', CompanyEmail);
                NewCompanyProfile.set('companyWebsite', CompanyWebsite);
                NewCompanyProfile.set('companyDescription', CompanyDescription);
                NewCompanyProfile.set('companyFacebook', CompanyFacebook);
                NewCompanyProfile.set('companyTwitter', CompanyTwitter);
                NewCompanyProfile.set('companyInstagram', CompanyInstagram);
                NewCompanyProfile.set('companyLinkedIn', CompanyLinkedIn);
                NewCompanyProfile.set('companyYoutube', CompanyYoutube);
                NewCompanyProfile.set('visibility', true);
                NewCompanyProfile.set('createdBy', CurrentUser);
                NewCompanyProfile.set('updatedBy', CurrentUser);

                if (CompanyLogo) {
                    const newImage = new Parse.File(CompanyLogo.name, CompanyLogo);
                    NewCompanyProfile.set('companyLogo', newImage);
                }
                if (ProjectOwner) {
                    const newImage = new Parse.File(ProjectOwner.name, ProjectOwner);
                    NewCompanyProfile.set('projectOwner', newImage);
                }
                if (ProjectPartner) {
                    const newImage = new Parse.File(ProjectPartner.name, ProjectPartner);
                    NewCompanyProfile.set('projectPartner', newImage);
                }


                await NewCompanyProfile.save();

                toast.success(`New company profile created succesfully`);
            } catch (error) {
                // console.log(error);
                setFormLoading(false);
            }
        }

        setFormLoading(false);
        ClearAllInputs();
        setIsUpdate(false);
        setShowForm(false);
    }

    const handleUpdate = (data) => {
        setIsUpdate(true)
        window.scrollTo({
            top: 100,
            behavior: 'smooth'
        });

        const newData = data.data;



        setAllInputs({

            CompanyName: newData.get('companyName'),
            CompanyAddress: newData.get('companyAddress'),
            CompanyEmail: newData.get('companyEmail'),
            CompanyPhoneNumber: newData.get('companyPhoneNumber'),
            CompanyDescription: newData.get('companyDescription'),
            CompanyLogo: newData.get('companyLogo') ? newData.get('companyLogo')['_url'] : undefined,
            ProjectOwner: newData.get('projectOwner') ? newData.get('projectOwner')['_url'] : undefined,
            ProjectPartner: newData.get('projectPartner') ? newData.get('projectPartner')['_url'] : undefined,
            CompanyFacebook: newData.get('companyFacebook'),
            CompanyTwitter: newData.get('companyTwitter'),
            CompanyInstagram: newData.get('companyInstagram'),
            CompanyLinkedIn: newData.get('companyLinkedIn'),
            CompanyYoutube: newData.get('companyYoutube'),

        });

        if (newData.get('companyLogo')) {
            setChangedLogo(true);
        }
        if (newData.get('projectOwner')) {
            // console.log('projectOwner');
            setChangedOwner(true);
        }
        if (newData.get('projectPartner')) {
            setChangedPartner(true);
        }
        // setIsUpdate(true);
        setShowForm(true)
        setUpdatedData(newData);
        // setShowForm(false);
    }

    const handleDelete = async (data) => {
        const newData = data.data;
        // // console.log(newData);
        // setShowDeleteModal(true); 
        if (DeleteConfirmaiton === true) {
            // setShowForm(false);
            if (newData.className === 'CompanyProfile') {
                //    setEditOverlay(true)

                const CompanyProfileQuery = new Parse.Query("CompanyProfile");
                CompanyProfileQuery.equalTo("objectId", newData.id);

                try {
                    let foundCompanyProfile = await CompanyProfileQuery.first();
                    await foundCompanyProfile.destroy();
                    toast.success('Deleted Succesfully')
                } catch (error) {
                    // console.log(error);
                }

            }
        }
    }

    const findUser = async (e) => {
        Parse.User.enableUnsafeCurrentUser();
        const currentUser = await Parse.User.currentAsync();
        setCurrentUser(currentUser);
    }
    findUser();


    return (
        <DashContentDiv>
            {/* {
                ShowFetchedData.length === 0 ? <div className={'confirmationOverlay activeConfirmationOverlay'}>
                    <div className={'activeSpinnerHolder spinnerHolder'}>
                        <spin className={'activeOverlaySpinner overlaySpinner'}></spin>
                    </div>
                </div> : undefined
            } */}
            <div className={FormLoading ? 'confirmationOverlay activeConfirmationOverlay' : 'confirmationOverlay'}>
                <div className={FormLoading ? 'activeSpinnerHolder spinnerHolder' : 'spinnerHolder'}>
                    <spin className={FormLoading ? 'activeOverlaySpinner overlaySpinner' : 'overlaySpinner'}></spin>
                </div>
            </div>
            {
                PropsData.className === 'User' || (PropsData.className === 'CompanyProfile' && ShowFetchedData.length < 1) ?
                    <PrimaryButton onClick={() => { setShowForm(!ShowForm); setIsUpdate(false); ClearAllInputs(); }}>{ShowForm ? "Cancel" : "Add"}</PrimaryButton> :
                    undefined
            }

            <form style={ShowForm ? { textAlign: 'start', display: 'block' } : { display: 'none' }} onSubmit={PropsData.className === 'CompanyProfile' ? FormSubmit : doUserRegistration} >
                {/* {PropsData.className === 'User' && <div>
                    <label>Choose a Role: </label>
                    <select onChange={e => setAllInputs({ ...AllInputs, Role: e.target.value })}>
                        <option value="">Select</option>
                        {UserRole.map((dropdown, idx) => (
                            <option key={idx} value={dropdown.value}>{dropdown.label}</option>
                        ))}
                    </select></div>} */}

                {
                    FormAllInputs.map((data, index) => {
                        return (
                            PropsData.className === data.ContentType &&
                            data.Content.map((input, index) => {
                                return (
                                    <div key={index}>
                                        <label>{input.label}</label>
                                        {
                                            input.type && input.type === 'textarea' ?
                                                <textarea required={input.required} type={input.type} name={input.name} value={input.value} onChange={input.onChange} /> :
                                                input.type && input.type !== 'textarea' && input.type !== 'file' ?
                                                    <input required={input.required} type={input.type} placeholder={input.placeholder} name={input.name} value={input.value} onChange={input.onChange} /> :
                                                    input.type && input.type === 'file' && input.name === 'CompanyLogo' ?
                                                        <>
                                                            {IsUpdate && Changedlogo && AllInputs.CompanyLogo ?
                                                                <div style={{
                                                                    display: 'flex',
                                                                    border: '1px solid #ff000063',
                                                                    width: 'fit-content',
                                                                    padding: '6px',
                                                                    flexDirection: 'column',
                                                                    borderRadius: '5px'
                                                                }} ><img src={AllInputs.CompanyLogo} style={{ width: '100px', height: '100px' }} alt="Gallery" />
                                                                    <small style={{ color: 'red', fontSize: '1.2rem', cursor: 'pointer' }} onClick={() => { setChangedLogo(!Changedlogo); setAllInputs({ ...AllInputs, CompanyLogo: undefined }); }}>Change logo</small></div> :

                                                                <input required={input.required} type={input.type} name={input.name} onChange={input.onChange} />
                                                            }
                                                        </> :
                                                        input.type && input.type === 'file' && input.name === 'ProjectOwner' ?
                                                            <>

                                                                {IsUpdate && ChangedOwner && AllInputs.ProjectOwner ? <div style={{
                                                                    display: 'flex',
                                                                    border: '1px solid #ff000063',
                                                                    width: 'fit-content',
                                                                    padding: '6px',
                                                                    flexDirection: 'column',
                                                                    borderRadius: '5px'
                                                                }} ><img src={AllInputs.ProjectOwner} style={{ width: '100px', height: '100px' }} alt="Gallery" />
                                                                    <small style={{ color: 'red', fontSize: '1.2rem', cursor: 'pointer' }} onClick={() => { setChangedOwner(!ChangedOwner); setAllInputs({ ...AllInputs, ProjectOwner: undefined }); }}>Change logo</small></div> :

                                                                    <input required={input.required} type={input.type} name={input.name} onChange={input.onChange} />
                                                                }

                                                            </> :
                                                            input.type && input.type === 'file' && input.name === 'ProjectPartner' ?
                                                                <>

                                                                    {IsUpdate && ChangedPartner && AllInputs.ProjectPartner ? <div style={{
                                                                        display: 'flex',
                                                                        border: '1px solid #ff000063',
                                                                        width: 'fit-content',
                                                                        padding: '6px',
                                                                        flexDirection: 'column',
                                                                        borderRadius: '5px'
                                                                    }} ><img src={AllInputs.ProjectPartner} style={{ width: '100px', height: '100px' }} alt="Gallery" />
                                                                        <small style={{ color: 'red', fontSize: '1.2rem', cursor: 'pointer' }} onClick={() => { setChangedPartner(!ChangedPartner); setAllInputs({ ...AllInputs, ProjectPartner: undefined }); }}>Change logo</small></div> :

                                                                        <input required={input.required} type={input.type} name={input.name} onChange={input.onChange} />
                                                                    }
                                                                </> : undefined

                                        }

                                        < InputGroupDiv key={index} >
                                            {
                                                input.formGroup && input.name === 'CompanySocial' && input.Content.map((ContentData, index) => {
                                                    return (

                                                        <InputGroup width={ContentData.width} key={index}>
                                                            <label>{ContentData.label} </label>
                                                            <input required={input.required} type={ContentData.type} placeholder={input.placeholder} name={ContentData.name} value={AllInputs[ContentData.name]} onChange={ContentData.onChange} />
                                                        </InputGroup>

                                                    )
                                                })
                                            }


                                        </InputGroupDiv>

                                    </div>
                                )
                            })
                        )
                    }
                    )

                }
                {PasswordCheck && <small style={{ color: 'red' }}>{'Password and Confirm Password must be same'}</small>}<br />
                <PrimaryButton type='submit'>{IsUpdate ? 'Update' : 'Add'}</PrimaryButton>
            </form>

            {
                ShowFetchedData.length !== 0 ?
                    <DashContentTableDiv>
                        <LclTable>
                            <LclTRow>


                                {PropsData.className === 'CompanyProfile' && <LclTData>Company Name</LclTData>}
                                {PropsData.className === 'CompanyProfile' && <LclTData>Company Address</LclTData>}
                                {PropsData.className === 'CompanyProfile' && <LclTData>Company Email</LclTData>}
                                {PropsData.className === 'CompanyProfile' && <LclTData>Company Phone</LclTData>}
                                {PropsData.className === 'CompanyProfile' && <LclTData>Company About</LclTData>}
                                {PropsData.className === 'CompanyProfile' && <LclTData>Company Social</LclTData>}
                                {PropsData.className === 'CompanyProfile' && <LclTData>Company Logo</LclTData>}
                                {PropsData.className === 'CompanyProfile' && <LclTData>Project Owner</LclTData>}
                                {PropsData.className === 'CompanyProfile' && <LclTData>Project Partner</LclTData>}

                                {/* {PropsData.className === 'User' && <LclTData>User Role</LclTData>} */}
                                {PropsData.className === 'User' && <LclTData>User Name</LclTData>}
                                {/* {PropsData.className === 'User' && <LclTData>User First Name</LclTData>} */}
                                {/* {PropsData.className === 'User' && <LclTData>User Last Name</LclTData>} */}
                                {PropsData.className === 'User' && <LclTData>User Email</LclTData>}
                                {PropsData.className === 'User' && <LclTData>Status</LclTData>}
                                {/* {PropsData.className === 'User' && <LclTData>User Phone</LclTData>} */}
                                {/* {PropsData.className === 'User' && <LclTData>User Picture</LclTData>} */}





                                <LclTData>Create / Update info</LclTData>
                                {PropsData.className === 'CompanyProfile' && <LclTData>Visibility</LclTData>}
                                {PropsData.className === 'CompanyProfile' && <LclTData>Manage</LclTData>}

                            </LclTRow>

                            {ShowFetchedData.map((data, index) => {
                                return <LclTRow key={index}>



                                    {data.className === 'CompanyProfile' && <LclTData>{data.get('companyName') ? data.get('companyName') : '----'}</LclTData>}
                                    {data.className === 'CompanyProfile' && <LclTData>{data.get('companyAddress') ? data.get('companyAddress') : '----'}</LclTData>}
                                    {data.className === 'CompanyProfile' && <LclTData>{data.get('companyEmail') ? data.get('companyEmail') : '----'}</LclTData>}
                                    {data.className === 'CompanyProfile' && <LclTData>{data.get('companyPhoneNumber') ? `+880 ${data.get('companyPhoneNumber')}` : '----'}</LclTData>}
                                    {data.className === 'CompanyProfile' && <LclTData>{data.get('companyDescription') ? data.get('companyDescription') : '----'}</LclTData>}

                                    {/* {data.className === '_User' && data.get('role') === 'Admin' && <LclTData>{data.get('role') ? data.get('role') : '----'}</LclTData>} */}
                                    {data.className === '_User' && <LclTData>{data.get('username') ? data.get('username') : '----'}    </LclTData>}
                                    {/* {data.className === '_User' && data.get('role') === 'Admin' && <LclTData>{data.get('firstName') ? data.get('firstName') : '----'}</LclTData>} */}
                                    {/* {data.className === '_User' && data.get('role') === 'Admin' && <LclTData>{data.get('lastName') ? data.get('lastName') : '----'}</LclTData>} */}
                                    {data.className === '_User' && <LclTData>{data.get('email') ? data.get('email') : '----'}</LclTData>}
                                    {/* {data.className === '_User' && data.get('role') === 'Admin' && <LclTData>{data.get('phoneNumber') ? `+880 ${data.get('phoneNumber')}` : '----'}</LclTData>} */}
                                    {/* {data.className === '_User' && <LclTData>{data.get('companyDescription')}</LclTData>} */}
                                    {data.className === '_User' && <LclTData>  {CurrentUser && CurrentUser.id === data.id ? <p style={{ color: 'green', display: 'flex', alignItems: 'center' }}>Active <span style={{ margin: '0 8px', borderRadius: '50%', height: '12px', width: '12px', display: 'inline-block', background: 'green' }}></span> </p> : <p style={{ color: 'red', display: 'flex', alignItems: 'center' }}>Inactive<span style={{ margin: '0 8px', borderRadius: '50%', height: '12px', width: '12px', display: 'inline-block', background: 'red' }}></span></p>} </LclTData>}



                                    {data.className === 'CompanyProfile' &&
                                        <LclTData>
                                            <LclTable margin='10px 0'>
                                                <LclTRow><LclTData>Facebook Link: {data.get('companyFacebook') ? data.get('companyFacebook') : '----'}</LclTData></LclTRow>
                                                <LclTRow><LclTData>Twitter Link: {data.get('companyTwitter') ? data.get('companyTwitter') : '----'}</LclTData></LclTRow>
                                                <LclTRow><LclTData>Instagram Link: {data.get('companyInstagram') ? data.get('companyInstagram') : '----'}</LclTData></LclTRow>
                                                <LclTRow><LclTData>LinkedIn Link: {data.get('companyLinkedIn') ? data.get('companyLinkedIn') : '----'}</LclTData></LclTRow>
                                                <LclTRow><LclTData>Youtube Link: {data.get('companyYoutube') ? data.get('companyYoutube') : '----'}</LclTData></LclTRow>
                                            </LclTable>
                                        </LclTData>}
                                    {data.className === 'CompanyProfile' && <LclTData><img src={data.get('companyLogo') && data.get('companyLogo')['_url']} alt='Company Logo' style={{ width: '100px', height: '100px', objectFit: 'contain' }} /></LclTData>}
                                    {data.className === 'CompanyProfile' && <LclTData><img src={data.get('projectOwner') && data.get('projectOwner')['_url']} alt='Project Owner ' style={{ width: '100px', height: '100px', objectFit: 'contain' }} /></LclTData>}
                                    {data.className === 'CompanyProfile' && <LclTData><img src={data.get('projectPartner') && data.get('projectPartner')['_url']} alt='Project Partner' style={{ width: '100px', height: '100px', objectFit: 'contain' }} /></LclTData>}



                                    {/* {PropsData.className !== 'CompanyProfile' && <LclTData>{data.get('image') ? <img src={data.get('image') && data.get('image')['_url']} alt={data.get('image') && data.get('image')['_name']} style={{ height: '100px' }} /> : 'Not Found'}</LclTData>} */}



                                    <LclTData>
                                        <LclTable>
                                            <LclTRow display='flex' flexDirection='column'>
                                                <LclTData fontSize='15px' textAlign='center'>
                                                    Created at: {data.get("createdAt") !== undefined && FormatTime(data.get("createdAt").toUTCString())}
                                                </LclTData>
                                                {PropsData.className === 'CompanyProfile' && <LclTData fontSize='15px' textAlign='center'>
                                                    Created by: {data.get("createdBy") !== undefined && data.get("createdBy").get('username')}
                                                </LclTData>}
                                                <LclTData fontSize='15px' textAlign='center'>
                                                    Last Updated at: {data.get("updatedAt") !== undefined && FormatTime(data.get("updatedAt").toUTCString())}
                                                </LclTData>
                                                {PropsData.className === 'CompanyProfile' && <LclTData fontSize='15px' textAlign='center'>
                                                    Last Updated by: {data.get("updatedBy") !== undefined && data.get("updatedBy").get('username')}
                                                </LclTData>}
                                            </LclTRow>

                                        </LclTable>
                                    </LclTData>
                                    {PropsData.className === 'CompanyProfile' && <LclTData>
                                        <LclTable>
                                            <LclTRow>
                                                <LclTData> Present </LclTData>
                                                <LclTData> Change To </LclTData>
                                            </LclTRow>
                                            <LclTRow>
                                                <LclTData color='#0dcc00' fontSize='15px'>{data.get('visibility') === false ? <b style={{ cursor: 'pointer' }}>Hide <BiHide /></b> : <b>Show  <BiShowAlt /> </b>}</LclTData>
                                                <LclTData>  {data.get('visibility') === false ? <small style={{ margin: '10px', color: 'red', fontSize: '15px', cursor: 'pointer', }} onClick={() => VisibilityChange({ data: data }, { visibility: 'Show' })}> Show <BiShowAlt /></small> : <small style={{ margin: '10px', color: 'red', fontSize: '15px', cursor: 'pointer', }} onClick={() => VisibilityChange({ data: data }, { visibility: 'Hide' })}> Hide <BiHide /></small>} </LclTData>

                                            </LclTRow>

                                        </LclTable>

                                    </LclTData>}
                                    {PropsData.className === 'CompanyProfile' && <LclTData>
                                        <PrimaryButton style={{ margin: '10px' }}
                                            onClick={() => { handleUpdate({ data: data }); }}
                                        >Update </PrimaryButton>
                                        <DangerButton style={{ margin: '10px' }}
                                            onClick={() => { setDeleteConfirmaiton(true); setShowDeleteModal(true); setDeleteContent({ data: data }) }}
                                        > Delete </DangerButton>
                                    </LclTData>}
                                </LclTRow>
                            }
                            )}
                        </LclTable >
                        <div className={ShowDeleteModal ? 'Modal Open' : 'Modal'}>
                            < div className='ModalCard'>
                                <H3 color='red' padding='0 0 20px 0' margin='20px 0' borderBottom='1px solid red'> <AiFillWarning color='red' fontSize={'40px'} />Delete</H3>
                                {
                                    DeleteContent !== null ? <div>  Are you sure you want to delete <span style={{ color: 'red', fontWeight: 'bolder' }}> {DeleteContent.data.get('sectionName') ? DeleteContent.data.get('sectionName') : DeleteContent.data.get('pageName') ? DeleteContent.data.get('pageName') : DeleteContent.data.get('title') ? DeleteContent.data.get('title') : null} </span>?  </div> : null
                                }
                                <div>

                                    <PrimaryButton onClick={() => setShowDeleteModal(false)}>Cancel</PrimaryButton>
                                    <DangerButton onClick={() => { setShowDeleteModal(false); handleDelete(DeleteContent); }}>Delete</DangerButton>
                                </div>
                            </div>
                        </div>
                    </DashContentTableDiv> : <H2 margin='20rem 10rem' color=' red'>{PropsData === 'CompanyProfile' ? "Please add company profile before adding any content's " : "No user found"}</H2>
            }


            <Toaster
                position="top-center"
                reverseOrder={false}
                autoClose={10000}
            />

        </DashContentDiv >
    );
};

export default DashCompanyAndUser;