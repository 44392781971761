import React, { useContext, useState } from 'react';
import { Redirect, Route } from 'react-router';
// import { DataContext } from '../../../App';
// import User from './DashUser';
import Parse from 'parse/dist/parse.min.js';

const PrivateRoute = ({ children, ...rest }) => {

    // const { currentUser } = useContext(DataContext);
    const [CurrentUser, setCurrentUser] = useState({});

    const findUser = async () => {
        Parse.User.enableUnsafeCurrentUser();
        const currentUser = await Parse.User.currentAsync();
        setCurrentUser(currentUser);
        // // console.log(currentUser);
    }
    findUser();
    return (
        <Route {...rest}
            render={({ location }) => (
                CurrentUser ? (
                    children
                ) : (
                    <Redirect

                        to={{
                            pathname: '/login',
                            state: { from: location }
                        }}
                    />
                )
            )} />

    );
};



export default PrivateRoute;