import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Parse from 'parse/dist/parse.min.js';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { H2, PrimaryButton } from '../Component/StyledComponent/VMBALLStyled';

const DashboardLogin = () => {
    const [UserDetails, setUserDetails] = useState({
        UserName: '',
        Password: ''
    });
    const [Content, setContent] = useState([]);
    const location = useLocation();
    const [CurrentUser, setCurrentUser] = useState('');
    const history = useHistory();
    let { from } = location.state || { from: { pathname: '/vmbdash' } }
    const doUserLogIn = async (e) => {
        // setLoading(true);

        e.preventDefault();
        const { UserName, Password } = UserDetails;

        if (UserName === '' || Password === '') {
            toast.error('Please enter your username and password');
            return;
        }
        else {
            try {
                const user = await Parse.User.logIn(UserName, Password);
                toast.success(
                    `Success! User ${user.get('username')} has successfully signed in!`
                );

                Parse.User.enableUnsafeCurrentUser();
                const currentUser = await Parse.User.currentAsync();
                setCurrentUser(currentUser);
                // history.push(from.pathname);
                // console.log(from.pathname);

                history.replace(from.pathname);
                setUserDetails({
                    UserName: '',
                    Password: ''
                });
            } catch (error) {
                toast.error(`Error! ${error.message}`);
                console.log(error);
            }
        }
        // setLoading(false);

    };


    useEffect(() => {
        const FetchMassage = async () => {
            const query = new Parse.Query('CompanyProfile');
            const result = await query.first();
            setContent([result]);
        }
        FetchMassage();
        return () => {
            window.removeEventListener('click', FetchMassage);
        }
    }, []);

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100vh' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>
                {Content ? Content.map((img, key) => <img src={img.get('companyLogo') ? img.get('companyLogo').url() : undefined} alt='' key={key} style={{ height: '150px', width: '150px', borderRadius: '5px' }} />) : <small style={{ height: '150px', width: '150px', borderRadius: '5px' }}> </small>}
                <form onSubmit={doUserLogIn} style={{ width: '15rem', margin: '3rem 0  ' }}>
                    <label>User Name</label>
                    <input type="text" placeholder='Enter your username.' onChange={(e) => setUserDetails({ ...UserDetails, UserName: e.target.value })} />
                    <label>Password</label>
                    <input type="password" placeholder='Enter your account password.' onChange={(e) => setUserDetails({ ...UserDetails, Password: e.target.value })} />
                    <PrimaryButton type='submit' margin='1rem 0'>Login</PrimaryButton>
                </form>
                {Content ? Content.map((newContent, key) => <small style={{ margin: '10px 0' }} key={key}>Copyright @ {newContent.get('companyName')}</small>) : <small style={{ margin: '10px 0' }}> </small>}
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                autoClose={10000}
            />
        </div>
    );
};

export default DashboardLogin;