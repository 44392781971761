import React, { useContext, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Container, DashMenuContainer, DashMenuIcon, DashMenuItem, DashMenuItemContainer, DashMenuLogoContainer, DashMenuName, Image } from '../Component/StyledComponent/VMBALLStyled';
import DashAllPages from './DashAllPages';
// import DashboardContent from './DashboardContent';
import Parse from 'parse/dist/parse.min.js';
import { MdOutlineArrowDropUp, MdOutlineArrowDropDown } from 'react-icons/md';
import { FaBars, FaTimes, FaUsers } from "react-icons/fa";
// import { CgIfDesign } from 'react-icons/cg';
import { BiLogIn } from 'react-icons/bi';
import { IoIosCreate } from 'react-icons/io';
import { RiProfileFill } from 'react-icons/ri';
import { BiCommentDetail } from 'react-icons/bi';
import DashCompanyAndUser from './DashCompanyAndUser';
import toast, { Toaster } from 'react-hot-toast';
import DashAppearance from './DashAppearance';
import { DataContext } from '../App';
import DashNavbarAndFooter from './DashNavbarAndFooter';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { NavbarItemIcon } from '../Component/Style/Navbar/Navbar.style';
import DashHome from './DashHome';
import ScrollButton from '../Component/ScrollBtn/ScrollButton';
// import '../App.css';






const Dashboard = () => {
    const { arrValue } = useContext(DataContext);

    const [DashboardContentState, setDashboardContentState] = useState(<DashHome />);
    const [Pages, setPages] = useState([]);
    const [Sections, setSections] = useState([]);
    const [ShowSections, setShowSections] = useState(false);
    const [ShowSectionsContent, setShowSectionsContent] = useState(false);
    const [ShowNavbar, setShowNavbar] = useState(false);
    const [ActivePage, setActivePage] = useState('');
    const [CompanyProfile, setCompanyProfile] = useState([]);
    const [ActiveItem, setActiveItem] = useState('alfogencsdjikn');

    const [AppearanceInput, setAppearanceInput] = arrValue;
    const theme = {
        primaryBtnColor: AppearanceInput.primaryButtonBGColor !== '' && AppearanceInput.primaryButtonBGColor,
        primaryBtnInvertedbackground: AppearanceInput.primaryButtonBGColor !== '' && AppearanceInput.primaryButtonBGColor,
        primaryBtnBoxShadow: AppearanceInput.primaryButtonBGColor !== '' && AppearanceInput.primaryButtonBGColor,
        dangerBtnColor: AppearanceInput.dangerButtonBGColor !== '' && AppearanceInput.dangerButtonBGColor,
        dangerBtnInvertedbackground: AppearanceInput.dangerButtonBGColor !== '' && AppearanceInput.dangerButtonBGColor,
        dangerBtnBoxShadow: AppearanceInput.dangerButtonBGColor !== '' && AppearanceInput.dangerButtonBGColor,
        successBtnColor: AppearanceInput.successButtonBGColor !== '' && AppearanceInput.successButtonBGColor,
        successBtnInvertedbackground: AppearanceInput.successButtonBGColor !== '' && AppearanceInput.successButtonBGColor,
        successBtnBoxShadow: AppearanceInput.successButtonBGColor !== '' && AppearanceInput.successButtonBGColor,
    }

    const [PageRefresh] = arrValue;

    const history = useHistory();
    const location = useLocation();
    let { from } = location.state || { from: { pathname: '/vmbdash' } }
    const ShowContent = (path) => {

        if (path.className === 'DashAllPages' || path.className === 'Pages' || path.className === 'Sections') {
            setDashboardContentState(<DashAllPages data={path} />)
        }
        else if (path.className === 'User' || path.className === 'CompanyProfile') {
            setDashboardContentState(<DashCompanyAndUser data={path} />)
        }
        else if (path.className === 'Appearance') {
            setDashboardContentState(<DashAppearance data={path} />)
        }
        else if (path.className === 'Comments') {
            setDashboardContentState(<DashHome />)
        }
        else if (path.className === 'Footer' || path.className === 'Navbar') {
            setDashboardContentState(<DashNavbarAndFooter data={path} />)
        }
        
    }

    const doUserLogOut = async () => {
        try {
            await Parse.User.logOut();
            const currentUser = await Parse.User.current();
            if (currentUser === null) {
                // toast.success('Success! No user is logged in anymore!');
            }

            history.replace(from.pathname);

        } catch (error) {
            toast.error(`Error! ${error.message}`);

        }
    };

    const MenuOnClick = (path) => {
        ShowContent({ className: path });
        if (path === 'DashAllPages') {
            setShowSections(!ShowSections);
            setShowSectionsContent(false);
        }
    }

    const DashMenus = ({ id, name, path, icon, subGroup, }) => {

        return (
            <div style={{ width: '100%' }}>
                <DashMenuItem className={ActiveItem === id ? 'DashActiveItem' : ''} onClick={() => { MenuOnClick(path); setActiveItem(id); }}>
                    <DashMenuIcon>
                        {icon}
                    </DashMenuIcon>
                    <DashMenuName>
                        {name}{name === 'Page' && Pages.length !== 0 && <>{Pages !== [] && ShowSections ? <MdOutlineArrowDropUp color='#fff' fontSize={'25px'} /> : <MdOutlineArrowDropDown color='#fff' fontSize={'25px'} />}</>}
                    </DashMenuName>
                </DashMenuItem>
                {
                    ShowSections && subGroup && subGroup.Pages.map((page, key) => (

                        <div key={key}>

                            <DashMenuItem className={ActiveItem === page.id ? 'DashActiveItem' : ''} key={page.id} onClick={() => { ShowContent(page); setShowSectionsContent(!ShowSectionsContent); setActiveItem(page.id); setActivePage(page.id) }}>
                                <DashMenuIcon>
                                    {/* <BsIntersect />  */}
                                    <b style={{ borderRadius: '5px ', padding: '0px 5px', color: 'black', background: 'white', fontSize: '1.3rem' }}>P</b>
                                </DashMenuIcon>
                                <DashMenuName>
                                    {page.get('pageName')}
                                    {ShowSections.length !== 0 && (ShowSectionsContent && ActivePage === page.id ? <MdOutlineArrowDropUp color='#fff' fontSize={'25px'} /> : <MdOutlineArrowDropDown color='#fff' fontSize={'25px'} />)}
                                </DashMenuName>

                            </DashMenuItem>
                            {
                                ActivePage === page.id && ShowSectionsContent && subGroup && subGroup.Sections.map((section) => (
                                    ActivePage === section.get('page').id ?
                                        (subGroup.Sections.sort((firstItem, secondItem) => firstItem.get('sectionPosition') - secondItem.get('sectionPosition')) ?
                                            <DashMenuItem className={ActiveItem === section.id ? 'DashActiveItem' : ''} key={section.id} onClick={() => { setShowNavbar(false); ShowContent(section); setActiveItem(section.id) }}>
                                                <DashMenuIcon>
                                                    <b style={{ borderRadius: '5px ', padding: '0px 5px', color: 'black', background: 'white', fontSize: '1.3rem' }}>S</b>
                                                </DashMenuIcon>
                                                <DashMenuName>
                                                    {section.get('type')}:
                                                    {section.get('sectionName')}
                                                </DashMenuName>
                                            </DashMenuItem> : undefined)
                                        : undefined
                                ))}
                        </div>
                    ))}
            </div>



        )
    }
    useEffect(() => {
        const allFetch = async () => {

            const FetchPages = async () => {
                const pagesQuery = new Parse.Query('Pages');
                try {
                    let allPages = await pagesQuery.find();
                    setPages(allPages);
                } catch (error) {
                    // console.log('page error', error);
                }
            }
            await FetchPages();
            const FetchSections = async () => {
                const SectionsQuery = new Parse.Query('Sections');
                try {
                    let AllSections = await SectionsQuery.find();
                    setSections(AllSections);
                } catch (error) {
                    // console.log('Sections error', error);
                }
            }
            await FetchSections();

            const FetchCompanyProfile = async () => {
                const CompanyProfileQuery = new Parse.Query('CompanyProfile');

                try {
                    let allCompanyProfile = await CompanyProfileQuery.find();
                    allCompanyProfile.map(item => item.get('visibility') === true ? setCompanyProfile([item]) : null)

                } catch (error) {
                    // console.log('page error', error);
                }
            }
            await FetchCompanyProfile();

        }
        allFetch();
        window.scrollTo(0, 0);
        // window.addEventListener('click', allFetch);

        return () => {
            window.removeEventListener('click', allFetch);
        }
    }, [PageRefresh]);


    const MenuItems = [
        {
            id: ';fjadlsnasdjikn',
            name: 'User',
            path: 'User',
            icon: <FaUsers />
        },
        {
            id: 'fadkgehuddjikn',
            name: 'Company Profile',
            path: 'CompanyProfile',
            icon: <RiProfileFill />
        },
        {
            id: 'alfogencsdjikn',
            name: 'Comments',
            path: 'Comments',
            icon: <BiCommentDetail />
        },
        {
            id: 'fjadlsdigelnwu',
            name: 'Navbar',
            path: 'Navbar',
            icon: <b style={{ borderRadius: '5px ', padding: '0px 5px', color: 'black', background: 'white', fontSize: '1.3rem' }}>N</b>
        },
        {
            id: 'fasdadfsafdsdn',
            name: 'Page',
            path: 'DashAllPages',
            icon: <IoIosCreate />,
            subGroup: {
                Pages: Pages,
                Sections: Sections
            },
        },
        {
            id: 'ajfasaswqwreslkn',
            name: 'Footer',
            path: 'Footer',
            icon: <b style={{ borderRadius: '5px ', padding: '0px 5px', color: 'black', background: 'white', fontSize: '1.3rem' }}>F</b>
        },
        // {
        //     id: 'ajfasjkflaslkn',
        //     name: 'Appearance',
        //     path: 'Appearance',
        //     icon: <CgIfDesign />
        // },
        // {
        //     name: 'Footer',
        //     path: 'Footer',
        //     icon: <CgIfDesign />
        // },

    ]
    // useEffect(() => {

    //     const DashAppearance = async () => {
    //         const query = new Parse.Query('Appearance');
    //         const foundDashboard = await query.first();
    //         if (foundDashboard) {
    //             setAppearanceInput({
    //                 dashboardMenuBGColor: foundDashboard.get('dashboardMenuBGColor'),
    //                 dashContentTheme: foundDashboard.get('dashContentTheme'),
    //                 primaryButtonBGColor: foundDashboard.get('primaryButtonBGColor'),
    //                 dangerButtonBGColor: foundDashboard.get('dangerButtonBGColor'),
    //                 successButtonBGColor: foundDashboard.get('successButtonBGColor'),
    //                 fontFamily: foundDashboard.get('fontFamily'),
    //                 h1FontSize: foundDashboard.get('h1FontSize'),
    //                 h1TextTransformation: foundDashboard.get('h1TextTransformation'),
    //                 h1Color: foundDashboard.get('h1Color'),
    //                 h1TextFormating: foundDashboard.get('h1TextFormating'),
    //                 h2FontSize: foundDashboard.get('h2FontSize'),
    //                 h2TextTransformation: foundDashboard.get('h2TextTransformation'),
    //                 h2Color: foundDashboard.get('h2Color'),
    //                 h2TextFormating: foundDashboard.get('h2TextFormating'),
    //                 h3FontSize: foundDashboard.get('h3FontSize'),
    //                 h3TextTransformation: foundDashboard.get('h3TextTransformation'),
    //                 h3Color: foundDashboard.get('h3Color'),
    //                 h3TextFormating: foundDashboard.get('h3TextFormating'),
    //                 h4FontSize: foundDashboard.get('h4FontSize'),
    //                 h4TextTransformation: foundDashboard.get('h4TextTransformation'),
    //                 h4Color: foundDashboard.get('h4Color'),
    //                 h4TextFormating: foundDashboard.get('h4TextFormating'),
    //                 h5FontSize: foundDashboard.get('h5FontSize'),
    //                 h5TextTransformation: foundDashboard.get('h5TextTransformation'),
    //                 h5Color: foundDashboard.get('h5Color'),
    //                 h5TextFormating: foundDashboard.get('h5TextFormating'),
    //                 h6FontSize: foundDashboard.get('h6FontSize'),
    //                 h6TextTransformation: foundDashboard.get('h6TextTransformation'),
    //                 h6Color: foundDashboard.get('h6Color'),
    //                 h6TextFormating: foundDashboard.get('h6TextFormating'),
    //                 pFontSize: foundDashboard.get('pFontSize'),
    //                 pTextTransformation: foundDashboard.get('pTextTransformation'),
    //                 pColor: foundDashboard.get('pColor'),
    //                 pTextFormating: foundDashboard.get('pTextFormating'),
    //             });
    //         }
    //     }
    //     DashAppearance();

    //     // setProperty();

    //     return () => {
    //         window.removeEventListener('click', DashAppearance);
    //     }

    // }, [setAppearanceInput]);


    return (
        <ThemeProvider theme={theme}>


            <Container width='100%' display='flex' justifyContent='space-between' >
                <Container width='20%' margin='0' className={AppearanceInput.dashContentTheme ? 'DashboardMenuDark' : 'DashboardMenuDefault'} style={{ background: AppearanceInput.dashboardMenuBGColor !== undefined ? AppearanceInput.dashboardMenuBGColor : undefined }}>
                    <NavbarItemIcon onClick={() => setShowNavbar(!ShowNavbar)} top='10px'>
                        {ShowNavbar ? <FaTimes style={{ color: "white", fontSize: '2rem' }} /> : <FaBars style={{ color: "white", fontSize: '2rem' }} />}

                    </NavbarItemIcon>
                    <DashMenuContainer mediaLeft={ShowNavbar ? '0' : '-70vh'}>

                        <DashMenuLogoContainer padding='20px 0'>
                            {
                                CompanyProfile && CompanyProfile.map((company) => (
                                    <Image src={company.get('companyLogo') && company.get('companyLogo')['_url']} alt='logo' height='100%' width='auto' mediaHeight='100%' mediaObjectFit='contain' />
                                ))
                            }
                        </DashMenuLogoContainer>

                        <DashMenuItemContainer style={{ background: '#0f2e50' }}>

                            {
                                MenuItems.map(({ id, name, path, icon, subGroup }) =>
                                    <DashMenus key={id} id={id} name={name} path={path} icon={icon} subGroup={subGroup} />
                                )
                            }

                            <DashMenuItem position='sticky' bottom='0' backgroundColor='#0f2e50' onClick={doUserLogOut}>
                                <DashMenuIcon> <BiLogIn />  </DashMenuIcon>  <DashMenuName> Log Out </DashMenuName>
                            </DashMenuItem>
                        </DashMenuItemContainer>


                    </DashMenuContainer>

                </Container>
                <Container width='80%' margin='0' height='100vh' overflow='auto' textAlign='start'>
                    {DashboardContentState}
                </Container>
            </Container>


            <Toaster
                position="top-center"
                reverseOrder={false}
                autoClose={10000}
            />
        </ThemeProvider >
    );
};

export default Dashboard;