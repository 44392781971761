import React, { useEffect, useState } from 'react';
import { DangerButton, DashContentDiv, DashContentTableDiv, H3, LclTable, LclTData, LclTRow, PrimaryButton, Source, VideoContainer } from '../Component/StyledComponent/VMBALLStyled';
import Parse from 'parse/dist/parse.min.js';
import toast, { Toaster } from 'react-hot-toast';
import { BiHide, BiShowAlt } from 'react-icons/bi';
import { AiFillWarning } from 'react-icons/ai';
import ScrollButton from '../Component/ScrollBtn/ScrollButton';

const DashAllPages = (data) => {
    // console.log(data)
    // console.log("data")
    const PropsData = data.data

    // console.log(PropsData);
    // const { arrValue } = useContext(DataContext);

    const [PageRefresh, setPageRefresh] = useState(false);
    const [ShowForm, setShowForm] = useState(false);
    const [DropdownOption, setDropdownOption] = useState('');
    const [GalleryDropdownOption, setGalleryDropdownOption] = useState('');
    const [ChangedImage, setChangedImage] = useState(false);
    const [VideoDropdownOption, setVideoDropdownOption] = useState('');
    // const [NewsDropdownOption, setNewsDropdownOption] = useState('');
    const [ShowContent, setShowContent] = useState([]);
    const [DeleteConfirmaiton, setDeleteConfirmaiton] = useState(false);
    const [ShowDeleteModal, setShowDeleteModal] = useState(false);
    const [DeleteContent, setDeleteContent] = useState(null);
    const [IsUpdate, setIsUpdate] = useState(false);
    const [UpdatedContent, setUpdatedContent] = useState(null);
    const [FormLoading, setFormLoading] = useState(false);
    // const [PageLoading, setPageLoading] = useState(false);
    const [ChangedRootPage, setChangedRootPage] = useState(null);
    const [DisplayType, setDisplayType] = useState(PropsData.className === 'Sections' && PropsData.get('type') === 'News' ? 'Article' : 'Photos');


    const [AllInputs, setAllInputs] = useState({

        // Page Name and Route
        pageName: '',
        pageRoute: '',

        // Section Details
        sectionName: '',
        sectionPosition: '',
        sectionTitle: '',
        SectionBackgroundImage: '',
        // sectionSubTitle: '',
        // sectionDescription: '',

        // Section Details Style
        sectionTitleTextColor: '',
        sectionTitleFontSize: '',
        sectionTitleFontWeight: '',
        // sectionSubTitleTextColor: '',
        // sectionSubTitleFontSize: '',
        // sectionSubTitleFontWeight: '',
        // sectionDescriptionTextColor: '',
        // sectionDescriptionFontSize: '',
        // sectionDescriptionFontWeight: '',

        // Content Details 
        ContentName: '',
        ContentHeading: '',
        ContentSubHeading: '',
        ContentDescription: '',
        ContentDescription1: '',
        ContentLink: '',
        ContentImage: '',
        ContentImage1: '',
        ContentImage2: '',
        ContentImageAlt: '',
        ContentEmbadedLink: '',
        ContentVideo: '',
        // ContentTitle: '',
        // ContentSubTitle: '',
        // ContentDescription: '',
        ContentButtonName: '',
        ContentButtonLink: '',
        // CounterStart: '',
        // CounterEnd: '',
        // CounterDelay: '',
        // CounterDuration: '',

        // Content Details Style
        ContentTitleTextColor: '',
        ContentTitleFontSize: '',
        ContentTitleFontWeight: '',
        ContentSubTitleTextColor: '',
        ContentSubTitleFontSize: '',
        ContentSubTitleFontWeight: '',
        ContentDescriptionTextColor: '',
        ContentDescriptionFontSize: '',
        ContentDescriptionFontWeight: '',
        ContentButtonTextColor: '',
        ContentButtonFontSize: '',
        ContentButtonFontWeight: '',
        ContentImageWidth: '',
        ContentImageHeight: '',
        ContentImageBorderRadius: '',


        // Contact Form Details
        PhoneNumber: '',
        Email: '',
        Address: '',
        AddressLink: '',

        // Contact Form Details Style
        PhoneNumberTextColor: '',
        PhoneNumberFontSize: '',
        PhoneNumberFontWeight: '',
        EmailTextColor: '',
        EmailFontSize: '',
        EmailFontWeight: '',
        AddressTextColor: '',
        AddressFontSize: '',
        AddressFontWeight: '',

    });


    const ClearAllInputs = () => {
        return setAllInputs({

            // Page Name and Route
            pageName: '',
            pageRoute: '',

            // Section Details
            sectionName: '',
            sectionPosition: '',
            sectionTitle: '',
            SectionBackgroundImage: '',
            // sectionSubTitle: '',
            // sectionDescription: '',

            // Section Details Style
            sectionTitleTextColor: '',
            sectionTitleFontSize: '',
            sectionTitleFontWeight: '',
            // sectionSubTitleTextColor: '',
            // sectionSubTitleFontSize: '',
            // sectionSubTitleFontWeight: '',
            // sectionDescriptionTextColor: '',
            // sectionDescriptionFontSize: '',
            // sectionDescriptionFontWeight: '',

            // Content Details 
            ContentName: '',
            ContentHeading: '',
            ContentSubHeading: '',
            ContentDescription: '',
            ContentDescription1: '',
            ContentLink: '',
            ContentImage: '',
            ContentImage1: '',
            ContentImage2: '',
            ContentImageAlt: '',
            ContentEmbadedLink: '',
            ContentVideo: '',
            // ContentTitle: '',
            // ContentSubTitle: '',
            // ContentDescription: '',
            ContentButtonName: '',
            ContentButtonLink: '',
            // CounterStart: '',
            // CounterEnd: '',
            // CounterDelay: '',
            // CounterDuration: '',

            // Content Details Style
            ContentTitleTextColor: '',
            ContentTitleFontSize: '',
            ContentTitleFontWeight: '',
            ContentSubTitleTextColor: '',
            ContentSubTitleFontSize: '',
            ContentSubTitleFontWeight: '',
            ContentDescriptionTextColor: '',
            ContentDescriptionFontSize: '',
            ContentDescriptionFontWeight: '',
            ContentButtonTextColor: '',
            ContentButtonFontSize: '',
            ContentButtonFontWeight: '',
            ContentImageWidth: '',
            ContentImageHeight: '',
            ContentImageBorderRadius: '',


            // Contact Form Details
            PhoneNumber: '',
            Email: '',
            Address: '',
            AddressLink: '',

            // Contact Form Details Style
            PhoneNumberTextColor: '',
            PhoneNumberFontSize: '',
            PhoneNumberFontWeight: '',
            EmailTextColor: '',
            EmailFontSize: '',
            EmailFontWeight: '',
            AddressTextColor: '',
            AddressFontSize: '',
            AddressFontWeight: '',

        });
    }

    useEffect(() => {


        setShowForm(false);
        setIsUpdate(false);

        setAllInputs({

            // Page Name and Route
            pageName: '',
            pageRoute: '',

            // Section Details
            sectionName: '',
            sectionPosition: '',
            sectionTitle: '',
            SectionBackgroundImage: '',
            // sectionSubTitle: '',
            // sectionDescription: '',

            // Section Details Style
            sectionTitleTextColor: '',
            sectionTitleFontSize: '',
            sectionTitleFontWeight: '',
            // sectionSubTitleTextColor: '',
            // sectionSubTitleFontSize: '',
            // sectionSubTitleFontWeight: '',
            // sectionDescriptionTextColor: '',
            // sectionDescriptionFontSize: '',
            // sectionDescriptionFontWeight: '',

            // Content Details 
            ContentName: '',
            ContentHeading: '',
            ContentSubHeading: '',
            ContentDescription: '',
            ContentDescription1: '',
            ContentLink: '',
            ContentImage: '',
            ContentImage1: '',
            ContentImage2: '',
            ContentImageAlt: '',
            ContentEmbadedLink: '',
            ContentVideo: '',
            // ContentTitle: '',
            // ContentSubTitle: '',
            // ContentDescription: '',
            ContentButtonName: '',
            ContentButtonLink: '',
            // CounterStart: '',
            // CounterEnd: '',
            // CounterDelay: '',
            // CounterDuration: '',

            // Content Details Style
            ContentTitleTextColor: '',
            ContentTitleFontSize: '',
            ContentTitleFontWeight: '',
            ContentSubTitleTextColor: '',
            ContentSubTitleFontSize: '',
            ContentSubTitleFontWeight: '',
            ContentDescriptionTextColor: '',
            ContentDescriptionFontSize: '',
            ContentDescriptionFontWeight: '',
            ContentButtonTextColor: '',
            ContentButtonFontSize: '',
            ContentButtonFontWeight: '',
            ContentImageWidth: '',
            ContentImageHeight: '',
            ContentImageBorderRadius: '',


            // Contact Form Details
            PhoneNumber: '',
            Email: '',
            Address: '',
            AddressLink: '',

            // Contact Form Details Style
            PhoneNumberTextColor: '',
            PhoneNumberFontSize: '',
            PhoneNumberFontWeight: '',
            EmailTextColor: '',
            EmailFontSize: '',
            EmailFontWeight: '',
            AddressTextColor: '',
            AddressFontSize: '',
            AddressFontWeight: '',

        });
        const allFetch = async () => {

            if (PropsData.className === 'DashAllPages') {
                setDisplayType(PropsData.className === 'Sections' && PropsData.get('type') === 'News' ? 'Article' : 'Photos')
                const fetchPages = async () => {
                    const pagesQuery = new Parse.Query('Pages');
                    try {
                        let allPages = await pagesQuery.find();
                        setShowContent(allPages);
                    } catch (error) {
                        // // console.log('page error', error);
                    }
                }
                await fetchPages();

            }
            if (PropsData.className === 'Pages') {

                setDisplayType(PropsData.className === 'Sections' && PropsData.get('type') === 'News' ? 'Article' : 'Photos')
                const fetchSection = async () => {
                    const sectoinQuery = new Parse.Query('Sections');
                    // sectoinQuery.equalTo("page", Contentvalue);
                    try {
                        let allSections = await sectoinQuery.find();
                        setShowContent(allSections);

                    } catch (error) {
                        // // console.log(error);
                    }
                }
                fetchSection();
            }
            if (PropsData.className === 'Sections') {
                if (PropsData.get('type') !== 'Gallery' && PropsData.get('type') !== 'News') {
                    setDropdownOption(PropsData.get('type'));
                }
                if (PropsData.get('type') === 'PhotoGallery') {

                    setDisplayType(PropsData.className === 'Sections' && PropsData.get('type') === 'News' ? 'Article' : 'Photos')
                    const FetchPhotoGallery = async () => {
                        let PhotoGalleryQuery = new Parse.Query('PhotoGallery');
                        PhotoGalleryQuery.equalTo("section", PropsData);
                        try {
                            let FoundPhotoGallery = await PhotoGalleryQuery.find();
                            setShowContent(FoundPhotoGallery);
                        } catch (error) {
                            // // console.log(error);

                        }
                    }
                    FetchPhotoGallery();
                }
                if (PropsData.get('type') === 'VideoGallery') {
                    setDisplayType(PropsData.className === 'Sections' && PropsData.get('type') === 'News' ? 'Article' : 'Photos')
                    const FetchVideoGallery = async () => {
                        let VideoGalleryQuery = new Parse.Query('VideoGallery');
                        VideoGalleryQuery.equalTo("section", PropsData);
                        try {
                            let FoundVideoGallery = await VideoGalleryQuery.find();
                            setShowContent(FoundVideoGallery);
                        } catch (error) {
                            // // console.log(error);

                        }
                    }
                    FetchVideoGallery();
                }
                if (PropsData.get('type') === 'Article') {
                    setDisplayType(PropsData.className === 'Sections' && PropsData.get('type') === 'News' ? 'Article' : 'Photos')
                    const FetchArticle = async () => {
                        let ArticleQuery = new Parse.Query('Article');
                        ArticleQuery.equalTo("section", PropsData);
                        try {
                            let FoundArticle = await ArticleQuery.find();
                            setShowContent(FoundArticle);
                        } catch (error) {
                            // console.log(error);

                        }
                    }
                    FetchArticle();
                }
                if (PropsData.get('type') === 'Card') {
                    setDisplayType(PropsData.className === 'Sections' && PropsData.get('type') === 'News' ? 'Article' : 'Photos')
                    const FetchCard = async () => {
                        let CardQuery = new Parse.Query('Card');
                        CardQuery.equalTo("section", PropsData);
                        try {
                            let FoundCard = await CardQuery.find();
                            setShowContent(FoundCard);
                        } catch (error) {
                            // console.log(error);

                        }
                    }
                    FetchCard();
                }
                if (PropsData.get('type') === 'LandingPage') {
                    setDisplayType(PropsData.className === 'Sections' && PropsData.get('type') === 'News' ? 'Article' : 'Photos')
                    const FetchCard = async () => {
                        let ItemQuery = new Parse.Query('LandingPage');
                        ItemQuery.equalTo("section", PropsData);
                        try {
                            let FoundItem = await ItemQuery.find();
                            setShowContent(FoundItem);
                        } catch (error) {
                            // console.log(error);

                        }
                    }
                    FetchCard();
                }
                if (PropsData.get('type') === 'About') {
                    setDisplayType(PropsData.className === 'Sections' && PropsData.get('type') === 'News' ? 'Article' : 'Photos')
                    const FetchCard = async () => {
                        let ItemQuery = new Parse.Query('About');
                        ItemQuery.equalTo("section", PropsData);
                        try {
                            let FoundItem = await ItemQuery.find();
                            setShowContent(FoundItem);
                        } catch (error) {
                            // console.log(error);

                        }
                    }
                    FetchCard();
                }
                if (PropsData.get('type') === 'VRTour') {
                    setDisplayType(PropsData.className === 'Sections' && PropsData.get('type') === 'News' ? 'Article' : 'Photos')
                    const FetchCard = async () => {
                        let ItemQuery = new Parse.Query('VRTour');
                        ItemQuery.equalTo("section", PropsData);
                        try {
                            let FoundItem = await ItemQuery.find();
                            setShowContent(FoundItem);
                        } catch (error) {
                            // console.log(error);

                        }
                    }
                    FetchCard();
                }
                if (PropsData.get('type') === 'Gallery') {
                    // setDisplayType('Article')
                    const FetchCard = async () => {
                        let ItemQuery = new Parse.Query('Gallery');
                        ItemQuery.equalTo("section", PropsData);
                        DisplayType === 'Videos' ? ItemQuery.equalTo("type", 'Videos') : ItemQuery.equalTo("type", 'Photos');
                        try {
                            let FoundItem = await ItemQuery.find();
                            setShowContent(FoundItem);
                        } catch (error) {
                            // console.log(error);

                        }
                    }
                    FetchCard();
                }
                if (PropsData.get('type') === 'News') {
                    // setDisplayType('Photos')
                    const FetchCard = async () => {
                        let ItemQuery = new Parse.Query('News');
                        ItemQuery.equalTo("section", PropsData);
                        if (DisplayType === 'Videos') { ItemQuery.equalTo("type", 'Videos') }
                        else { ItemQuery.equalTo("type", 'Article') }
                        try {
                            let FoundItem = await ItemQuery.find();
                            setShowContent(FoundItem);
                        } catch (error) {
                            // console.log(error);

                        }
                    }
                    FetchCard();
                }

            }
        }

        allFetch();

        window.scrollTo(0, 0);
        // window.addEventListener('click', allFetch);

        return () => {
            window.removeEventListener('click', allFetch);
        }
    }, [PropsData, DisplayType]);
    const ArticleInput = [
        {
            name: 'articleName',
            label: 'Article Name',
            type: 'text',
            placeholder: 'GramBangla',
            value: AllInputs.ContentName,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentName: e.target.value });
            }

        },
        {
            name: 'articleHeading',
            label: 'Article Heading',
            placeholder: 'Gram Bangla',
            type: 'text',
            value: AllInputs.ContentHeading,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentHeading: e.target.value });
            }

        },
        {
            name: 'articleDescription',
            label: 'Article Description',
            placeholder: 'Amader Gram Bangla .....',
            type: 'textarea',
            value: AllInputs.ContentDescription,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentDescription: e.target.value });
            }


        },
        {
            name: 'articleLink',
            label: 'Article Link',
            placeholder: 'GramBangla',
            type: 'text',
            value: AllInputs.ContentLink,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentLink: e.target.value });
            }

        },
        {
            name: 'articleImage',
            label: 'Article Image',
            type: 'file',

            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentImage: e.target.files[0] });
            }

        },
    ];
    const CardInput = [
        {
            name: 'cardName',
            label: 'Card Name',
            required: true,
            placeholder: 'MagicBook ',
            type: 'text',
            value: AllInputs.ContentName,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentName: e.target.value });
            }

        },
        {
            name: 'cardHeading',
            label: 'Card Heading',
            placeholder: 'Magic Book',
            type: 'text',
            value: AllInputs.ContentHeading,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentHeading: e.target.value });
            }

        },
        {
            name: 'cardDescription',
            label: 'Card Description',
            placeholder: 'Magic Book is a.....',
            type: 'textarea',
            value: AllInputs.ContentDescription,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentDescription: e.target.value });
            }

        },
        {
            name: 'cardImage',
            label: 'Card Image',
            type: 'file',
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentImage: e.target.files[0] });
            }

        },
    ];
    const LandingPageInput = [
        {
            name: 'title',
            label: 'Title',
            placeholder: 'Virtual Meuseum of Bangladesh',
            type: 'text',
            value: AllInputs.ContentHeading,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentHeading: e.target.value });
            }

        },
        {
            name: 'subTitle',
            label: 'Sub Title',
            placeholder: 'Welcome to ',
            type: 'text',
            value: AllInputs.ContentSubHeading,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentSubHeading: e.target.value });
            }

        },
        {
            name: 'buttonName',
            label: 'Button Name',
            placeholder: 'Get App',
            type: 'text',
            value: AllInputs.ContentButtonName,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentButtonName: e.target.value });
            }

        },
        {
            name: 'buttonLink',
            label: 'Link',
            placeholder: 'www.getapp.com',
            type: 'text',
            value: AllInputs.ContentLink,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentLink: e.target.value });
            }

        },


    ];
    const VRTourInput = [
        {
            name: 'vrLink',
            label: 'VR Link',
            placeholder: 'https://app.cloudpano.com/public/shradrescript.js',
            type: 'text',
            value: AllInputs.ContentLink,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentLink: e.target.value });
            }

        },

    ];
    const AboutUsInput = [
        {
            name: 'Description',
            label: '1st Paragraph',
            type: 'textarea',
            placeholder: 'We are a team of creative and passionate people who love to create. We believe in working smart and delivering results.',
            value: AllInputs.ContentDescription,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentDescription: e.target.value });
            }

        },
        {
            name: 'Description1',
            label: '2nd Paragraph',
            type: 'textarea',
            placeholder: 'We do not believe in the traditional way of working. We believe in working smart and delivering results.',
            value: AllInputs.ContentDescription1,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentDescription1: e.target.value });
            }

        },
        {
            name: 'Description2',
            label: '3rd Paragraph',
            type: 'textarea',
            placeholder: 'We have a passion for creating and delivering results. We believe in working smart and delivering results.',
            value: AllInputs.ContentDescription2,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentDescription2: e.target.value });
            }

        },
        {
            name: 'aboutsImage',
            label: 'Image',
            type: 'file',
            value: AllInputs.ContentImage,
            onChange: (e) => {
                setAllInputs({ ...AllInputs, ContentImage: e.target.files[0] });
            }

        },


    ];
    const InputObject = ({ name, value, label, type, onChange, size, placeholder }) => {
        return {
            id: 3,
            name: name,
            value: value,
            label: label,
            type: type,
            onChange: onChange,
            size: size,
            placeholder: placeholder,
        }
    }

    const FormAllInputs = [
        PropsData.className === 'DashAllPages' && !IsUpdate && {
            id: 1,
            contentType: 'Page',
            content: [{
                id: 1,
                name: 'Page Name',
                required: true,
                placeholder: 'Home',
                type: 'text',
                value: AllInputs.pageName,
                onChange: (e) => setAllInputs({ ...AllInputs, pageName: e.target.value })
            },
            PropsData.className === 'DashAllPages' && {
                id: 2,
                name: 'Page Route',
                required: true,
                placeholder: 'home',
                type: 'text',
                value: AllInputs.pageRoute,
                onChange: (e) => setAllInputs({ ...AllInputs, pageRoute: e.target.value })
            },]
        },
        (PropsData.className !== 'Sections') && {
            id: 2,
            contentType: 'Dropdown',
            dropdown: [{
                id: 'fasasdf',
                name: 'LandingPage',
                value: 'LandingPage',
                label: 'Landing Page  ',
            },
            {
                id: 'fasdfasd',
                name: 'About',
                value: 'About',
                label: 'About',
            },
            {
                id: 'asdfasdf',
                name: 'VRTour',
                value: 'VRTour',
                label: 'VR Tour',
            },
            {
                id: 'afsdges',
                name: 'Gallery',
                value: 'Gallery',
                label: 'Gallery(Photos & Videos)',
            },
            // {
            //     id: 'afdsfsddf',
            //     name: 'PhotoGallery',
            //     value: 'PhotoGallery',
            //     label: 'Photo Gallery',
            // },
            // {
            //     id: 'asdfsfa',
            //     name: 'VideoGallery',
            //     value: 'VideoGallery',
            //     label: 'Video Gallery',

            // },
            {
                id: 'gasdsda',
                name: 'News',
                value: 'News',
                label: 'News',

            },
            {
                id: 'asdgsadg',
                name: 'Card',
                value: 'Card',
                label: 'Card',

            },
            ],
        },
        (PropsData.className === 'Sections' && PropsData.get('type') === 'Gallery') && {
            id: 2,
            contentType: 'Dropdown',
            dropdown: [
                {
                    id: 3,
                    name: 'Photos',
                    value: 'Photos',
                    label: 'Photos',
                },
                {
                    id: 4,
                    name: 'Videos',
                    value: 'Videos',
                    label: 'Videos',

                },
            ],
        },
        (PropsData.className === 'Sections' && PropsData.get('type') === 'News') && {
            contentType: 'Dropdown',
            dropdown: [
                {
                    name: 'Article',
                    value: 'Article',
                    label: 'Article',
                },
                {
                    name: 'Videos',
                    value: 'Videos',
                    label: 'Videos',

                },
            ],
        },
        DropdownOption && PropsData.className !== 'Sections' && !IsUpdate && {
            id: 3,
            contentType: 'Section',
            content: [{
                name: 'Section Name',
                required: true,
                placeholder: 'Landing Page',
                type: 'text',
                value: AllInputs.sectionName,
                onChange: (e) => setAllInputs({ ...AllInputs, sectionName: e.target.value })
            },
            {
                name: 'Section Position',
                required: true,
                placeholder: '1',
                type: 'number',
                value: AllInputs.sectionPosition,
                onChange: (e) => setAllInputs({ ...AllInputs, sectionPosition: e.target.value })
            },
            DropdownOption !== 'LandingPage' && {
                name: 'Section Title',
                placeholder: 'Landing Page',
                type: 'text',
                value: AllInputs.sectionTitle,
                onChange: (e) => setAllInputs({ ...AllInputs, sectionTitle: e.target.value })
            },
            {
                name: 'Section Background Image',
                type: 'file',
                // value: AllInputs.ContentImage,
                onChange: (e) => setAllInputs({ ...AllInputs, SectionBackgroundImage: e.target.files[0] }),
            },
            ]
        },
        DropdownOption && DropdownOption === 'PhotoGallery' && !IsUpdate && {
            id: 3,
            contentType: 'PhotoGallery',
            content: [
                // InputObject({ name: 'galleryImageAlt', value: 'galleryImageAlt', label: 'Gallery Image Alt', type: 'text', onChange: (e) => setAllInputs({ ...AllInputs, ContentImageAlt: e.target.value }) }),
                InputObject({ name: 'Image', label: 'Image', type: 'file', onChange: (e) => setAllInputs({ ...AllInputs, ContentImage: e.target.files[0] }) }),
            ]
        },
        DropdownOption && DropdownOption === 'VideoGallery' && !IsUpdate && {
            id: 3,
            contentType: 'VideoGallery',
            dropdown: [
                {
                    id: 1,
                    name: 'YoutubeVideo',
                    value: 'YoutubeVideo',
                    label: 'Youtube Video',
                },
                {
                    id: 2,
                    name: 'VimeoVideo',
                    value: 'VimeoVideo',
                    label: 'Vimeo Video',
                },
                {
                    id: 3,
                    name: 'UploadVideo',
                    value: 'UploadVideo',
                    label: 'Upload Video',
                },
            ],
            content: [
                {
                    name: 'Title',
                    label: 'Title',
                    type: 'text',
                    placeholder: 'Video Title',
                    value: AllInputs.ContentHeading,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, ContentHeading: e.target.value });
                    }
                },

                VideoDropdownOption === 'YoutubeVideo' ? InputObject({ name: 'YoutubeLink', placeholder: 'https://www.youtube.com/embed/xrR3fasdxQNeB_y', value: AllInputs.ContentEmbadedLink, label: 'Youtube Link', type: 'text', onChange: (e) => setAllInputs({ ...AllInputs, ContentEmbadedLink: e.target.value }) }) :
                    VideoDropdownOption === 'VimeoVideo' ? InputObject({ name: 'VimeoLink', placeholder: 'https://player.vimeo.com/video/565603571?h=2f7b0a168f&amp', value: AllInputs.ContentEmbadedLink, label: 'Vimeo Link', type: 'text', onChange: (e) => setAllInputs({ ...AllInputs, ContentEmbadedLink: e.target.value }) }) :
                        VideoDropdownOption === 'UploadVideo' ? InputObject({ name: 'UploadVideo', label: 'Upload Your Video', size: AllInputs.ContentVideo.size, type: 'file', onChange: (e) => setAllInputs({ ...AllInputs, ContentVideo: e.target.files[0] }) }) : null
            ]

        },
        DropdownOption && DropdownOption === 'Photos' && !IsUpdate && {
            id: 3,
            contentType: 'Photos',
            content: [
                // InputObject({ name: 'galleryImageAlt', value: 'galleryImageAlt', label: 'Gallery Image Alt', type: 'text', onChange: (e) => setAllInputs({ ...AllInputs, ContentImageAlt: e.target.value }) }),
                InputObject({ name: 'Image', label: 'Image', type: 'file', onChange: (e) => setAllInputs({ ...AllInputs, ContentImage: e.target.files[0] }) }),
            ]
        },
        DropdownOption && DropdownOption === 'Videos' && !IsUpdate && {
            id: 3,
            contentType: 'Videos',
            dropdown: [
                {
                    id: 1,
                    name: 'YoutubeVideo',
                    value: 'YoutubeVideo',
                    label: 'Youtube Video',
                },
                {
                    id: 2,
                    name: 'VimeoVideo',
                    value: 'VimeoVideo',
                    label: 'Vimeo Video',
                },
                {
                    id: 3,
                    name: 'UploadVideo',
                    value: 'UploadVideo',
                    label: 'Upload Video',
                },
            ],
            content: [
                // VideoDropdownOption !== 'UploadVideo' && InputObject({ name: 'Image', value: 'Image', label: 'Image', type: 'file', }),
                {
                    name: 'Title',
                    label: 'Title',
                    type: 'text',
                    placeholder: 'Video Title',
                    value: AllInputs.ContentHeading,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, ContentHeading: e.target.value });
                    }
                },
                VideoDropdownOption === 'YoutubeVideo' ? InputObject({ name: 'YoutubeEmbadedLink', title: AllInputs.ContentHeading, placeholder: 'https://www.youtube.com/embed/xrR3fasdxQNeB_y', value: AllInputs.ContentEmbadedLink, label: 'Youtube Link', type: 'text', onChange: (e) => setAllInputs({ ...AllInputs, ContentEmbadedLink: e.target.value }) }) :
                    VideoDropdownOption === 'VimeoVideo' ? InputObject({ name: 'VimeoEmbadedLink', title: AllInputs.ContentHeading, placeholder: 'https://player.vimeo.com/video/565603571?h=2f7b0a168f&amp', value: AllInputs.ContentEmbadedLink, label: 'Vimeo Link', type: 'text', onChange: (e) => setAllInputs({ ...AllInputs, ContentEmbadedLink: e.target.value }) }) :
                        VideoDropdownOption === 'UploadVideo' ? InputObject({ name: 'UploadVideo', label: 'Upload Your Video', size: AllInputs.ContentVideo.size, type: 'file', onChange: (e) => setAllInputs({ ...AllInputs, ContentVideo: e.target.files[0] }) }) : null
            ]

        },
        DropdownOption && DropdownOption === 'Article' && !IsUpdate && {
            id: 3,
            contentType: 'Article',
            content: [
                ...ArticleInput.map(input => InputObject(input))
            ]
        },
        DropdownOption && DropdownOption === 'Card' && !IsUpdate && {
            id: 3,
            contentType: 'Card',
            content: [
                ...CardInput.map(input => InputObject(input))
            ]
        },
        DropdownOption && DropdownOption === 'LandingPage' && !IsUpdate && {
            id: 3,
            contentType: 'LandingPage',
            content: [
                ...LandingPageInput.map(input => InputObject(input))
            ]
        },
        DropdownOption && DropdownOption === 'VRTour' && !IsUpdate && {
            id: 3,
            contentType: 'VRTour',
            content: [
                ...VRTourInput.map(input => InputObject(input))
            ]
        },
        DropdownOption && DropdownOption === 'About' && !IsUpdate && {
            id: 3,
            contentType: 'About',
            content: [
                ...AboutUsInput.map(input => InputObject(input))
            ]
        },


    ];
    const FormAllInputsForUpdate = [
        IsUpdate && UpdatedContent.className === 'Pages' && {
            id: 1,
            contentType: 'Page',
            content: [{
                id: 1,
                name: 'Page Name',
                required: true,
                type: 'text',
                value: AllInputs.pageName,
                onChange: (e) => setAllInputs({ ...AllInputs, pageName: e.target.value })
            },
            UpdatedContent.className === 'Pages' && {
                id: 2,
                name: 'Page Route',
                required: true,
                type: 'text',
                value: AllInputs.pageRoute,
                onChange: (e) => setAllInputs({ ...AllInputs, pageRoute: e.target.value })
            },]
        },
        IsUpdate && UpdatedContent.className === 'Sections' && {
            id: 3,
            contentType: 'Section',
            content: [{
                id: 1,
                name: 'Section Name',
                label: 'Section Name',
                placeholder: 'Services',
                required: true,
                type: 'text',
                value: AllInputs.sectionName,
                onChange: (e) => setAllInputs({ ...AllInputs, sectionName: e.target.value })
            },
            {
                id: 2,
                name: 'Section Position',
                label: 'Section Position',
                required: true,
                type: 'number',
                placeholder: '2',
                value: AllInputs.sectionPosition,
                onChange: (e) => setAllInputs({ ...AllInputs, sectionPosition: e.target.value })
            },
            {
                id: 3,
                name: 'Section Title',
                label: 'Section Title',
                type: 'text',
                value: AllInputs.sectionTitle,
                placeholder: 'Our Services',
                onChange: (e) => setAllInputs({ ...AllInputs, sectionTitle: e.target.value })
            },
            {
                id: 4,
                name: 'Section Background Image',
                label: 'Section Background Image',
                type: 'file',
                // value: AllInputs.ContentImage,
                onChange: (e) => setAllInputs({ ...AllInputs, SectionBackgroundImage: e.target.files[0] }),
            },
            ]
        },
        (PropsData.className === 'Sections' && PropsData.get('type') === 'Gallery') && {
            id: 2,
            contentType: 'Dropdown',
            dropdown: [
                {
                    id: 3,
                    name: 'Photos',
                    value: 'Photos',
                    label: 'Photos',
                },
                {
                    id: 4,
                    name: 'Videos',
                    value: 'Videos',
                    label: 'Videos',

                },
            ],
        },
        // (PropsData.className === 'Sections' && PropsData.get('type') === 'News') && {
        //     contentType: 'Dropdown',
        //     dropdown: [
        //         {
        //             name: 'Article',
        //             value: 'Article',
        //             label: 'Article',
        //         },
        // {
        //     name: 'Videos',
        //     value: 'Videos',
        //     label: 'Videos',

        // },
        //     ],
        // },
        (PropsData.className === 'Sections' && PropsData.get('type') === 'News') && {
            contentType: 'Article',
            content: [
                ...ArticleInput.map(input => InputObject(input))
            ]
        },
        IsUpdate && UpdatedContent.className === 'PhotoGallery' && {
            id: 3,
            contentType: 'PhotoGallery',
            content: [
                // InputObject({ name: 'galleryImageAlt', value: 'galleryImageAlt', label: 'Gallery Image Alt', type: 'text', onChange: (e) => setAllInputs({ ...AllInputs, ContentImageAlt: e.target.value }) }),
                InputObject({ name: 'Image', value: AllInputs.ContentImage, label: 'Image', type: 'file', onChange: (e) => setAllInputs({ ...AllInputs, ContentImage: e.target.files[0] }) }),
            ]
        },
        IsUpdate && UpdatedContent.className === 'VideoGallery' && {
            id: 3,
            contentType: 'VideoGallery',
            dropdown: [
                {
                    id: 1,
                    name: 'YoutubeVideo',
                    value: 'YoutubeVideo',
                    label: 'Youtube Video',
                },
                {
                    id: 2,
                    name: 'VimeoVideo',
                    value: 'VimeoVideo',
                    label: 'Vimeo Video',
                },
                {
                    id: 3,
                    name: 'UploadVideo',
                    value: 'UploadVideo',
                    label: 'Upload Video',
                },
            ],
            content: [
                // VideoDropdownOption !== 'UploadVideo' && InputObject({ name: 'Image', value: 'Image', label: 'Image', type: 'file', }),

                VideoDropdownOption === 'YoutubeVideo' ? InputObject({ name: 'YoutubeEmbadedLink', value: AllInputs.ContentEmbadedLink, label: 'Youtube Embaded Link', type: 'text', onChange: (e) => setAllInputs({ ...AllInputs, ContentEmbadedLink: e.target.value }) }) :
                    VideoDropdownOption === 'VimeoVideo' ? InputObject({ name: 'VimeoEmbadedLink', value: AllInputs.ContentEmbadedLink, label: 'Vimeo Embaded Link', type: 'text', onChange: (e) => setAllInputs({ ...AllInputs, ContentEmbadedLink: e.target.value }) }) :
                        VideoDropdownOption === 'UploadVideo' ? InputObject({ name: 'UploadVideo', value: 'UploadVideo', label: 'Upload Your Video', type: 'file', onChange: (e) => setAllInputs({ ...AllInputs, ContentVideo: e.target.files[0] }) }) : null
            ]

        },
        IsUpdate && UpdatedContent.className === 'Article' && {
            id: 3,
            contentType: 'Article',
            content: [
                ...ArticleInput.map(input => InputObject(input))
            ]
        },
        IsUpdate && UpdatedContent.className === 'Card' && {
            id: 3,
            contentType: 'Card',
            content: [
                ...CardInput.map(input => InputObject(input))
            ]
        },
        IsUpdate && UpdatedContent.className === 'LandingPage' && {
            id: 3,
            contentType: 'LandingPage',
            content: [
                ...LandingPageInput.map(input => InputObject(input))
            ]
        },
        IsUpdate && UpdatedContent.className === 'VRTour' && {
            id: 3,
            contentType: 'VRTour',
            content: [
                ...VRTourInput.map(input => InputObject(input))
            ]
        },
        IsUpdate && UpdatedContent.className === 'About' && {
            id: 3,
            contentType: 'About',
            content: [
                ...AboutUsInput.map(input => InputObject(input))
            ]
        },
        IsUpdate && UpdatedContent.className === 'Videos' && {
            id: 3,
            contentType: 'Videos',
            dropdown: [
                {
                    id: 1,
                    name: 'YoutubeVideo',
                    value: 'YoutubeVideo',
                    label: 'Youtube Video',
                },
                {
                    id: 2,
                    name: 'VimeoVideo',
                    value: 'VimeoVideo',
                    label: 'Vimeo Video',
                },
                {
                    id: 3,
                    name: 'UploadVideo',
                    value: 'UploadVideo',
                    label: 'Upload Video',
                },
            ],
            content: [
                // VideoDropdownOption !== 'UploadVideo' && InputObject({ name: 'Image', value: 'Image', label: 'Image', type: 'file', }),
                {
                    name: 'Title',
                    label: 'Title',
                    type: 'text',
                    placeholder: 'Video Title',
                    value: AllInputs.ContentHeading,
                    onChange: (e) => {
                        setAllInputs({ ...AllInputs, ContentHeading: e.target.value });
                    }
                },
                VideoDropdownOption === 'YoutubeVideo' ? InputObject({ name: 'YoutubeEmbadedLink', title: AllInputs.ContentHeading, placeholder: 'https://www.youtube.com/embed/xrR3fasdxQNeB_y', value: AllInputs.ContentEmbadedLink, label: 'Youtube Link', type: 'text', onChange: (e) => setAllInputs({ ...AllInputs, ContentEmbadedLink: e.target.value }) }) :
                    VideoDropdownOption === 'VimeoVideo' ? InputObject({ name: 'VimeoEmbadedLink', title: AllInputs.ContentHeading, placeholder: 'https://player.vimeo.com/video/565603571?h=2f7b0a168f&amp', value: AllInputs.ContentEmbadedLink, label: 'Vimeo Link', type: 'text', onChange: (e) => setAllInputs({ ...AllInputs, ContentEmbadedLink: e.target.value }) }) :
                        VideoDropdownOption === 'UploadVideo' ? InputObject({ name: 'UploadVideo', label: 'Upload Your Video', size: AllInputs.ContentVideo.size, type: 'file', onChange: (e) => setAllInputs({ ...AllInputs, ContentVideo: e.target.files[0] }) }) : null
            ]

        },
        IsUpdate && UpdatedContent.className === 'Article' && {
            id: 3,
            contentType: 'Article',
            content: [
                ...ArticleInput.map(input => InputObject(input))
            ]
        },
    ];

    const FormSubmit = async (e) => {
        setFormLoading(true);
        e.preventDefault();

        const { pageName, pageRoute, sectionName, sectionPosition, sectionTitle, SectionBackgroundImage, ContentImage, ContentImageAlt, ContentVideo, ContentName, ContentHeading, ContentSubHeading, ContentDescription, ContentDescription1, ContentDescription2, ContentEmbadedLink, ContentLink, ContentButtonName } = AllInputs;

        const NewPage = IsUpdate ? new Parse.Query('Pages') : new Parse.Object('Pages');
        const NewSection = IsUpdate ? new Parse.Object('Sections') : new Parse.Object('Sections');


        try {

            if (!IsUpdate) {
                if (PropsData.className === 'DashAllPages') {

                    const NewNavItem = IsUpdate ? new Parse.Object('Navbar') : new Parse.Object('Navbar');
                    NewPage.set('pageName', pageName);
                    NewPage.set('pageRoute', pageRoute.replaceAll(' ', '').toLowerCase());
                    NewPage.set('visibility', true);
                    NewPage.set('pageRoot', false);
                    NewPage.set('createdBy', Parse.User.current());
                    NewPage.set('updatedBy', Parse.User.current());

                    await NewPage.save();
                    NewNavItem.set('ItemType', 'Page');
                    NewNavItem.set('ItemName', pageName);
                    NewNavItem.set('ItemRoute', pageRoute.replaceAll(' ', '').toLowerCase());
                    NewNavItem.set('visibility', true);
                    NewNavItem.set('AsBoth', true);
                    NewNavItem.set('AsPage', false);

                    NewNavItem.set('page', NewPage.toPointer());


                    await NewNavItem.save();
                }
                if (PropsData.className !== 'Sections') {

                    const NewNavItem = IsUpdate ? new Parse.Object('Navbar') : new Parse.Object('Navbar');
                    NewSection.set('page', PropsData.className === 'DashAllPages' ? NewPage.toPointer() : PropsData);
                    NewSection.set('sectionPosition', Number(sectionPosition));
                    NewSection.set('sectionName', sectionName);
                    if (SectionBackgroundImage) {
                        const newImage = new Parse.File(SectionBackgroundImage.name, SectionBackgroundImage);
                        NewSection.set('image', newImage);
                    }
                    NewSection.set('type', DropdownOption);
                    NewSection.set('visibility', true);
                    NewSection.set('createdBy', Parse.User.current());
                    NewSection.set('updatedBy', Parse.User.current());
                    NewSection.set('sectionTitle', sectionTitle);
                    await NewSection.save();


                    NewNavItem.set('ItemType', 'Section');
                    NewNavItem.set('ItemName', sectionName);
                    NewNavItem.set('visibility', false);
                    NewNavItem.set('section', NewSection.toPointer());
                    NewNavItem.set('AsBoth', true);
                    NewNavItem.set('AsSection', false);

                    await NewNavItem.save();
                }
                if (DropdownOption === 'PhotoGallery') {
                    const NewPhotoGallery = new Parse.Object('PhotoGallery');


                    NewPhotoGallery.set('section', PropsData.className === 'Sections' ? PropsData : NewSection.toPointer());
                    NewPhotoGallery.set('createdBy', Parse.User.current());
                    NewPhotoGallery.set('updatedBy', Parse.User.current());

                    NewPhotoGallery.set('imageAlt', ContentImageAlt);
                    NewPhotoGallery.set('visibility', true);
                    if (ContentImage) {
                        const newImage = new Parse.File(ContentImage.name, ContentImage);
                        NewPhotoGallery.set('image', newImage);
                    }

                    // Content Style
                    // newTiltPicture.set('titleTextColor', contentTitleTextColor);
                    // newTiltPicture.set('titleFontSize', Number(contentTitleFontSize));
                    // newTiltPicture.set('titleFontWeight', contentTitleFontWeight);
                    // newTiltPicture.set('subTitleTextColor', contentSubTitleTextColor);
                    // newTiltPicture.set('subTitleFontSize', Number(contentSubTitleFontSize));
                    // newTiltPicture.set('subTitleFontWeight', contentSubTitleFontWeight);
                    // newTiltPicture.set('descriptionTextColor', contentDescriptionTextColor);
                    // newTiltPicture.set('descriptionFontSize', Number(contentDescriptionFontSize));
                    // newTiltPicture.set('descriptionFontWeight', contentDescriptionFontWeight);
                    // newTiltPicture.set('buttonTextColor', contentButtonTextColor);
                    // newTiltPicture.set('buttonFontSize', Number(contentButtonFontSize));
                    // newTiltPicture.set('buttonFontWeight', contentButtonFontWeight);

                    await NewPhotoGallery.save();
                    // toast.success('New tilt picture added successfully!');
                }
                if (DropdownOption === 'VideoGallery') {
                    const NewVideoGallery = new Parse.Object('VideoGallery');


                    NewVideoGallery.set('section', PropsData.className === 'Sections' ? PropsData : NewSection.toPointer());
                    NewVideoGallery.set('createdBy', Parse.User.current());
                    NewVideoGallery.set('updatedBy', Parse.User.current());

                    // NewVideoGallery.set('videoType', VideoDropdownOption);
                    NewVideoGallery.set('videoTitle', ContentHeading);
                    NewVideoGallery.set('videoType', VideoDropdownOption);
                    NewVideoGallery.set('visibility', true);
                    if (ContentVideo) {
                        const newVideo = new Parse.File(ContentVideo.name, ContentVideo);
                        NewVideoGallery.set('video', newVideo);
                    } else {
                        NewVideoGallery.set('embadedLink', ContentEmbadedLink);
                    }

                    // Content Style
                    // newSlider.set('titleTextColor', contentTitleTextColor);
                    // newSlider.set('titleFontSize', Number(contentTitleFontSize));
                    // newSlider.set('titleFontWeight', contentTitleFontWeight);
                    // newSlider.set('subTitleTextColor', contentSubTitleTextColor);
                    // newSlider.set('subTitleFontSize', Number(contentSubTitleFontSize));
                    // newSlider.set('subTitleFontWeight', contentSubTitleFontWeight);
                    // newSlider.set('descriptionTextColor', contentDescriptionTextColor);
                    // newSlider.set('descriptionFontSize', Number(contentDescriptionFontSize));
                    // newSlider.set('descriptionFontWeight', contentDescriptionFontWeight);



                    await NewVideoGallery.save();
                    // toast.success('New VideoGallery added successfully!');
                }
                if (PropsData.className === 'Sections' && PropsData.get('type') === 'News') {
                    const NewItem = new Parse.Object('News');


                    NewItem.set('section', PropsData.className === 'Sections' ? PropsData : NewSection.toPointer());
                    NewItem.set('createdBy', Parse.User.current());
                    NewItem.set('updatedBy', Parse.User.current());
                    NewItem.set('visibility', true);
                    if (DropdownOption === 'Article') {
                        NewItem.set('type', 'Article');
                        NewItem.set('name', ContentName);
                        NewItem.set('heading', ContentHeading);
                        NewItem.set('description', ContentDescription);
                        NewItem.set('link', ContentLink);
                        if (ContentImage) {
                            const newImage = new Parse.File(ContentImage.name, ContentImage);
                            // console.log(ContentVideo);
                            NewItem.set('image', newImage);
                        }
                    }
                    if (DropdownOption === 'Videos') {
                        NewItem.set('type', 'Videos');
                        NewItem.set('videoTitle', ContentHeading);
                        NewItem.set('videoType', VideoDropdownOption);

                        if (ContentVideo) {
                            const newVideo = new Parse.File(ContentVideo.name, ContentVideo);
                            // console.log(ContentVideo);
                            NewItem.set('video', newVideo);
                        } else {
                            NewItem.set('embadedLink', ContentEmbadedLink);
                        }
                    }


                    // if (ContentImage) {
                    //     const newImage = new Parse.File(ContentImage.name, ContentImage);
                    //     NewArticle.set('image', newImage);
                    // }

                    // Content Style
                    // newSlider.set('titleTextColor', contentTitleTextColor);
                    // newSlider.set('titleFontSize', Number(contentTitleFontSize));
                    // newSlider.set('titleFontWeight', contentTitleFontWeight);
                    // newSlider.set('subTitleTextColor', contentSubTitleTextColor);
                    // newSlider.set('subTitleFontSize', Number(contentSubTitleFontSize));
                    // newSlider.set('subTitleFontWeight', contentSubTitleFontWeight);
                    // newSlider.set('descriptionTextColor', contentDescriptionTextColor);
                    // newSlider.set('descriptionFontSize', Number(contentDescriptionFontSize));
                    // newSlider.set('descriptionFontWeight', contentDescriptionFontWeight);



                    await NewItem.save();
                    // toast.success('New Article added successfully!');
                }
                if (DropdownOption === 'Card') {
                    const NewCard = new Parse.Object('Card');


                    NewCard.set('section', PropsData.className === 'Sections' ? PropsData : NewSection.toPointer());
                    NewCard.set('createdBy', Parse.User.current());
                    NewCard.set('updatedBy', Parse.User.current());


                    NewCard.set('name', ContentName);
                    NewCard.set('heading', ContentHeading);
                    NewCard.set('description', ContentDescription);
                    NewCard.set('visibility', true);
                    if (ContentImage) {
                        const newImage = new Parse.File(ContentImage.name, ContentImage);
                        NewCard.set('image', newImage);
                    }

                    // Content Style
                    // newCard.set('titleTextColor', contentTitleTextColor);
                    // newCard.set('titleFontSize', Number(contentTitleFontSize));
                    // newCard.set('titleFontWeight', contentTitleFontWeight);
                    // newCard.set('subTitleTextColor', contentSubTitleTextColor);
                    // newCard.set('subTitleFontSize', Number(contentSubTitleFontSize));
                    // newCard.set('subTitleFontWeight', contentSubTitleFontWeight);
                    // newCard.set('descriptionTextColor', contentDescriptionTextColor);
                    // newCard.set('descriptionFontSize', Number(contentDescriptionFontSize));
                    // newCard.set('descriptionFontWeight', contentDescriptionFontWeight);
                    // newCard.set('buttonTextColor', contentButtonTextColor);
                    // newCard.set('buttonFontSize', Number(contentButtonFontSize));
                    // newCard.set('buttonFontWeight', contentButtonFontWeight);



                    await NewCard.save();
                    // toast.success('New card added successfully!');
                }
                if (DropdownOption === 'LandingPage') {
                    const NewLandingPage = new Parse.Object('LandingPage');


                    NewLandingPage.set('section', PropsData.className === 'Sections' ? PropsData : NewSection.toPointer());
                    NewLandingPage.set('createdBy', Parse.User.current());
                    NewLandingPage.set('updatedBy', Parse.User.current());


                    // NewLandingPage.set('name', ContentName);
                    NewLandingPage.set('heading', ContentHeading);
                    NewLandingPage.set('subHeading', ContentSubHeading);
                    NewLandingPage.set('buttonName', ContentButtonName);
                    NewLandingPage.set('link', ContentLink);
                    NewLandingPage.set('visibility', true);

                    await NewLandingPage.save();
                    // toast.success('New card added successfully!');
                }
                if (DropdownOption === 'About') {
                    const NewAbout = new Parse.Object('About');


                    NewAbout.set('section', PropsData.className === 'Sections' ? PropsData : NewSection.toPointer());
                    NewAbout.set('createdBy', Parse.User.current());
                    NewAbout.set('updatedBy', Parse.User.current());


                    NewAbout.set('description', ContentDescription);
                    NewAbout.set('description1', ContentDescription1);
                    NewAbout.set('description2', ContentDescription2);
                    NewAbout.set('visibility', true);
                    if (ContentImage) {
                        const newImage = new Parse.File(ContentImage.name, ContentImage);
                        NewAbout.set('image', newImage);
                    }
                    await NewAbout.save();
                    // toast.success('New card added successfully!');
                }
                if (DropdownOption === 'VRTour') {
                    const NewItem = new Parse.Object('VRTour');


                    NewItem.set('section', PropsData.className === 'Sections' ? PropsData : NewSection.toPointer());
                    NewItem.set('createdBy', Parse.User.current());
                    NewItem.set('updatedBy', Parse.User.current());


                    NewItem.set('link', ContentLink);
                    NewItem.set('visibility', true);
                    await NewItem.save();
                    // toast.success('New card added successfully!');
                }
                if (PropsData.className === 'Sections' && PropsData.get('type') === 'Gallery') {
                    const NewItem = new Parse.Object('Gallery');


                    NewItem.set('section', PropsData.className === 'Sections' ? PropsData : NewSection.toPointer());
                    NewItem.set('createdBy', Parse.User.current());
                    NewItem.set('updatedBy', Parse.User.current());
                    NewItem.set('visibility', true);
                    if (DropdownOption === 'Photos') {
                        NewItem.set('type', 'Photos');
                        if (ContentImage) {
                            const newImage = new Parse.File(ContentImage.name, ContentImage);
                            NewItem.set('image', newImage);
                        }
                    }
                    if (DropdownOption === 'Videos') {
                        NewItem.set('type', 'Videos');
                        NewItem.set('videoTitle', ContentHeading);
                        NewItem.set('videoType', VideoDropdownOption);

                        if (ContentVideo) {
                            const newVideo = new Parse.File(ContentVideo.name, ContentVideo);
                            // console.log(ContentVideo);
                            NewItem.set('video', newVideo);
                        } else {
                            NewItem.set('embadedLink', ContentEmbadedLink);
                        }
                    }

                    await NewItem.save();
                    // toast.success('New card added successfully!');
                }
                toast.success('Added successfully!');
            }
            if (IsUpdate) {
                if (UpdatedContent.className === 'Pages') {

                    const NewNavItem = new Parse.Query('Navbar');
                    NewPage.equalTo('objectId', UpdatedContent.id);

                    let FoundPage = await NewPage.first();

                    FoundPage.set('pageName', pageName);
                    FoundPage.set('pageRoute', pageRoute.replaceAll(' ', '').toLowerCase());
                    FoundPage.set('visibility', true);
                    // FoundPage.set('createdBy', Parse.User.current());
                    FoundPage.set('updatedBy', Parse.User.current());

                    await FoundPage.save();

                    const FoundNavItem = await NewNavItem.find();

                    for (let newNavItem of FoundNavItem) {


                        if (newNavItem.get('ItemType') === 'Page') {
                            // console.log('newNavItem', newNavItem);
                            if (newNavItem.get('page').id === FoundPage.id) {
                                newNavItem.set('ItemType', 'Page');
                                newNavItem.set('ItemName', pageName);
                                newNavItem.set('ItemRoute', pageRoute.replaceAll(' ', '').toLowerCase());
                                newNavItem.set('visibility', true);
                                await newNavItem.save();

                            }
                        }
                    }




                }
                if (UpdatedContent.className === 'Sections') {

                    const NewNavItem = new Parse.Query('Navbar');
                    const SectionQuery = new Parse.Query('Sections');
                    SectionQuery.equalTo('objectId', UpdatedContent.id);

                    const FoundSections = await SectionQuery.first();
                    FoundSections.set('sectionPosition', Number(sectionPosition));
                    FoundSections.set('sectionName', sectionName);
                    if (!ChangedImage && SectionBackgroundImage) {
                        const newImage = new Parse.File(SectionBackgroundImage.name, SectionBackgroundImage);
                        FoundSections.set('image', newImage);
                    }
                    // FoundSections.set('type', DropdownOption);
                    FoundSections.set('updatedBy', Parse.User.current());
                    FoundSections.set('sectionTitle', sectionTitle);


                    await FoundSections.save();
                    const FoundNavItem = await NewNavItem.find();

                    for (let newNavItem of FoundNavItem) {
                        if (newNavItem.get('ItemType') === 'Section') {

                            if (newNavItem.get('section').id === FoundSections.id) {
                                newNavItem.set('ItemType', 'Section');
                                newNavItem.set('ItemName', sectionName);
                                newNavItem.set('visibility', false);
                                newNavItem.set('section', FoundSections.toPointer());
                                await newNavItem.save();

                            }
                        }
                    }
                }
                if (UpdatedContent.className === 'PhotoGallery') {
                    const TiltPictureQuery = new Parse.Query('PhotoGallery');
                    TiltPictureQuery.equalTo('objectId', UpdatedContent.id);

                    const FoundTiltPicture = await TiltPictureQuery.first();
                    FoundTiltPicture.set('section', PropsData.className === 'Sections' ? PropsData : NewSection.toPointer());
                    FoundTiltPicture.set('updatedBy', Parse.User.current());

                    FoundTiltPicture.set('imageAlt', ContentImageAlt);
                    if (ContentImage) {
                        const newImage = new Parse.File(ContentImage.name, ContentImage);
                        FoundTiltPicture.set('image', newImage);
                    }

                    // Content Style
                    // newTiltPicture.set('titleTextColor', contentTitleTextColor);
                    // newTiltPicture.set('titleFontSize', Number(contentTitleFontSize));
                    // newTiltPicture.set('titleFontWeight', contentTitleFontWeight);
                    // newTiltPicture.set('subTitleTextColor', contentSubTitleTextColor);
                    // newTiltPicture.set('subTitleFontSize', Number(contentSubTitleFontSize));
                    // newTiltPicture.set('subTitleFontWeight', contentSubTitleFontWeight);
                    // newTiltPicture.set('descriptionTextColor', contentDescriptionTextColor);
                    // newTiltPicture.set('descriptionFontSize', Number(contentDescriptionFontSize));
                    // newTiltPicture.set('descriptionFontWeight', contentDescriptionFontWeight);
                    // newTiltPicture.set('buttonTextColor', contentButtonTextColor);
                    // newTiltPicture.set('buttonFontSize', Number(contentButtonFontSize));
                    // newTiltPicture.set('buttonFontWeight', contentButtonFontWeight);

                    await FoundTiltPicture.save();
                    // toast.success('New tilt picture added successfully!');
                }
                if (UpdatedContent.className === 'VideoGallery') {
                    const VideoGalleryQuery = new Parse.Query('VideoGallery');
                    VideoGalleryQuery.equalTo('objectId', UpdatedContent.id);

                    const FoundVideoGallery = await VideoGalleryQuery.first();
                    FoundVideoGallery.set('updatedBy', Parse.User.current());

                    // NewVideoGallery.set('videoType', VideoDropdownOption);
                    FoundVideoGallery.set('videoTitle', ContentHeading);
                    FoundVideoGallery.set('type', VideoDropdownOption);
                    // NewVideoGallery.set('visibility', true);
                    if (ContentVideo) {
                        const newVideo = new Parse.File(ContentImage.name, ContentVideo);
                        FoundVideoGallery.set('video', newVideo);
                    } else {
                        FoundVideoGallery.set('embadedLink', ContentEmbadedLink);
                    }

                    // Content Style
                    // newSlider.set('titleTextColor', contentTitleTextColor);
                    // newSlider.set('titleFontSize', Number(contentTitleFontSize));
                    // newSlider.set('titleFontWeight', contentTitleFontWeight);
                    // newSlider.set('subTitleTextColor', contentSubTitleTextColor);
                    // newSlider.set('subTitleFontSize', Number(contentSubTitleFontSize));
                    // newSlider.set('subTitleFontWeight', contentSubTitleFontWeight);
                    // newSlider.set('descriptionTextColor', contentDescriptionTextColor);
                    // newSlider.set('descriptionFontSize', Number(contentDescriptionFontSize));
                    // newSlider.set('descriptionFontWeight', contentDescriptionFontWeight);



                    await FoundVideoGallery.save();
                    // toast.success('New VideoGallery added successfully!');
                }
                if (UpdatedContent.className === 'News') {
                    const ArticleQuery = new Parse.Query('News');
                    ArticleQuery.equalTo('objectId', UpdatedContent.id);

                    const FoundArticle = await ArticleQuery.first();


                    FoundArticle.set('updatedBy', Parse.User.current());
                    FoundArticle.set('type', 'Article');
                    FoundArticle.set('name', ContentName);
                    FoundArticle.set('heading', ContentHeading);
                    FoundArticle.set('description', ContentDescription);
                    FoundArticle.set('link', ContentLink);
                    if (!ChangedImage && ContentImage) {
                        const newImage = new Parse.File(ContentImage.name, ContentImage);
                        // console.log(ContentVideo);
                        FoundArticle.set('image', newImage);
                    }
                    await FoundArticle.save();
                    // toast.success('New slide added successfully!');
                }
                if (UpdatedContent.className === 'Card') {
                    const CardQuery = new Parse.Query('Card');
                    CardQuery.equalTo('objectId', UpdatedContent.id);

                    const FoundCard = await CardQuery.first();
                    FoundCard.set('updatedBy', Parse.User.current());


                    FoundCard.set('name', ContentName);
                    FoundCard.set('heading', ContentHeading);
                    FoundCard.set('description', ContentDescription);
                    FoundCard.set('visibility', true);
                    if (!ChangedImage && ContentImage) {
                        const newImage = new Parse.File(ContentImage.name, ContentImage);
                        FoundCard.set('image', newImage);
                    }

                    // Content Style
                    // newCard.set('titleTextColor', contentTitleTextColor);
                    // newCard.set('titleFontSize', Number(contentTitleFontSize));
                    // newCard.set('titleFontWeight', contentTitleFontWeight);
                    // newCard.set('subTitleTextColor', contentSubTitleTextColor);
                    // newCard.set('subTitleFontSize', Number(contentSubTitleFontSize));
                    // newCard.set('subTitleFontWeight', contentSubTitleFontWeight);
                    // newCard.set('descriptionTextColor', contentDescriptionTextColor);
                    // newCard.set('descriptionFontSize', Number(contentDescriptionFontSize));
                    // newCard.set('descriptionFontWeight', contentDescriptionFontWeight);
                    // newCard.set('buttonTextColor', contentButtonTextColor);
                    // newCard.set('buttonFontSize', Number(contentButtonFontSize));
                    // newCard.set('buttonFontWeight', contentButtonFontWeight);



                    await FoundCard.save();
                    // toast.success('New card added successfully!');
                }
                if (UpdatedContent.className === 'LandingPage') {
                    const ItemQuery = new Parse.Query('LandingPage');
                    ItemQuery.equalTo('objectId', UpdatedContent.id);

                    const FoundItem = await ItemQuery.first();
                    FoundItem.set('updatedBy', Parse.User.current());


                    FoundItem.set('heading', ContentHeading);
                    FoundItem.set('subHeading', ContentSubHeading);
                    FoundItem.set('buttonName', ContentButtonName);
                    FoundItem.set('link', ContentLink);
                    FoundItem.set('visibility', true);

                    await FoundItem.save();
                    // toast.success('New card added successfully!');
                }
                if (UpdatedContent.className === 'About') {
                    const ItemQuery = new Parse.Query('About');
                    ItemQuery.equalTo('objectId', UpdatedContent.id);

                    const FoundItem = await ItemQuery.first();
                    FoundItem.set('updatedBy', Parse.User.current());



                    FoundItem.set('description', ContentDescription);
                    FoundItem.set('description1', ContentDescription1);
                    FoundItem.set('description2', ContentDescription2);
                    FoundItem.set('visibility', true);
                    if (!ChangedImage && ContentImage) {
                        const newImage = new Parse.File(ContentImage.name, ContentImage);
                        FoundItem.set('image', newImage);
                    }

                    await FoundItem.save();
                    // toast.success('New card added successfully!');
                }
                if (UpdatedContent.className === 'VRTour') {
                    const ItemQuery = new Parse.Query('VRTour');
                    ItemQuery.equalTo('objectId', UpdatedContent.id);

                    const FoundItem = await ItemQuery.first();
                    FoundItem.set('updatedBy', Parse.User.current());


                    FoundItem.set('link', ContentLink);
                    FoundItem.set('visibility', true);

                    await FoundItem.save();
                    // toast.success('New card added successfully!');
                }
                if (UpdatedContent.className === 'Gallery') {
                    const NewItemQuery = new Parse.Query('Gallery');

                    NewItemQuery.equalTo('objectId', UpdatedContent.id);

                    const FoundItem = await NewItemQuery.first();

                    FoundItem.set('section', PropsData.className === 'Sections' ? PropsData : NewSection.toPointer());
                    FoundItem.set('createdBy', Parse.User.current());
                    FoundItem.set('updatedBy', Parse.User.current());
                    FoundItem.set('visibility', true);
                    if (DropdownOption === 'Photos') {
                        FoundItem.set('type', 'Photos');
                        if (ContentImage) {
                            const newImage = new Parse.File(ContentImage.name, ContentImage);
                            FoundItem.set('image', newImage);
                        }
                    }
                    if (DropdownOption === 'Videos') {
                        FoundItem.set('type', 'Videos');
                        FoundItem.set('videoTitle', ContentHeading);
                        FoundItem.set('videoType', VideoDropdownOption);

                        if (ContentVideo) {
                            const newVideo = new Parse.File(ContentVideo.name, ContentVideo);
                            // console.log(ContentVideo);
                            FoundItem.set('video', newVideo);
                        } else {
                            FoundItem.set('embadedLink', ContentEmbadedLink);
                        }
                    }

                    await FoundItem.save();
                    // toast.success('New card added successfully!');
                }
                toast.success('Updated successfully!');
                setDropdownOption('');
            }

            setAllInputs({

                // Page Name and Route
                pageName: '',
                pageRoute: '',

                // Section Details
                sectionName: '',
                sectionPosition: '',
                sectionTitle: '',
                SectionBackgroundImage: '',
                // sectionSubTitle: '',
                // sectionDescription: '',

                // Section Details Style
                sectionTitleTextColor: '',
                sectionTitleFontSize: '',
                sectionTitleFontWeight: '',
                // sectionSubTitleTextColor: '',
                // sectionSubTitleFontSize: '',
                // sectionSubTitleFontWeight: '',
                // sectionDescriptionTextColor: '',
                // sectionDescriptionFontSize: '',
                // sectionDescriptionFontWeight: '',

                // Content Details 
                ContentName: '',
                ContentHeading: '',
                ContentSubHeading: '',
                ContentDescription: '',
                ContentDescription1: '',
                ContentLink: '',
                ContentImage: '',
                ContentImage1: '',
                ContentImage2: '',
                ContentImageAlt: '',
                ContentEmbadedLink: '',
                ContentVideo: '',
                // ContentTitle: '',
                // ContentSubTitle: '',
                // ContentDescription: '',
                ContentButtonName: '',
                ContentButtonLink: '',
                // CounterStart: '',
                // CounterEnd: '',
                // CounterDelay: '',
                // CounterDuration: '',

                // Content Details Style
                ContentTitleTextColor: '',
                ContentTitleFontSize: '',
                ContentTitleFontWeight: '',
                ContentSubTitleTextColor: '',
                ContentSubTitleFontSize: '',
                ContentSubTitleFontWeight: '',
                ContentDescriptionTextColor: '',
                ContentDescriptionFontSize: '',
                ContentDescriptionFontWeight: '',
                ContentButtonTextColor: '',
                ContentButtonFontSize: '',
                ContentButtonFontWeight: '',
                ContentImageWidth: '',
                ContentImageHeight: '',
                ContentImageBorderRadius: '',


                // Contact Form Details
                PhoneNumber: '',
                Email: '',
                Address: '',
                AddressLink: '',

                // Contact Form Details Style
                PhoneNumberTextColor: '',
                PhoneNumberFontSize: '',
                PhoneNumberFontWeight: '',
                EmailTextColor: '',
                EmailFontSize: '',
                EmailFontWeight: '',
                AddressTextColor: '',
                AddressFontSize: '',
                AddressFontWeight: '',

            });
            setShowForm(false);
            setPageRefresh(true)
            setVideoDropdownOption('');
            setDropdownOption('');
        } catch (error) {
            console.log(error);
            setFormLoading(false);
        }

        setFormLoading(false);
        e.target.reset();

    }


    const FormatTime = (date) => {
        try {
            let time = date.split(" ");
            let hours = time[4].split(":")[0];
            let minutes = time[4].split(":")[1];
            let second = time[4].split(":")[2];
            let day = time[0].split(",")[0];
            let lcldate = time[1];
            let month = time[2];
            let year = time[3];
            hours = Number(hours) + 6
            hours = hours || 12;
            const ampm = hours >= 12 ? "PM" : "AM";
            minutes = minutes < 10 ? `${minutes}` : minutes;
            hours %= 12;
            const strTime = `${hours}:${minutes} ${ampm} ${day} ${lcldate} ${month} ${year}`;
            return strTime;
        } catch (e) {
            return "";
        }
    };
    function FormatSizeUnits(bytes) {
        if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
        else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
        else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
        else if (bytes > 1) { bytes = bytes + " bytes"; }
        else if (bytes === 1) { bytes = bytes + " byte"; }
        else { bytes = "0 bytes"; }
        return bytes;
    }
    const VisibilityChange = async (data, visibility) => {
        setFormLoading(true);
        const newData = data.data;
        const visibilityStatus = visibility.visibility;

        // console.log('visibilityStatus', visibilityStatus)
        const contentQuery = new Parse.Query(newData.className);
        contentQuery.equalTo("objectId", newData.id);
        try {
            let foundContent = await contentQuery.first();

            // console.log(foundContent.get('visibility'))
            if (visibilityStatus === 'Hide') {
                foundContent.set('visibility', false);
            }
            if (visibilityStatus === 'Show') {
                foundContent.set('visibility', true);
            }
            await foundContent.save();
            toast.success(`Visibility changed to ${visibilityStatus} Succesfully`)
        } catch (error) {
            // console.log(error);
            setFormLoading(false)
        }
        setFormLoading(false)
    }


    const handleUpdate = (data) => {
        setChangedImage(false)
        const newData = data.data;

        // console.log('newData ', newData);
        // console.log('newData Class Name', newData.className);
        // console.log('newData type', newData.get('type'));

        // window.scrollY = 0;
        window.scrollTo(0, 0)


        if (newData.className !== 'Pages' && newData.className !== 'Sections' && newData.className !== 'Gallery' && newData.className !== 'News') {
            setDropdownOption(newData.className);
        }

        if (newData.className === 'News') {
            setDropdownOption(newData.get('type'));
            // setGalleryDropdownOption(newData.get('type'));
        }

        if (newData.className === 'Gallery') {
            setDropdownOption(newData.className);
            // console.log('Updated Data', newData);
            // console.log('Updated Data Type', newData.get('type'));
            setVideoDropdownOption(newData.get('videoType'))
        }
        if (newData.className === 'VideoGallery') {
            setVideoDropdownOption(newData.get('type'));
            // console.log('Video Gallery', newData.get('type'))
            // setVideoDropdownOption(newData.className);
        }
        if (newData.className === 'Pages') {
            setAllInputs({
                // Page Name and Route
                pageName: newData.get('pageName'),
                pageRoute: newData.get('pageRoute'),

            });
        }
        if (newData.className === 'Sections') {
            setDropdownOption(newData.get('type'));
            setAllInputs({
                sectionName: newData.get('sectionName'),
                sectionTitle: newData.get('sectionTitle'),
                sectionPosition: newData.get('sectionPosition'),
                SectionBackgroundImage: newData.get('image') ? newData.get('image') : '',
            });

        }
        if (newData.className !== 'Pages' && newData.className !== 'Sections') {
            // console.log('ContentLink:', newData.get('link'));
            setAllInputs({
                ContentName: newData.get('name'),
                ContentHeading: newData.get('heading'),
                ContentSubHeading: newData.get('subHeading'),
                ContentDescription: newData.get('description'),
                ContentDescription1: newData.get('description1'),
                ContentDescription2: newData.get('description2'),
                ContentDescription3: newData.get('description3'),
                ContentButtonName: newData.get('buttonName'),
                ContentLink: newData.get('link'),
                ContentEmbadedLink: newData.get('embadedLink'),
                ContentImage: newData.get('image') ? newData.get('image') : '',

                // ContentImageAlt: '',        
                // ContentVideo: '',

            });
        }
        if (newData.get('image')) {
            setChangedImage(true);
        }

        // setIsUpdatedImage(newData.get('image'));

        setIsUpdate(true);
        setShowForm(true)
        setUpdatedContent(newData);
        // setShowForm(false);
    }


    const handleRootPageChange = async (data) => {

        setFormLoading(true);

        const pagesQuery = new Parse.Query('Pages');
        try {
            let foundPage = await pagesQuery.find();

            for (let newPage of foundPage) {
                newPage.set('pageRoot', false);

                if (newPage.id === data.data.id) {
                    newPage.set('pageRoot', true);
                }
                await newPage.save();
                setFormLoading(false);
                setPageRefresh(true)
                toast.success('Root page changed')
            }
        } catch (error) {
            // console.log(error);
            setFormLoading(false);

        }

    }

    const handleDelete = async (data) => {
        const newData = data.data;
        // setShowDeleteModal(true); 
        if (DeleteConfirmaiton === true) {
            setFormLoading(true);


            if (newData.className === 'Pages') {
                //    setEditOverlay(true)

                const pageQuery = new Parse.Query("Pages");
                pageQuery.equalTo("objectId", newData.id);

                try {
                    let foundPage = await pageQuery.first();
                    const navQuery = new Parse.Query('Navbar');
                    let foundNav = await navQuery.find();
                    for (let newNavItem of foundNav) {
                        if (newNavItem.get('ItemType') === 'Page') {
                            if (newNavItem.get('page').id === foundPage.id) {
                                await newNavItem.destroy();
                            }
                        }
                    }
                    const sectionQuery = new Parse.Query("Sections");
                    const foundSection = await sectionQuery.find();
                    for (let newSection of foundSection) {
                        const navQuery = new Parse.Query('Navbar');
                        let foundNav = await navQuery.find();
                        for (let newNavItem of foundNav) {
                            if (newNavItem.get('ItemType') === 'Section') {
                                if (newNavItem.get('section').id === newSection.id) {
                                    await newNavItem.destroy();
                                }
                            }
                        }
                        if (newSection.get('page').id === foundPage.id) {
                            const Card = new Parse.Query("Card");
                            const FoundCard = await Card.find();
                            for (let NewCard of FoundCard) {
                                if (NewCard.get('section').id === newSection.id) {
                                    await NewCard.destroy();
                                }
                            }
                            const Article = new Parse.Query("Article");
                            const FoundArticle = await Article.find();
                            for (let NewArticle of FoundArticle) {
                                if (NewArticle.get('section').id === newSection.id) {
                                    await NewArticle.destroy();
                                }
                            }
                            const PhotoGallery = new Parse.Query("PhotoGallery");
                            const FoundPhotoGallery = await PhotoGallery.find();
                            for (let NewPhotoGallery of FoundPhotoGallery) {
                                if (NewPhotoGallery.get('section').id === newSection.id) {
                                    await NewPhotoGallery.destroy();
                                }
                            }
                            const VideoGallery = new Parse.Query("VideoGallery");
                            const FoundVideoGallery = await VideoGallery.find();
                            for (let NewVideoGallery of FoundVideoGallery) {
                                if (NewVideoGallery.get('section').id === newSection.id) {
                                    await NewVideoGallery.destroy();
                                }
                            }
                            const LandingPage = new Parse.Query("LandingPage");
                            const FoundLandingPage = await LandingPage.find();
                            for (let NewLandingPage of FoundLandingPage) {
                                if (NewLandingPage.get('section').id === newSection.id) {
                                    await NewLandingPage.destroy();
                                }
                            }

                            await newSection.destroy();
                            const News = new Parse.Query("News");
                            const FoundNews = await News.find();
                            for (let NewNews of FoundNews) {
                                if (NewNews.get('section').id === newSection.id) {
                                    await NewNews.destroy();
                                }
                            }

                        }
                    }

                    await foundPage.destroy();
                    toast.success('Deleted Succesfully')
                } catch (error) {
                    console.log(error);
                }

            }

            if (newData.className === 'Sections') {
                //    setEditOverlay(true)
                const sectionQuery = new Parse.Query("Sections");
                sectionQuery.equalTo("objectId", newData.id);
                try {
                    let foundSection = await sectionQuery.first();
                    const navQuery = new Parse.Query('Navbar');
                    let foundNav = await navQuery.find();
                    for (let newNavItem of foundNav) {
                        if (newNavItem.get('ItemType') === 'Section') {
                            if (newNavItem.get('section').id === foundSection.id) {
                                await newNavItem.destroy();
                            }
                        }
                    }

                    const Card = new Parse.Query("Card");
                    const foundCard = await Card.find();
                    try {
                        for (let NewCard of foundCard) {

                            if (NewCard.get('section').id === foundSection.id) {
                                await NewCard.destroy();
                            }
                        }
                    } catch (error) {
                        // console.log(error);
                    }
                    const Article = new Parse.Query("Article");
                    const foundArticle = await Article.find();
                    try {
                        for (let NewCard of foundArticle) {

                            if (NewCard.get('section').id === foundSection.id) {
                                await NewCard.destroy();
                            }
                        }
                    } catch (error) {
                        // console.log(error);
                    }
                    const PhotoGallery = new Parse.Query("PhotoGallery");
                    const FoundPhotoGallery = await PhotoGallery.find();
                    try {
                        for (let newPhotoGallery of FoundPhotoGallery) {

                            if (newPhotoGallery.get('section').id === foundSection.id) {
                                await newPhotoGallery.destroy();
                            }
                        }
                    } catch (error) {
                        // console.log(error);
                    }
                    const VideoGallery = new Parse.Query("VideoGallery");
                    const FoundVideoGallery = await VideoGallery.find();
                    try {
                        for (let NewVideoGallery of FoundVideoGallery) {

                            if (NewVideoGallery.get('section').id === foundSection.id) {
                                await NewVideoGallery.destroy();
                            }
                        }
                    } catch (error) {
                        // console.log(error);
                    }

                    await foundSection.destroy();

                    const News = new Parse.Query("News");
                    const FoundNews = await News.find();
                    try {
                        for (let NewVideoGallery of FoundNews) {

                            if (NewVideoGallery.get('section').id === foundSection.id) {
                                await NewVideoGallery.destroy();
                            }
                        }
                    } catch (error) {
                        // console.log(error);
                    }

                    await News.destroy();
                    toast.success(`Deleted a section succesfully`);

                } catch (error) {
                    // console.log(error);
                }
            }

            if (newData.className === 'Card') {
                //    setEditOverlay(true)
                const Card = new Parse.Query("Card");
                Card.equalTo("objectId", newData.id);
                try {
                    let NewCard = await Card.first();
                    await NewCard.destroy();
                    toast.success(`Deleted Succesfully`);
                } catch (error) {
                    // console.log(error);
                }
            }

            if (newData.className === 'Article') {
                //    setEditOverlay(true)
                const Article = new Parse.Query("Article");
                Article.equalTo("objectId", newData.id);
                try {
                    let NewArticle = await Article.first();
                    await NewArticle.destroy();
                    toast.success(`Deleted Succesfully`);
                } catch (error) {
                    // console.log(error);
                }
            }

            if (newData.className === 'PhotoGallery') {
                //    setEditOverlay(true)
                const PhotoGallery = new Parse.Query("PhotoGallery");
                PhotoGallery.equalTo("objectId", newData.id);
                try {
                    let NewPhotoGallery = await PhotoGallery.first();
                    await NewPhotoGallery.destroy();
                    toast.success(`Deleted Succesfully`);
                } catch (error) {
                    // console.log(error);
                }
            }

            if (newData.className === 'VideoGallery') {
                //    setEditOverlay(true)
                const VideoGallery = new Parse.Query("VideoGallery");
                VideoGallery.equalTo("objectId", newData.id);
                try {
                    let newVideoGallery = await VideoGallery.first();
                    await newVideoGallery.destroy();
                    toast.success(`Deleted Succesfully`);
                } catch (error) {
                    // console.log(error);
                }
            }

            if (newData.className === 'Gallery') {
                //    setEditOverlay(true)
                const ItemQuery = new Parse.Query("Gallery");
                ItemQuery.equalTo("objectId", newData.id);
                try {
                    let newVideoGallery = await ItemQuery.first();
                    await newVideoGallery.destroy();
                    toast.success(`Deleted Succesfully`);
                } catch (error) {
                    // console.log(error);
                }
            }

            if (newData.className === 'News') {
                //    setEditOverlay(true)
                const ItemQuery = new Parse.Query("News");
                ItemQuery.equalTo("objectId", newData.id);
                try {
                    let NewItem = await ItemQuery.first();
                    await NewItem.destroy();
                    toast.success(`Deleted Succesfully`);
                } catch (error) {
                    // console.log(error);
                }
            }

            setFormLoading(false);
            setPageRefresh(true);
        }
    }

    const decamelize = (str, separator) => {
        separator = typeof separator === 'undefined' ? '_' : separator;

        return str
            .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
            .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
            .toLowerCase();
    }

    return (
        <DashContentDiv>
            <div className={FormLoading ? 'confirmationOverlay activeConfirmationOverlay' : 'confirmationOverlay'}>
                <div className={FormLoading ? 'activeSpinnerHolder spinnerHolder' : 'spinnerHolder'}>
                    <spin className={FormLoading ? 'activeOverlaySpinner overlaySpinner' : 'overlaySpinner'}></spin>
                </div>
            </div>
            <H3>{PropsData.className === 'DashAllPages' ? 'Pages' : PropsData.className === 'Pages' ? "Sections" : PropsData.get('sectionName')} :</H3>

            {/* {
                PageLoading ? <div className={'confirmationOverlay activeConfirmationOverlay'}>
                    <div className={'activeSpinnerHolder spinnerHolder'}>
                        <spin className={'activeOverlaySpinner overlaySpinner'}></spin>
                    </div>
                </div> : undefined
            } */}
            {/*  <div  style={{ display: 'flex', justifyContent: 'space-between' }}> */}

            {(PropsData.className === 'DashAllPages' && ShowContent.length < 1) || PropsData.className === 'Pages' || PropsData.className === 'Sections' ?
                <PrimaryButton margin="1rem 0px"
                    disabled={FormLoading && true}
                    onClick={() => {
                        setDropdownOption('');
                        setShowForm(!ShowForm);
                        setIsUpdate(false);
                        setVideoDropdownOption('');
                        ClearAllInputs();
                    }}>
                    {ShowForm ? "Cancel" : "Add"}</PrimaryButton>
                : undefined}
            {/* <PrimaryButton margin="1rem 0px" onClick={() => setPageRefresh(true)}>Page Refresh</PrimaryButton> */}

            {/*  </div> */}

            <form style={ShowForm ? { textAlign: 'start', display: 'block' } : { display: 'none' }} onSubmit={FormSubmit}>
                {!IsUpdate && FormAllInputs.map(input => (
                    input.contentType === 'Page' ?
                        input.content.map(input => (
                            <div key={input.id}>
                                <label>{input.name}: </label>
                                <input required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} value={input.value} />
                            </div>
                        ))
                        : input.contentType === 'Dropdown' ?
                            <select onChange={e => setDropdownOption(e.target.value)}>
                                <option value="">Select</option>
                                {input.dropdown.map(dropdown => (
                                    <option key={dropdown.id} value={dropdown.value}>{dropdown.label}</option>
                                ))}
                            </select> :
                            input.contentType === 'Section' ?
                                input.content.map((input, key) =>
                                    input.name && (
                                        <div key={key}>
                                            <label>{input.name}: </label>
                                            <input required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} value={input.value} />
                                        </div>)) :
                                DropdownOption && DropdownOption === input.contentType && (DropdownOption !== 'VideoGallery' && DropdownOption !== 'Videos') ?
                                    input.content.map(input => (
                                        <div key={input.id}>
                                            <label>{input.label}: </label>
                                            {
                                                input.type === 'textarea' ?
                                                    <textarea required={input.required} type={'textarea'} placeholder={input.placeholder} onChange={input.onChange} />
                                                    :
                                                    <input required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} />
                                            }
                                        </div>)) :

                                    DropdownOption && DropdownOption === input.contentType && (DropdownOption === 'VideoGallery' || DropdownOption === 'Videos') &&

                                    <div>
                                        <label>Choose Video Type: </label>
                                        <select onChange={e => setVideoDropdownOption(e.target.value)}>
                                            <option value="">Select</option>
                                            {input.dropdown.map(dropdown => (
                                                <option key={dropdown.id} value={dropdown.value}>{dropdown.label}</option>
                                            ))}
                                        </select>
                                        {
                                            VideoDropdownOption && input.content.map(input => (
                                                <div>
                                                    <label>{input.label}: </label>
                                                    <input required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} value={input.value} />

                                                </div>))
                                        }
                                    </div>
                ))}
                {IsUpdate && FormAllInputsForUpdate.map(input => (
                    input.contentType === 'Page' ?
                        input.content.map(input => (
                            <div key={input.id}>
                                <label>{input.name}: </label>
                                <input required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} value={input.value} />
                            </div>
                        ))
                        // : input.contentType === 'Dropdown' ?
                        //     <select onChange={e => setGalleryDropdownOption(e.target.value)}>
                        //         <option value="">Select</option>
                        //         {input.dropdown.map(dropdown => (
                        //             <option key={dropdown.id} value={dropdown.value}>{dropdown.label}</option>
                        //         ))}
                        //     </select>
                        :
                        input.contentType === 'Section' ?
                            input.content.map(input => (
                                input.type !== 'file' ? <div key={input.id}>
                                    <label>{input.label}: </label>
                                    {
                                        input.type === 'textarea' ?
                                            <textarea required={input.required} type={'textarea'} placeholder={input.placeholder} onChange={input.onChange} value={input.value} />
                                            :
                                            <input required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} value={input.value} />
                                    }  </div> : input.type === 'file' ? <div key={input.id}>
                                        <label>{input.label}: </label>
                                        {ChangedImage ? <><img src={AllInputs.ContentImage} style={{ width: 'auto', height: '100px' }} alt="Gallery" />
                                            <small style={{ color: 'red', fontSize: '1.2rem', cursor: 'pointer' }} onClick={() => setChangedImage(!ChangedImage)}>Change Image </small></> : <><input required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} /></>}

                                    </div> : undefined)) :
                            DropdownOption && DropdownOption === input.contentType && (DropdownOption !== 'VideoGallery' && DropdownOption !== 'Videos') ?
                                input.content.map(input => (
                                    input.type !== 'file' ?
                                        <div key={input.id}>
                                            <label>{input.label}: </label>
                                            <input required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} value={input.value} />
                                        </div>
                                        : input.type === 'file' ? <div key={input.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <label>{input.label}: </label>
                                            {ChangedImage ? <>
                                                <img src={AllInputs.ContentImage.url()} style={{ width: '100px', height: '100px', margin: '5px 0' }} alt="Gallery" />
                                                {/* <input type='file' value={AllInputs.ContentImage.url()} /> */}
                                                <small style={{ color: 'red', fontSize: '1.2rem', cursor: 'pointer', borderBottom: '1px solid red' }} onClick={() => setChangedImage(!ChangedImage)}>Change Image ?</small></> : <><input required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} /></>}

                                        </div> : undefined
                                )) :
                                DropdownOption && DropdownOption === input.contentType && DropdownOption === 'News' &&

                                <div>
                                    <label>Choose Video Type: </label>
                                    <select onChange={e => setVideoDropdownOption(e.target.value)}>
                                        <option value="">Select</option>
                                        {input.dropdown.map(dropdown => (
                                            <option key={dropdown.id} value={dropdown.value}>{dropdown.label}</option>
                                        ))}
                                    </select>
                                    {
                                        VideoDropdownOption && input.content.map(input => (
                                            <div>
                                                <label>{input.label}: </label>
                                                <input required={input.required} type={input.type} placeholder={input.placeholder} onChange={input.onChange} value={input.value} />

                                            </div>))
                                    }

                                </div>

                ))}


                {AllInputs.ContentVideo ?
                    <p>
                        <b> {AllInputs.ContentVideo && 'Your Video Size: ' + FormatSizeUnits(AllInputs.ContentVideo.size)}</b>
                    </p>
                    : undefined
                }
                {!IsUpdate && (DropdownOption === 'Gallery' || DropdownOption === 'News') && <p>
                    <b style={{ color: 'red', fontSize: '20px' }}> ** After create this section you can add {DropdownOption === "Gallery" ? 'Photos' : 'Article'} & Videos from that section</b>
                </p>}
                <br />

                {/* {DropdownOption === 'News' && !IsUpdate && <p>
                    <b> After create this section you can add Article & Videos from that section</b>
                </p>}
                <br /> */}

                {
                    (DropdownOption || IsUpdate) && <PrimaryButton type="submit">{IsUpdate ? 'Update' : "Add"} {DropdownOption}</PrimaryButton>

                }
            </form>



            <DashContentTableDiv>


                {PropsData.className === 'Sections' && PropsData.get('type') === 'Gallery' &&
                    <div style={{ display: 'flex', justifyContent: 'start', margin: '5px 2px' }}>
                        <select onChange={(e) => setDisplayType(e.target.value)}>
                            <option value="Photos">Photos</option>
                            <option value="Videos">Videos</option>
                        </select>
                    </div>}
                {PropsData.className === 'Sections' && PropsData.get('type') === 'News' &&
                    <div style={{ display: 'flex', justifyContent: 'start', margin: '5px 2px' }}>
                        <select onChange={(e) => setDisplayType(e.target.value)}>
                            <option value="Article">Article</option>
                            <option value="Videos">Videos</option>
                        </select>
                    </div>
                }


                <LclTable>
                    <LclTRow>
                        {PropsData.className === 'DashAllPages' ? <LclTData>Page Name</LclTData> : null}
                        {PropsData.className === 'DashAllPages' ? <LclTData> Page Route</LclTData> : null}
                        {PropsData.className === 'Pages' ? <LclTData> Section Positon</LclTData> : null}
                        {PropsData.className === 'Pages' ? <LclTData> Section Type </LclTData> : null}
                        {(PropsData.className === 'Pages') && <LclTData> Section Name</LclTData>}
                        {PropsData.className === 'Pages' ? <LclTData> Section Title</LclTData> : null}
                        {/* {PropsData.className === 'Sections' && (PropsData.get('type') === 'Gallery') ? <LclTData> Type </LclTData> : null} */}
                        {PropsData.className === 'Sections' && (PropsData.get('type') === 'News') ? <LclTData> Type </LclTData> : null}
                        {PropsData.className === 'Sections' && (PropsData.get('type') === 'Card' || PropsData.get('type') === 'News' || PropsData.get('type') === 'Article') && DisplayType !== 'Videos' ? <LclTData> Name </LclTData> : null}
                        {PropsData.className === 'Sections' && (PropsData.get('type') === 'Card' || PropsData.get('type') === 'Article' || PropsData.get('type') === 'News') && DisplayType !== 'Videos' ? <LclTData> Heding </LclTData> : null}
                        {PropsData.className === 'Sections' && (PropsData.get('type') === 'Card' || PropsData.get('type') === 'Article' || PropsData.get('type') === 'News' || PropsData.get('type') === 'About') && DisplayType !== 'Videos' ? <LclTData> Description </LclTData> : null}
                        {(PropsData.className === 'Pages' || (PropsData.className === 'Sections' && PropsData.get('type') !== 'VideoGallery' && PropsData.get('type') !== 'LandingPage' && PropsData.get('type') !== 'VRTour' && DisplayType !== 'Videos')) && <LclTData> {PropsData.className === 'Pages' && 'Background'} Image</LclTData>}
                        {PropsData.className === 'Sections' && PropsData.get('type') === 'VideoGallery' ? <LclTData> Embaded Link </LclTData> : null}
                        {PropsData.className === 'Sections' && (PropsData.get('type') === 'VideoGallery' || (PropsData.get('type') === 'Gallery') || PropsData.get('type') === 'News') && DisplayType !== 'Article' && DisplayType !== 'Photos' ? <LclTData> Video </LclTData> : null}
                        {PropsData.className === 'Sections' && PropsData.get('type') === 'LandingPage' ? <LclTData> Title </LclTData> : null}
                        {PropsData.className === 'Sections' && PropsData.get('type') === 'LandingPage' ? <LclTData> Sub Title </LclTData> : null}
                        {PropsData.className === 'Sections' && PropsData.get('type') === 'LandingPage' ? <LclTData> Button name </LclTData> : null}
                        {PropsData.className === 'Sections' && (PropsData.get('type') === 'Article' || PropsData.get('type') === 'VRTour' || PropsData.get('type') === 'LandingPage') ? <LclTData> Link </LclTData> : null}


                        <LclTData>Create / Update info</LclTData>
                        {PropsData.className !== 'CompanyProfile' && <LclTData>Visibility</LclTData>}
                        <LclTData>Manage</LclTData>

                    </LclTRow>
                    {ShowContent.map((data, index) =>
                        ((data.className === 'Sections' && ShowContent.sort((firstItem, secondItem) => firstItem.get('sectionPosition') - secondItem.get('sectionPosition'))) || data.className !== 'Sections') ? <LclTRow key={index}>


                            {/* {data.className} */}

                            {data.className === 'Pages' &&
                                <LclTData>

                                    {data.get('pageName') ? data.get('pageName') : '----'}
                                    < br />
                                    {/* {data.get('pageRoot') === true ? <small style={{ color: '#0dcc00', fontSize: '15px' }}>This page is selected as root page</small> : undefined} */}
                                </LclTData>}
                            {data.className === 'Pages' &&
                                <LclTData>
                                    {data.get('pageRoute') ? data.get('pageRoute') : '----'}
                                </LclTData>}
                            {data.className === 'Sections' &&
                                <LclTData>
                                    {data.get('sectionPosition') ? data.get('sectionPosition') : '----'}
                                </LclTData>}
                            {data.className === 'Sections' &&
                                <LclTData>
                                    {data.get('type') ? decamelize(data.get('type'), ' ') : '----'}
                                </LclTData>}
                            {data.className === 'Sections' &&
                                <LclTData>
                                    {data.get('sectionName') ? data.get('sectionName') : '----'}
                                </LclTData>}
                            {data.className === 'Sections' &&
                                <LclTData>
                                    {data.get('sectionTitle') ? data.get('sectionTitle') : '----'}
                                </LclTData>}

                            {
                                PropsData.className === 'Sections' && (PropsData.get('type') === 'News') ?
                                    <LclTData>
                                        {data.get('type') ? data.get('type') : '----'}

                                    </LclTData> : null
                            }
                            {
                                PropsData.className === 'Sections' && (PropsData.get('type') === 'Card' || PropsData.get('type') === 'Article' || PropsData.get('type') === 'News') && DisplayType !== 'Videos' ?
                                    <LclTData>
                                        {data.get('name') ? data.get('name') : '----'}

                                    </LclTData> : null
                            }
                            {
                                PropsData.className === 'Sections' && (PropsData.get('type') === 'Card' || PropsData.get('type') === 'Article' || PropsData.get('type') === 'LandingPage' || PropsData.get('type') === 'News') && DisplayType !== 'Videos' &&
                                <LclTData>
                                    {data.get('heading') ? data.get('heading') : '----'}

                                </LclTData>
                            }
                            {
                                PropsData.className === 'Sections' && (PropsData.get('type') === 'LandingPage') &&
                                <LclTData>
                                    {data.get('subHeading') ? data.get('subHeading') : '----'}

                                </LclTData>
                            }

                            {
                                PropsData.className === 'Sections' && (PropsData.get('type') === 'Card' || PropsData.get('type') === 'Article' || PropsData.get('type') === 'About' || PropsData.get('type') === 'News') && DisplayType !== 'Videos' &&
                                <LclTData minWidth='400px'>
                                    {data.get('description') ? data.get('description') : '----'}
                                    {data.get('description1') ? <p>{data.get('description1')}</p> : undefined}
                                    {data.get('description2') ? <p>{data.get('description2')}</p> : undefined}
                                    {data.get('description3') ? <p>{data.get('description3')}</p> : undefined}
                                </LclTData>
                            }
                            {
                                PropsData.className === 'Sections' && (PropsData.get('type') === 'LandingPage') &&
                                <LclTData>
                                    {data.get('buttonName') ? data.get('buttonName') : '----'}
                                </LclTData>
                            }

                            {PropsData.className !== "DashAllPages" && PropsData.get('type') !== 'VideoGallery' && PropsData.get('type') !== 'LandingPage' && PropsData.get('type') !== 'VRTour' && DisplayType !== 'Videos' ? <LclTData>{data.get('image') ? <img src={data.get('image') && data.get('image')['_url']} alt={data.get('image') && data.get('image')['_name']} style={{ height: '100px' }} /> : 'Not Found'}</LclTData> : null}


                            {
                                PropsData.className === 'Sections' && PropsData.get('type') === 'VideoGallery' &&
                                <LclTData>
                                    {data.get('embadedLink') ? data.get('embadedLink') : '----'}
                                </LclTData>
                            }
                            {
                                PropsData.className === 'Sections' && (PropsData.get('type') === 'Article' || PropsData.get('type') === 'VRTour' || PropsData.get('type') === 'LandingPage') &&
                                <LclTData width='300px' maxWidth='300px'>
                                    {data.get('link') ? data.get('link') : '----'}
                                </LclTData>
                            }
                            {
                                PropsData.className === 'Sections' && (PropsData.get('type') === 'VideoGallery' || PropsData.get('type') === 'Gallery' || PropsData.get('type') === 'News') && DisplayType !== 'Article' && DisplayType !== 'Photos' ?
                                    <LclTData>
                                        {data.get('type') === 'Videos' ?
                                            <VideoContainer width={'300px'} height={'220x'} padding=' 0' margin='3px'
                                                borderRadius={'10px'} cursor='pointer'
                                                filter='opacity(0.8)'
                                                mediaWidth='150px' mediaHeight='100px'
                                            >
                                                {data.get('videoType') !== 'UploadVideo' ?
                                                    <>
                                                        <p style={{ wordBreak: 'break-word' }}> Title: <b><i>{data.get('videoTitle')}</i></b> <br />  </p>
                                                        <p style={{ wordBreak: 'break-word' }}> Type: <b><i>{decamelize(data.get('videoType'), ' ')}</i></b> <br />
                                                            Link: "<b><i> {data.get('embadedLink')}</i></b>"</p></> :
                                                    data.get('videoType') === 'UploadVideo' ?
                                                        <>
                                                            <p style={{ wordBreak: 'break-word' }}> Title: <b><i>{data.get('videoTitle')}</i></b> <br />  </p>
                                                            <video style={{ objectFit: 'cover', overflow: 'hidden', borderRadius: '10px', border: '0.1px solid #ababb678', width: '100%' }} controls>
                                                                <Source src={data.get('video') ? data.get('video')['_url'] : undefined} type='video/mp4' />
                                                            </video> </> : undefined}

                                            </VideoContainer> : '---'}
                                    </LclTData> : null

                            }
                            <LclTData>
                                <LclTable>
                                    <LclTRow display='flex' flexDirection='column'>
                                        <LclTData fontSize='15px' textAlign='center'>
                                            Created at: {data.get("createdAt") !== undefined && FormatTime(data.get("createdAt").toUTCString())}
                                        </LclTData>
                                        <LclTData fontSize='15px' textAlign='center'>
                                            Created by: {data.get("createdBy") !== undefined && data.get("createdBy").get('username')}
                                        </LclTData>
                                        <LclTData fontSize='15px' textAlign='center'>
                                            Last Updated at: {data.get("updatedAt") !== undefined && FormatTime(data.get("updatedAt").toUTCString())}
                                        </LclTData>
                                        <LclTData fontSize='15px' textAlign='center'>
                                            Last Updated by: {data.get("updatedBy") !== undefined && data.get("updatedBy").get('username')}
                                        </LclTData>
                                    </LclTRow>

                                </LclTable>
                            </LclTData>
                            <LclTData>
                                <LclTable>
                                    <LclTRow>
                                        <LclTData> Present </LclTData>
                                        <LclTData> Change To </LclTData>
                                    </LclTRow>
                                    <LclTRow>
                                        <LclTData color='#0dcc00' fontSize='15px'>{data.get('visibility') === false ? <b style={{ cursor: 'pointer' }}>Hide <BiHide /></b> : <b>Show  <BiShowAlt /> </b>}</LclTData>
                                        <LclTData>
                                            {data.get('visibility') === false ? <small style={{ margin: '10px', color: 'red', fontSize: '15px', cursor: 'pointer', }} onClick={() => VisibilityChange({ data: data }, { visibility: 'Show' })}> Show <BiShowAlt /></small> : <small style={{ cursor: 'pointer', margin: '10px', color: 'red', fontSize: '15px' }} onClick={() => VisibilityChange({ data: data }, { visibility: 'Hide' })}> Hide <BiHide /></small>}
                                        </LclTData>
                                    </LclTRow>

                                </LclTable >

                            </LclTData>
                            <LclTData>
                                {(PropsData.className === 'DashAllPages' || PropsData.className === 'Pages' || (PropsData.className === 'Sections' && (PropsData.get('type') !== 'VideoGallery' && PropsData.get('type') !== 'Gallery' && PropsData.get('type') !== 'Videos'
                                    //  && PropsData.get('type') !== 'News'                                
                                    && data.get('type') !== 'Videos'
                                ))) && <PrimaryButton style={{ margin: '10px' }}
                                    onClick={() => { window.scrollTo(0, 0); handleUpdate({ data: data }); }}
                                >Update </PrimaryButton>}
                                <DangerButton style={{ margin: '10px' }}
                                    onClick={() => { setDeleteConfirmaiton(true); setShowDeleteModal(true); setDeleteContent({ data: data }) }}
                                > Delete </DangerButton>
                                {/* {data.className === 'Pages' && data.get('pageRoot') === false ? 
                                        <PrimaryButton style={{ cursor: 'pointer' }}
                                            onClick={() => { setChangedRootPage({ data: data }); setShowDeleteModal(true); setDeleteConfirmaiton(true); }}>
                                            Make this page as root
                                        </PrimaryButton>
                                        : undefined 
                                    } */}
                            </LclTData>


                        </LclTRow> : undefined

                    )}
                </LclTable >
                <div className={ShowDeleteModal ? 'Modal Open' : 'Modal'}>
                    < div className='ModalCard'>
                        {/* https://play.google.com/store/apps/details?id=bd.com.rrad.service.surecare */}
                        <H3 color='red' padding='0 0 20px 0' margin='20px 0' borderBottom='1px solid red' display='flex'> <AiFillWarning color='red' fontSize={'40px'} /> {DeleteContent !== null ? 'Delete' : ChangedRootPage !== null ? 'Root Page Change' : undefined}  </H3>
                        {
                            DeleteContent !== null ? <div>  Are you sure you want to delete <span style={{ color: 'red', fontWeight: 'bolder' }}> {DeleteContent.data.get('sectionName') ? DeleteContent.data.get('sectionName') : DeleteContent.data.get('pageName') ? DeleteContent.data.get('pageName') : DeleteContent.data.get('title') ? DeleteContent.data.get('title') : null} </span>?  </div> : ChangedRootPage !== null ? <div>  Are you sure you want to make <span style={{ color: 'red', fontWeight: 'bolder' }}> {ChangedRootPage.data.get('pageName')} </span> as root page?  </div> : undefined
                        }
                        <div>

                            <PrimaryButton onClick={() => { setShowDeleteModal(false); setDeleteContent(null); setChangedRootPage(null) }}>Cancel</PrimaryButton>
                            {DeleteContent !== null ? <DangerButton onClick={() => { setShowDeleteModal(false); handleDelete(DeleteContent); }}>Delete</DangerButton> : ChangedRootPage !== null ? <DangerButton onClick={() => { setShowDeleteModal(false); handleRootPageChange(ChangedRootPage); }}>Make root page</DangerButton> : null}

                        </div>
                    </div>
                </div>
                {/* <ScrollButton props={true} /> */}

            </DashContentTableDiv >





            <Toaster
                position="top-center"
                reverseOrder={false}
                autoClose={10000}
            />

        </DashContentDiv >
    );
};


export default DashAllPages;