import React, { useEffect, useState } from 'react';
import { DashContentDiv, DashContentTableDiv, H3, LclTable, LclTData, LclTRow } from '../Component/StyledComponent/VMBALLStyled';
import Parse from 'parse/dist/parse.min.js'; 



const DashHome = () => {
    const [Content, setContent] = useState([]);

    useEffect(() => {
        const FetchMassage = async () => {
            const query = new Parse.Query('Massage');
            const result = await query.find();
            setContent(result);
        }
        FetchMassage();
        return () => {
            window.removeEventListener('click', FetchMassage);
        }
    }, []);


    const FormatTime = (date) => {
        try {
            let time = date.split(" ");
            let hours = time[4].split(":")[0];
            let minutes = time[4].split(":")[1];
            let second = time[4].split(":")[2];
            let day = time[0].split(",")[0];
            let lcldate = time[1];
            let month = time[2];
            let year = time[3];
            hours = Number(hours) + 6
            hours = hours || 12;
            const ampm = hours >= 12 ? "PM" : "AM";
            minutes = minutes < 10 ? `${minutes}` : minutes;
            hours %= 12;
            const strTime = `${hours}:${minutes} ${ampm} ${day} ${lcldate} ${month} ${year}`;
            return strTime;
        } catch (e) {
            return "";
        }
    };
    return (
        <DashContentDiv>
            <H3>Visitor Massages</H3>

            <DashContentTableDiv>

                {Content.length === 0 ?
                    <H3 margin='20vh' textAlign='center'>No Visitor Massages Found</H3>
                    : <LclTable>
                        <LclTRow>
                            <LclTData>Name</LclTData>
                            <LclTData>Email</LclTData>
                            <LclTData>Phone</LclTData>
                            <LclTData>Massage</LclTData>
                            <LclTData>Date</LclTData>
                        </LclTRow>
                        {
                            Content.map((item, index) => <LclTRow key={index}>
                                <LclTData>{item.get('name')}</LclTData>
                                <LclTData>{item.get('email')}</LclTData>
                                <LclTData>{item.get('phone')}</LclTData>
                                <LclTData>{item.get('massage')}</LclTData>
                                <LclTData>{FormatTime(item.get('createdAt').toUTCString())}</LclTData>
                            </LclTRow>
                            )}
                    </LclTable>}
            </DashContentTableDiv>

        </DashContentDiv>
    );
};

export default DashHome;